import { ReactNode, forwardRef } from "react";
import clsx from "clsx";
import Select, { components, DropdownIndicatorProps, Props as SelectProps, NoticeProps } from "react-select";
import { ChevronDown } from "react-feather";

import useLanguage from "@axvdex/hooks/useLanguage";
import styles from "../../styles/CustomSelect.module.scss";
import CustomToolTip from "./CustomToolTip";

export interface ListItemsProps {
  value: string;
  label: string;
  id?: string;
  optionPrefix?: string | JSX.Element;
  optionSuffix?: string | JSX.Element;
  tooltip?: string;
  openTooltip?: boolean;
  extraFields?: any;
}

interface CustomSelectProps extends Partial<SelectProps> {
  extraClassName?: string;
  name: string;
  placeholder?: string;
  labelText: string;
  hiddenLabel?: boolean;
  customSelectIcon?: ReactNode;
  hideChevronIcon?: boolean;
  items: ListItemsProps[];
  inputValue?: string;
  helpText?: ReactNode;
  extraButton?: ReactNode;
  centerText?: boolean;
}

function CustomSelect(
  {
    extraClassName,
    name,
    placeholder,
    labelText,
    hiddenLabel,
    customSelectIcon,
    hideChevronIcon,
    items,
    inputValue,
    helpText,
    extraButton,
    centerText,
    ...props
  }: CustomSelectProps,
  ref: React.ForwardedRef<any>
) {
  const { i18 } = useLanguage();

  const DropdownIndicator = ({ ...props }: DropdownIndicatorProps<ListItemsProps>) => (
    <components.DropdownIndicator {...props}>
      <ChevronDown />
    </components.DropdownIndicator>
  );

  const NoOptionsMessage = ({ ...props }: NoticeProps<ListItemsProps>) => (
    <components.NoOptionsMessage {...props}>
      {i18("No results found", "customSelect.noResults")}
    </components.NoOptionsMessage>
  );

  const formatOptionLabel = ({ label, optionPrefix, optionSuffix, tooltip, openTooltip }: ListItemsProps) => {
    const microseconds = window.performance.now();
    if (tooltip) {
      const tooltipId = `tooltip-${microseconds}-${label}`;
      return (
        <>
          <div className="customSelectOptionFlexbox flexbox" data-tooltip-id={tooltipId}>
            {optionPrefix && <span className="customSelectOptionPrefix">{optionPrefix}</span>}
            <span className="customSelectOptionLabel">{label}</span>
            {optionSuffix && <span className="customSelectOptionSuffix">{optionSuffix}</span>}
          </div>

          {/* uncomment to force tooltip open */}
          {/*<CustomToolTip id={tooltipId} content={tooltip} open={true} />*/}
          <CustomToolTip id={tooltipId} content={tooltip} open={openTooltip} />
        </>
      );
    }
    return (
      <div className="customSelectOptionFlexbox flexbox" style={{ placeContent: centerText ? "center" : undefined }}>
        {optionPrefix && <span className="customSelectOptionPrefix">{optionPrefix}</span>}
        <span className="customSelectOptionLabel">{label}</span>
        {optionSuffix && <span className="customSelectOptionSuffix">{optionSuffix}</span>}
      </div>
    );
  };

  return (
    <div className={clsx("formGroup", "formGroupSelect", extraClassName && extraClassName)}>
      <label htmlFor={`id_${name}`} className={clsx(hiddenLabel && "visuallyHidden")}>
        {i18(labelText, "customSelect.label")}
      </label>

      <div className={clsx("inputGroup", "inputGroupSelect", customSelectIcon && "hasIcon")}>
        {customSelectIcon && <span className="inputGroupIcon">{customSelectIcon}</span>}

        <Select
          classNamePrefix="customSelect"
          className={clsx(styles.customSelect, "customSelect", hideChevronIcon && "hiddenChevron")}
          inputId={`id_${name}`}
          name={name}
          // do NOT i18 placeholder here, should only be done at the callee
          placeholder={typeof "" === typeof placeholder ? placeholder : i18("Placeholder", "customSelect.placeholder")}
          options={items}
          filterOption={(option, inputValue) => {
            return option.label.toLowerCase().includes(inputValue.toLowerCase());
          }}
          inputValue={inputValue}
          // force menu to be open
          // menuIsOpen={true}
          formatOptionLabel={formatOptionLabel}
          components={{
            DropdownIndicator,
            NoOptionsMessage,
          }}
          {...props}
          ref={ref}
        />
        {extraButton}
      </div>

      {helpText && <div className="helpText">{helpText}</div>}
    </div>
  );
}

export default forwardRef(CustomSelect);
