import clsx from "clsx";
import CustomInputButton from "@axvdex/components/form-element/CustomInputButton";
import CustomNumericInput from "@axvdex/components/form-element/CustomNumericInput";
import CustomSwitch from "@axvdex/components/form-element/CustomSwitch";
import { updateUserGlobalSettingsFields } from "@axvdex/state/wallet/walletThunks";

export const SwapSettingsContainer = ({
  actionType,
  slippage,
  setSlippage,
  slippageType,
  setSlippageType,
  openSlippage,
  TradeFromTokenSlippageList,
  i18,
  isolatedPoolTrading,
  setIsolatedPoolTrading,
  dispatch,
}: any) => (
  <div className={clsx("tradeFomTokenSlippageFieldsetWrapper", openSlippage && "isOpen")}>
    <fieldset className="tradeFomTokenSlippageFieldset">
      <legend>{i18("Slippage tolerance", "trade.form.slippage.legend")}</legend>

      <div className="btnGroup">
        {TradeFromTokenSlippageList.map((input, idx) => (
          <CustomInputButton
            key={idx}
            checked={slippageType === "hardcoded" ? input.id === slippage : input.id === "custom"}
            type="radio"
            id={input.id}
            name="slippage"
            labelText={input.label}
            value={input.id}
            onChange={() => {
              if (input.id === "custom") {
                setSlippage(slippage);
                setSlippageType("custom");
                dispatch(
                  updateUserGlobalSettingsFields({
                    slippageTolerance: { type: "custom", amount: slippage },
                  })
                );
              } else {
                setSlippage(input.id);
                setSlippageType("hardcoded");
                dispatch(
                  updateUserGlobalSettingsFields({
                    slippageTolerance: { type: "hardcoded", amount: input.id },
                  })
                );
              }
            }}
          />
        ))}
      </div>

      {slippageType === "custom" && (
        <CustomNumericInput
          extraClassName="tradeCustomSlippageFormGroup"
          name="trade_custom_slippage_amount"
          labelText={i18("Custom slippage amount", "trade.form.slippage.custom.label")}
          value={slippage}
          onChange={e => {
            setSlippage(e.target.value);
            dispatch(
              updateUserGlobalSettingsFields({
                slippageTolerance: { type: "custom", amount: e.target.value },
              })
            );
          }}
        />
      )}
      {actionType === "swap" && (
        <div style={{ marginTop: "1em" }}>
          <CustomSwitch
            extraClassName="StableWithdrawalCustomSwitch"
            name="isolatedPoolTrading"
            labelText={
              <>
                <p>{i18("Isolated pool trading (Advanced)", "trade.form.isolatedPoolTrading.label")}</p>
              </>
            }
            isToggled={isolatedPoolTrading}
            onToggle={e => {
              setIsolatedPoolTrading(e.target.checked);
              dispatch(
                updateUserGlobalSettingsFields({
                  tradeIsolatedPoolTrading: e.target.checked,
                })
              );
            }}
          />
        </div>
      )}
    </fieldset>
  </div>
);
