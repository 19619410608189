import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useMediaQuery } from "usehooks-ts";
import { ReactComponent as IcnSortAsc } from "@axvdex/assets/icons/icn-sort-arrow-down-short-wide-solid.svg";
import { ReactComponent as IcnSortDesc } from "@axvdex/assets/icons/icn-sort-arrow-down-wide-short-solid.svg";
import imgSanitize from "@axvdex/utils/imgSanitize";
import { abbreviateUSD } from "@axvdex/utils/formatNumber";
import { assetTableData } from "@axvdex/views/Statistics";
import useLanguage from "@axvdex/hooks/useLanguage";
import { responsiveBreakpoints } from "@axvdex/constants";
import { useAppSelector } from "@axvdex/state";
import { selectAssets } from "@axvdex/state/wallet/walletSelectors";
import Button from "../common/Button";
import CustomInput from "../form-element/CustomInput";

const SortOrderIcon = ({ sortOrder }: { sortOrder: string }) => {
  const icon = () => {
    if ("desc" === sortOrder) {
      return <IcnSortDesc />;
    } else if ("asc" === sortOrder) {
      return <IcnSortAsc />;
    }
    return <></>;
  };

  return icon();
};

const StatisticsAssetsTable = ({ analyticsData }: { analyticsData: assetTableData }) => {
  const { i18 } = useLanguage();
  const assets = useAppSelector(selectAssets);
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);

  const defaultColumns = useMemo(() => {
    return [
      {
        id: "asset",
        accessorKey: "asset",
        header: () => <span>{i18("Asset", "statisticsAssetsTable.column.asset")}</span>,
        cell: ({ row }) => (
          <div className="flexbox">
            <span className="tokenIcon" title={row.original.symbol} aria-label={row.original.symbol}>
              <img src={imgSanitize(row.original.symbol)} alt={`${row.original.symbol}`} />
            </span>
            {!isMobileBreakpoint && <span className="tokenName">{row.original.symbol}</span>}
          </div>
        ),
        // sortingFn: (a, b) => {
        //   return a.original.symbol.localeCompare(b.original.symbol);
        // },
        // enableSorting: true,
      },
      {
        id: "price",
        accessorKey: "price",
        header: () => <span>{i18("Price", "statisticsAssetsTable.column.price")}</span>,
        cell: ({ row }) => abbreviateUSD(`${Math.round(row.original.curPriceInUSD * 1000) / 1000}`),
        sortingFn: (a, b) => {
          return a.original.curPriceInUSD - b.original.curPriceInUSD;
        },
        enableSorting: true,
      },
      {
        id: "priceChange",
        accessorKey: "priceChange",
        header: () => <span>{i18("24h Change", "statistics.assetsTable.column.priceChange")}</span>,
        cell: ({ row }) => (
          <span
            className={clsx(
              "variation",
              row.original.changePrice > 0 && "higher",
              row.original.changePrice < 0 && "lower"
            )}
          >
            <NumericFormat
              value={Math.abs(row.original.changePrice)}
              fixedDecimalScale
              decimalScale={2}
              displayType="text"
              suffix="%"
            />
          </span>
        ),
        sortingFn: (a, b) => {
          return a.original.changePrice - b.original.changePrice;
        },
        enableSorting: true,
      },
      {
        id: "24hvolume",
        accessorKey: "24hvolume",
        header: () => <span>{i18("24h Volume", "statisticsAssetsTable.column.24hVolume")}</span>,
        cell: ({ row }) => abbreviateUSD(`${row.original.volume24hInUSD}`),
        sortingFn: (a, b) => a.original.volume24hInUSD - b.original.volume24hInUSD,
        enableSorting: true,
      },
      {
        id: "liquidity",
        accessorKey: "liquidity",
        header: () => <span>{i18("Liquidity", "statisticsAssetsTable.column.liquidity")}</span>,
        cell: ({ row }) => abbreviateUSD(`${row.original.liquidityInUSD}`),
        sortingFn: (a, b) => a.original.liquidityInUSD - b.original.liquidityInUSD,
        enableSorting: true,
      },
    ];
  }, [i18]);

  const [searchString, setSearchString] = useState("");
  const [columns] = useState(() => [...defaultColumns]);
  const [data, setData] = useState(() => []);

  useEffect(() => {
    if (analyticsData && Object.keys(analyticsData).length > 0) parseData();
    if (data.length === 0 && assets && Object.keys(assets).length > 0) {
      setSorting([{ id: "liquidity", desc: true }]);
    }
  }, [analyticsData, assets, searchString]);

  const parseData = (searchFilter = null) => {
    const searchRule = searchFilter || searchString;
    setData(
      Object.keys(analyticsData)
        .filter(
          assetID =>
            analyticsData[assetID].liquidityInUSD > 0 &&
            assets[assetID] && // this is to prevent disabled assets on this context to appear
            (searchRule !== "" ? analyticsData[assetID].symbol.toLowerCase().includes(searchRule.toLowerCase()) : true)
        )
        // replace price and change with more up-to-date values, so they are not different from the entire platform.
        // (analytics run every x minutes, so it can have outdated prices)
        .map(assetID => {
          return {
            ...analyticsData[assetID],
            curPriceInUSD: assets[assetID]?.price || 0,
            changePrice: analyticsData[assetID].priceVariation
              ? analyticsData[assetID].priceVariation?.variationSum /
                analyticsData[assetID].priceVariation?.variationCount
              : assets[assetID].price_24h_change || 0,
          };
        })
        .sort((a, b) => b.liquidityInUSD - a.liquidityInUSD)
    );
  };

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    debugTable: !1,
    autoResetAll: false,
  });

  const paginationBulletsButtons = Array.from({ length: table.getPageCount() }, (_, i) => (
    <li key={i}>
      <button
        className={clsx("btnTablePaginationBullet", pagination.pageIndex === i && "isCurrent")}
        onClick={() => table.setPageIndex(i)}
        title={`${i18("Go to page", "global.pagination.goToPage")} ${i + 1}`}
      />
    </li>
  ));

  return (
    <section className="statsSectionTable">
      <div>
        <div className="statsSectionTableHeader inlineFlexbox" style={{ width: "100%" }}>
          <h2>{i18("Assets", "statistics.assets.title")}</h2>
          <div style={{ marginLeft: "auto" }}>
            <CustomInput
              name="asset_search"
              labelText={i18("Search", "statistics.assets.filter.search.label")}
              placeholder={i18("Search...", "statistics.assets.filter.search.placeholder")}
              hiddenLabel={true}
              value={searchString}
              onChange={e => {
                setSearchString(e.target.value);
                parseData(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <table className={clsx("table", "statsTable")}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return "asset" === header.id ? (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ) : (
                    <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                      <div className={"tableHeaderColumnWithIcon"}>
                        <span className="icon">
                          <SortOrderIcon sortOrder={(header.column.getIsSorted() as string) ?? "unsorted"} />
                        </span>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {table.getPageCount() > 1 && (
        <section className="statisticsGridItemsSectionTablePagination">
          <Button
            extraClassName="btnTablePagination previous"
            title={i18("Previous page", "global.pagination.previousPage")}
            btnVariant="icon"
            btnColor="dark-medium"
            icon={<ChevronLeft />}
            onClick={table.previousPage}
            disabled={!table.getCanPreviousPage()}
          />

          <ul className="tablePaginationBulletBtnList">{paginationBulletsButtons.map(bullet => bullet)}</ul>

          <Button
            extraClassName="btnTablePagination next"
            title={i18("Next page", "global.pagination.nextPage")}
            btnVariant="icon"
            btnColor="dark-medium"
            icon={<ChevronRight />}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          />
        </section>
      )}
    </section>
  );
};

export default StatisticsAssetsTable;
