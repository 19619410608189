import { ReactNode } from "react";
import clsx from "clsx";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import BigNumber from "bignumber.js";
import useLanguage from "@axvdex/hooks/useLanguage";

interface CustomNumericInputProps extends NumericFormatProps {
  extraClassName?: string;
  name: string;
  disabled?: boolean;
  labelText: string;
  hiddenLabel?: boolean;
  helpText?: ReactNode;
  inputStyle?: React.CSSProperties;
}

function CustomNumericInput({
  extraClassName,
  name,
  disabled,
  labelText,
  hiddenLabel,
  helpText,
  decimalScale = 4,
  inputStyle,
  ...props
}: CustomNumericInputProps) {
  const { i18 } = useLanguage();

  // do NOT i18 labelText here, should only be done at the callee
  return (
    <div className={clsx("formGroup", extraClassName && extraClassName)} style={props.style}>
      <label className={clsx(disabled && "disabled", hiddenLabel && "visuallyHidden")} htmlFor={`id_${name}`}>
        {labelText && (typeof "" === typeof labelText ? labelText : i18("label", "customNumericInput.label"))}
      </label>

      <NumericFormat
        className={clsx(extraClassName && extraClassName)}
        id={`id_${name}`}
        name={name}
        displayType="input"
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={decimalScale}
        value={BigNumber(props.value ?? 0)
          .decimalPlaces(decimalScale, BigNumber.ROUND_FLOOR)
          .toString()}
        allowNegative={false}
        allowLeadingZeros={false}
        disabled={disabled}
        style={inputStyle}
        {...props}
      />

      {helpText && <div className="helpText">{helpText}</div>}
    </div>
  );
}

export default CustomNumericInput;
