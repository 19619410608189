export const WHITE_LIST_PERSISTED_STATE_KEYS = {
  dashboardGrid: "dashboardGrid",
  globalSettings: "globalSettings", // globalSettings are on the localstorage + API/DB so that users without permits also have their settings saved on the device and for other users to instant load the settings instead of waiting for the request of the /user to come in
  connectedWallet: "connectedWallet",
  autoConnectWallet: "autoConnectWallet",
  autoConnectWalletChains: "autoConnectWalletChains",
  permits: "permits",
  poolsFilters: "poolsFilters",
  poolsTable: "poolsTable",
  farmsTable: "farmsTable",
  tokens: "tokens",
  poolsAssets: "poolsAssets",
  myAssets: "myAssets",
  myHistory: "myHistory",
  favouriteAssets: "favouriteAssets",
  assetsRows: "assetsRows",
  isSideMenuOpen: "isSideMenuOpen",
  agreedCompliance: "agreedCompliance",
  lastSelectionOnTradePage: "lastSelectionOnTradePage",
};

const INITIAL_STATE = {
  connectedWallet: "",
  autoConnectWallet: false,
  autoConnectWalletChains: [],
  isSideMenuOpen: false,
  permits: {},
  tokens: [],
  poolsAssets: {},
  poolsFilters: {
    sortByDescending: false,
    selectedFilter: "Filter",
    selectedSort: "Sort",
    searchedPools: "",
  },
  poolsTable: {
    topFilter: "all",
    poolTypeFilter: null,
    selectedSort: null,
    sortOrder: null,
  },
  farmsTable: {
    topFilter: "all",
    selectedSort: null,
    sortOrder: null,
  },
  myAssets: {
    filter: "all",
  },
  myHistory: {
    filter: "all",
  },
  assetsRows: {},
  favouriteAssets: [],
  agreedCompliance: false,
  lastSelectionOnTradePage: null
};

export const persistState = (stateKey, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(stateKey, serializedState);
  } catch (err) {
    return setPersistedToInitial(stateKey);
  }
};

export const setPersistedToInitial = (stateKey: string) => {
  const state = INITIAL_STATE[stateKey];
  persistState(stateKey, state);
  return state;
};

export const loadState = (stateKey: string) => {
  try {
    const serializedState = localStorage.getItem(stateKey);
    if (serializedState === null) {
      return setPersistedToInitial(stateKey);
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return setPersistedToInitial(stateKey);
  }
};

export const removeState = (stateKey: string) => {
  localStorage.removeItem(stateKey);
};
