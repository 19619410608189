import { useEffect } from "react";
import CustomInput from "@axvdex/components/form-element/CustomInput";
import useLanguage from "@axvdex/hooks/useLanguage";

export const IbcTransferDetailsContainer = ({
  connectedChains,
  fromSelectedToken,
  toSelectedToken,
  toAddr,
  setToAddr,
  simulationValues,
}: any) => {
  const { i18 } = useLanguage();

  useEffect(() => {
    setToAddr(
      toSelectedToken.extraFields?.walletChainExternalContext?.address ||
        connectedChains[toSelectedToken.extraFields?.contextChainId]?.address ||
        ""
    );
  }, [toSelectedToken, connectedChains]);

  if (!connectedChains[toSelectedToken.extraFields?.contextChainId]) return null;
  return (
    <ul className="tradeFormList">
      <CustomInput
        name="from_address"
        labelText={i18("From address (connected wallet)", "trade.form.ibcTransfer.fromAdresss.label")}
        title={i18("From address (connected wallet)", "trade.form.ibcTransfer.fromAdresss.title")}
        value={
          fromSelectedToken.extraFields?.walletChainExternalContext?.address ||
          connectedChains[fromSelectedToken.extraFields?.contextChainId]?.address ||
          ""
        }
        readOnly
        style={{ cursor: "default" }}
      />

      {!simulationValues.error && (
        <>
          <br />
          <CustomInput
            name="to_address"
            labelText={i18("To address", "trade.form.ibcTransfer.toAdresss.label")}
            title={i18("To address", "trade.form.ibcTransfer.toAdresss.title")}
            value={toAddr}
            onChange={e => setToAddr(e.target.value)}
          />
        </>
      )}
    </ul>
  );
};
