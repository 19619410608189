import { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useMediaQuery, useOnClickOutside } from "usehooks-ts";
import FooterUtilitiesNavItems from "@axvdex/components/FooterUtilitiesNavItems";

import useLanguage from "@axvdex/hooks/useLanguage";
import { responsiveBreakpoints } from "../constants";
import styles from "../styles/HeaderUtilitiesMenu.module.scss";
import ButtonMenu from "./common/ButtonMenu";

/*
  "headerUtitilitesMenu.btnMenu.open": "Open menu", "headerUtitilitesMenu.btnMenu.close": "Close menu",
*/
function HeaderUtilitiesMenu() {
  const { i18 } = useLanguage();
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const headerMenuRef = useRef(null);
  const [openHeaderMenu, setOpenHeaderMenu] = useState(false);
  const handleToggleHeaderMenu = useCallback(() => {
    setOpenHeaderMenu(openHeaderMenu => !openHeaderMenu);
  }, []);
  const handleCloseHeaderMenu = useCallback(() => setOpenHeaderMenu(false), []);

  useEffect(() => {
    if (isMobileBreakpoint) {
      setOpenHeaderMenu(false);
    }
  }, [openHeaderMenu, setOpenHeaderMenu, isMobileBreakpoint]);

  useOnClickOutside(headerMenuRef, handleCloseHeaderMenu);

  return (
    <section className={clsx(styles.sectionHeaderUtilitiesMenu, "sectionHeaderUtilitiesMenu")} ref={headerMenuRef}>
      <ButtonMenu
        extraClassName={clsx(openHeaderMenu && "isOpen", "btnMenuHeader")}
        onClick={handleToggleHeaderMenu}
        title={
          openHeaderMenu
            ? i18("Close menu", "headerUtitilitesMenu.btnMenu.close")
            : i18("Open menu", "headerUtitilitesMenu.btnMenu.open")
        }
      >
        <span className="headerNavItemsListText">{i18("Menu", "headerUtitilitesMenu.btnMenu")}</span>
      </ButtonMenu>
      <aside className={clsx("headerUtilitiesMenu", openHeaderMenu && "isOpen")}>
        <div className="headerUtilitiesMenuWrapper">
          <div className="headerUtilitiesMenuInner">
            <FooterUtilitiesNavItems />
          </div>
        </div>
      </aside>
    </section>
  );
}

export default HeaderUtilitiesMenu;
