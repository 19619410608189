import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
// import { NumericFormat } from "react-number-format";
import BigNumber from "bignumber.js";
import useLanguage from "@axvdex/hooks/useLanguage";
import {
  IFarm,
  // ILpBalance,
  IPool,
} from "@axvdex/utils/interfaces";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  selectAssets,
  selectFarmsLpBalance,
  selectPoolsLpBalance,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import imgSanitize from "@axvdex/utils/imgSanitize";
import { abbreviateUSD, formatBalance } from "@axvdex/utils/formatNumber";
import { collectRewardsAction } from "@axvdex/state/wallet/walletThunks";
import { updateFarmsLpBalance, updatePoolsLpBalance } from "@axvdex/state/wallet/walletSlice";
// import { switchToken } from "../../hooks/SwitchToken";
// import { useGlobalModalContext } from "../../providers/GlobalModalProvider";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import Button from "../common/Button";
import CustomModal from "../common/CustomModal";
// import UserRewardsAmount from "../user/UserRewardsAmount";
// import ToastSuccessCollectRewards from "../toasts/ToastSuccessCollectRewards";
import styles from "../../styles/CollectRewardsModal.module.scss";

// import { DummyCollectRewardsModalList } from "../../mocks/DummyData";
import CustomLoader from "../common/CustomLoader";
import { FarmRepresentation } from "../common/FarmRepresentation";
import CustomInputButton from "../form-element/CustomInputButton";

interface RewardsModalProps {
  poolsToShow: IPool[];
  farmsToShow: IFarm[];
  triggerElement: any;
  specificChainId?: string;
}

function CollectRewardModal({ poolsToShow, farmsToShow, triggerElement, specificChainId }: RewardsModalProps) {
  const { i18 } = useLanguage();
  const poolsLpBalance = useAppSelector(selectPoolsLpBalance);
  const farmsLpBalance = useAppSelector(selectFarmsLpBalance);
  const assets = useAppSelector(selectAssets);
  const dispatch = useAppDispatch();
  const walletInfo = useAppSelector(selectWalletInfo);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(
    1 === poolsToShow.length ? poolsToShow[0] : 1 === farmsToShow.length ? farmsToShow[0] : null
  );
  const handleToggleModal = useCallback(() => {
    setModalIsOpen(modalIsOpen => !modalIsOpen);
  }, []);
  const handleCloseModal = () => setModalIsOpen(false);
  const [loadedForCollectRewards, setLoadedForCollectRewards] = useState(false);

  useEffect(() => {
    if (modalIsOpen && poolsToShow.length > 0) updateLpPoolsBalance();
    if (modalIsOpen && farmsToShow.length > 0) updateLpFarmsBalance();
  }, [modalIsOpen, poolsToShow]);

  const [tabSelected, setTabSelected] = useState(null);
  useEffect(() => {
    if (Object.keys(walletInfo.connectedChains).length > 0 && !tabSelected) {
      setTabSelected(specificChainId ? specificChainId : Object.keys(walletInfo.connectedChains)[0]);
    }
  }, [walletInfo]);

  const updateLpPoolsBalance = async () => {
    const lpBalanceRequests = [];
    poolsToShow.forEach(pool => {
      const walletChainContext = walletInfo.connectedChains[pool.contextChainId];
      lpBalanceRequests.push(
        rpcClientQuerySmartContractWrapper(walletChainContext.signingClient, pool.lp_staking, {
          balance: { address: walletChainContext.address },
        })
      );
    });

    dispatch(
      updatePoolsLpBalance(
        (await Promise.all(lpBalanceRequests)).reduce((a, v, i) => {
          if (v) {
            return {
              ...a,
              [poolsToShow[i].lp_staking]: v,
            };
          }
          return { ...a };
        }, {})
      )
    );
  };

  const updateLpFarmsBalance = async () => {
    const lpBalanceRequests = [];
    farmsToShow.forEach(farm => {
      const walletChainContext = walletInfo.connectedChains[farm.contextChainId];
      lpBalanceRequests.push(
        rpcClientQuerySmartContractWrapper(walletChainContext.signingClient, farm.address, {
          balance: { address: walletChainContext.address },
        })
      );
    });

    dispatch(
      updateFarmsLpBalance(
        (await Promise.all(lpBalanceRequests)).reduce((a, v, i) => {
          if (v) {
            return {
              ...a,
              [farmsToShow[i].address]: v,
            };
          }
          return { ...a };
        }, {})
      )
    );
  };

  const itemRewardsSummary = (item, isPool) => {
    const rewardAssets = [];
    const rewardAssetsBalance = {};
    let accumulatedRewards = BigNumber(0);

    // filter out the items that dont belong to the chain selected
    if (item.contextChainId !== tabSelected) return null;

    const lpbalance = isPool ? poolsLpBalance[item.lp_staking] : farmsLpBalance[item.address];

    if (!lpbalance) return null;

    lpbalance.rewards.forEach(reward => {
      if ("0" !== reward.rewards) {
        const rewardAsset = reward.asset.token?.contract_addr || reward.asset.native_token?.denom;
        accumulatedRewards = accumulatedRewards.plus(
          BigNumber(reward.rewards).div(Math.pow(10, assets[rewardAsset].decimals)).times(assets[rewardAsset].price)
        );
        rewardAssets.push(assets[rewardAsset]);
        if (rewardAssetsBalance[rewardAsset]) {
          rewardAssetsBalance[rewardAsset] = BigNumber(rewardAssetsBalance[rewardAsset])
            .plus(reward.rewards)
            .toString(10);
        } else {
          rewardAssetsBalance[rewardAsset] = reward.rewards;
        }
      }
    });

    if (accumulatedRewards.eq(0)) return null;
    return (
      <>
        {" "}
        <li
          key={item.address}
          className={`collectRewardsListItem ${
            itemSelected && itemSelected.address === item.address ? "" : "withGradientBorderBottom"
          }`}
          onClick={() =>
            !itemSelected || itemSelected.address !== item.address ? setItemSelected(item) : setItemSelected(null)
          }
        >
          <ul
            className="tokenGridList collectRewardsListItemTokensList"
            style={{ display: !isPool ? "flex" : undefined }} // if its a farm, it needs to be display flex
          >
            {isPool ? (
              item.poolAssets.map((asset, i) => {
                const poolAsset = assets[asset.info.token?.contract_addr || asset.info.native_token?.denom];
                return (
                  <li key={i}>
                    <span title={poolAsset.symbol} aria-label={poolAsset.symbol}>
                      <img className="tokenIcon" src={imgSanitize(poolAsset.symbol)} alt={poolAsset.symbol} />
                    </span>
                  </li>
                );
              })
            ) : (
              <FarmRepresentation
                address={item.address}
                inputAsset={{
                  label: assets[item.config.inc_token]?.label,
                  symbol: assets[item.config.inc_token]?.symbol,
                }}
                outputAssets={item.reward_sources.map(rewardSource => {
                  const rewardAsset =
                    assets[
                      rewardSource.reward_asset.token?.contract_addr || rewardSource.reward_asset.native_token?.denom
                    ];
                  return {
                    label: rewardAsset?.label,
                    symbol: rewardAsset?.symbol,
                  };
                })}
              />
            )}
          </ul>

          {1 < poolsToShow.length && (
            <div className={clsx("inlineFlexbox")}>
              <span className="collectRewardsListItemAmount">{abbreviateUSD(accumulatedRewards)}</span>
            </div>
          )}
        </li>
        {itemSelected && itemSelected.address === item.address && (
          <div className={`poolRewardsExtraDetails withGradientBorderBottom`}>
            <>
              {[...new Set(rewardAssets)].map((asset, idx) => {
                if (!rewardAssetsBalance[asset.id]) return <></>;
                const formatRewardAmount = BigNumber(rewardAssetsBalance[asset.id])
                  .div(Math.pow(10, asset.decimals))
                  .decimalPlaces(4)
                  .toString(10);

                return (
                  <li className="collectRewardsDetailsListItem total" key={idx}>
                    {isPool ? (
                      <em>
                        {0 === idx &&
                          i18(item.poolAssetSymbols.join(" ") + " - Pool", "collectRewardModal.poolList", {
                            poolAssetSymbols: item.poolAssetSymbols.join(" "),
                          })}
                      </em>
                    ) : (
                      <em>
                        {0 === idx &&
                          !isPool &&
                          i18(
                            item.reward_sources
                              .map(
                                rs =>
                                  assets[rs.reward_asset.token?.contract_addr || rs.reward_asset.native_token?.denom]
                                    .symbol
                              )
                              .join(" ") + " - Farm",
                            "collectRewardsModal.farmList",
                            {
                              farmAssetSymbols: item.reward_sources
                                .map(
                                  rs =>
                                    assets[rs.reward_asset.token?.contract_addr || rs.reward_asset.native_token?.denom]
                                      .symbol
                                )
                                .join(" "),
                            }
                          )}
                      </em>
                    )}

                    {1 < poolsToShow.length && (
                      <div className="poolRewardsDetails">
                        <span className={"swiper-no-swiping inlineFlexbox"}>
                          &nbsp;
                          {formatRewardAmount}{" "}
                          <small>
                            (
                            {abbreviateUSD(
                              formatBalance(rewardAssetsBalance[asset.id], asset.price, asset.decimals).usdConversion
                            )}
                            )
                          </small>
                          <span className="rewardAssetIconWrapper" title={asset.symbol} aria-label={asset.symbol}>
                            <img src={imgSanitize(asset.symbol)} alt={asset.symbol} className="rewardAssetIcon" />
                          </span>
                        </span>
                      </div>
                    )}
                  </li>
                );
              })}
            </>
          </div>
        )}
      </>
    );
  };

  const itemsTotalRewards = () => {
    const rewardAssets = [];
    const rewardAssetsBalance = {};
    let accumulatedRewards = BigNumber(0);

    const filteredPools = Object.keys(poolsLpBalance).filter(addr =>
      poolsToShow.find(poolToShow => poolToShow.lp_staking === addr && poolToShow.contextChainId === tabSelected)
    );
    filteredPools.forEach(pool => {
      const balance = poolsLpBalance[pool];
      balance.rewards.forEach(reward => {
        if ("0" !== reward.rewards) {
          const rewardAsset = reward.asset.token?.contract_addr || reward.asset.native_token?.denom;
          const rewardsFormated = BigNumber(reward.rewards)
            .div(Math.pow(10, assets[rewardAsset].decimals))
            .times(assets[rewardAsset].price);

          accumulatedRewards = accumulatedRewards.plus(rewardsFormated);
          rewardAssets.push(assets[rewardAsset]);

          if (rewardAssetsBalance[rewardAsset]) {
            rewardAssetsBalance[rewardAsset] = BigNumber(rewardAssetsBalance[rewardAsset])
              .plus(reward.rewards)
              .toString(10);
          } else {
            rewardAssetsBalance[rewardAsset] = reward.rewards;
          }
        }
      });
    });

    const filteredFarms = Object.keys(farmsLpBalance).filter(addr =>
      farmsToShow.find(farmToShow => farmToShow.address === addr && farmToShow.contextChainId === tabSelected)
    );
    filteredFarms.forEach(farm => {
      const balance = farmsLpBalance[farm];
      balance.rewards.forEach(reward => {
        if ("0" !== reward.rewards) {
          const rewardAsset = reward.asset.token?.contract_addr || reward.asset.native_token?.denom;
          const rewardsFormated = BigNumber(reward.rewards)
            .div(Math.pow(10, assets[rewardAsset].decimals))
            .times(assets[rewardAsset].price);

          accumulatedRewards = accumulatedRewards.plus(rewardsFormated);
          rewardAssets.push(assets[rewardAsset]);

          if (rewardAssetsBalance[rewardAsset]) {
            rewardAssetsBalance[rewardAsset] = BigNumber(rewardAssetsBalance[rewardAsset])
              .plus(reward.rewards)
              .toString(10);
          } else {
            rewardAssetsBalance[rewardAsset] = reward.rewards;
          }
        }
      });
    });

    return (
      <>
        <div className="totalRewards">
          <div className="tokenAmountsColumn">
            {[...new Set(rewardAssets)].map((asset, idx) => {
              if (!rewardAssetsBalance[asset.id]) return <></>;
              const formatRewardAmount = BigNumber(rewardAssetsBalance[asset.id])
                .div(Math.pow(10, asset.decimals))
                .decimalPlaces(4)
                .toString(10);

              return (
                <div className="totalRewardsDetails" key={idx}>
                  <span className={"swiper-no-swiping inlineFlexbox"}>
                    <span className="rewardAssetIconWrapper" title={asset.symbol} aria-label={asset.symbol}>
                      <img src={imgSanitize(asset.symbol)} alt={asset.symbol} className="rewardAssetIcon" />
                    </span>
                    &nbsp;
                    {formatRewardAmount}{" "}
                    <small>
                      (
                      {abbreviateUSD(
                        formatBalance(rewardAssetsBalance[asset.id], asset.price, asset.decimals).usdConversion
                      )}
                      )
                    </small>
                  </span>
                </div>
              );
            })}
          </div>
          <div className="totalColumn total">
            <em>{i18("Total Rewards", "collectRewardsModal.total")}</em>
            <span className={clsx("userRewardsAmount")}>{abbreviateUSD(accumulatedRewards)}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {"text" === triggerElement && (
        <Button
          btnColor="dark-medium"
          text={i18("Collect Rewards", "collectRewardsModal.btnCollect.text")}
          title={i18("Collect Rewards", "collectRewardsModal.btnCollect.title")}
          onClick={handleToggleModal}
        />
      )}

      {"icon" === triggerElement && (
        <Button
          extraClassName={clsx("btnFavourite")}
          title={i18("Rewards info", "collectRewardsModal.btnFarmCollect.title")}
          btnVariant="icon"
          btnColor={"purple"}
          onClick={handleToggleModal}
        >
          <i className="feather icon-heart" />
        </Button>
      )}

      <CustomModal
        isOpen={modalIsOpen}
        onClose={handleToggleModal}
        extraClassName={clsx(styles.collectRewardsModal, "collectRewardsModal")}
      >
        <section className="sectionModalHeader">
          <h1 className="h2">{i18("Collect Rewards", "collectRewardsModal.header")}</h1>
        </section>

        <section className="sectionCollectRewards">
          <fieldset>
            <legend className="visuallyHidden">{i18("Select tab", "collectRewardsModal.tabs.legend")}</legend>
            <div className="btnGroup filterBtnGroup" style={{ width: "inherit", marginBottom: "2em" }}>
              {Object.keys(walletInfo.connectedChains)
                .filter(chainId => {
                  if (specificChainId) return specificChainId === chainId;
                  return true;
                })
                .map((chainId, i) => {
                  return (
                    <CustomInputButton
                      key={"collectRewardsModal_" + chainId}
                      type="radio"
                      id={"collectRewardsModal_" + chainId}
                      defaultChecked={tabSelected === chainId}
                      name={"collectRewards_filter"}
                      labelText={walletInfo.connectedChains[chainId].chainState.displayName}
                      onClick={() => setTabSelected(chainId)}
                    />
                  );
                })}
            </div>
          </fieldset>
          <ul className="collectRewardsList withGradientBorderBottom">
            {poolsToShow.map(pool => itemRewardsSummary(pool, true))}
            {farmsToShow.map(farm => itemRewardsSummary(farm, false))}
            {itemsTotalRewards()}
          </ul>

          <div className="buttonContainer">
            <Button
              btnColor="gradientText"
              text={i18("Cancel", "collectRewardsModal.btnCancel.text")}
              title={i18("Cancel and Close", "collectRewardsModal.btnCancel.title")}
              onClick={handleCloseModal}
            />
            {!loadedForCollectRewards ? (
              <Button
                btnColor="gradient"
                text={i18("Collect", "collectRewardsModal.btnCollectAll.text.1")}
                title={i18("Collect Rewards", "collectRewardsModal.btnCollectAll.title")}
                disabled={
                  0 ===
                    poolsToShow.filter(pool => {
                      return (
                        poolsLpBalance[pool.lp_staking] &&
                        poolsLpBalance[pool.lp_staking].rewards.find(balance => "0" !== balance.rewards)
                      );
                    }).length &&
                  0 ===
                    farmsToShow.filter(farm => {
                      return (
                        farmsLpBalance[farm.address] &&
                        farmsLpBalance[farm.address].rewards.find(balance => "0" !== balance.rewards)
                      );
                    }).length
                }
                onClick={async () => {
                  setLoadedForCollectRewards(true);
                  await dispatch(
                    collectRewardsAction({
                      poolsData: poolsToShow.filter(
                        pool =>
                          tabSelected === pool.contextChainId &&
                          poolsLpBalance[pool.lp_staking].rewards.find(balance => "0" !== balance.rewards)
                      ),
                      farmsData: farmsToShow.filter(
                        farm =>
                          tabSelected === farm.contextChainId &&
                          farmsLpBalance[farm.address].rewards.find(balance => "0" !== balance.rewards)
                      ),
                      i18,
                    })
                  );
                  setLoadedForCollectRewards(false);
                }}
              />
            ) : (
              <CustomLoader size="xs" />
            )}
          </div>
        </section>
      </CustomModal>
    </>
  );
}

export default CollectRewardModal;
