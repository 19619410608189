import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import { IAsset, IChain, IGlobalConfig } from "./interfaces";

export const getChainsToConnectTo = (
  globalConfig: IGlobalConfig,
  chains: {
    [key: string]: IChain;
  }
) => {
  const possibleChainsToConnect = globalConfig.chainContexts
    .filter((chainContext: any) => !chainContext.disabled)
    .map((chainContext: any) => chains[chainContext.chainId]);

  // check if there are any chains to auto connect to on the local storage
  // if not, connect to ALL by default
  // we cant get this from user object as it is not loaded yet when this runs usually
  const autoConnectWalletChains = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWalletChains);

  let chainsToConnect = [];
  if (autoConnectWalletChains && autoConnectWalletChains.length >= 1) {
    chainsToConnect = possibleChainsToConnect.filter(possibleChainToConnect =>
      autoConnectWalletChains.includes(possibleChainToConnect.chainId)
    );
  } else {
    chainsToConnect = possibleChainsToConnect;
  }

  return chainsToConnect;
};

export const AssetChainLogo = ({
  chain,
  asset,
  imgStyle,
  style,
  noText,
}: {
  chain?: IChain;
  asset?: IAsset;
  imgStyle?: { [key: string]: string };
  style?: { [key: string]: string };
  noText?: boolean;
}) => {
  if (chain) {
    try {
      return (
        <img
          title={chain.displayName}
          style={imgStyle}
          src={
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require(`../assets/logos/chain-${chain.chainId.toLocaleLowerCase()}.svg`)
          }
          alt="logo chain"
        />
      );
    } catch (e) {
      if (!noText) {
        return (
          <span title={`Chain ${chain.displayName}`} style={style}>
            ({chain.displayName})
          </span>
        );
      }
      return <></>;
    }
  }
  if (asset) {
    try {
      return (
        <img
          title={asset.symbol}
          style={imgStyle}
          src={
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require(`../assets/logos/asset-${asset.symbol.toLocaleLowerCase()}.svg`)
          }
          alt="logo asset"
        />
      );
    } catch (e) {
      if (!noText) {
        return (
          <span title={`Asset ${asset.symbol}`} style={style}>
            ({asset.symbol})
          </span>
        );
      }
      return <></>;
    }
  }
};
