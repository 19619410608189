import { useEffect, useState } from "react";
import clsx from "clsx";
// import { Circle, Settings, Shield, ShieldOff, XCircle } from "react-feather";
import { Edit } from "react-feather";
import BigNumber from "bignumber.js";
import useLanguage from "@axvdex/hooks/useLanguage";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  selectAssetBalances,
  selectAssets,
  selectContracts,
  selectNicknames,
  selectUser,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import { updateNativeBalance, updateTokenBalance } from "@axvdex/state/wallet/walletThunks";
// import { getNicknameProtection } from "@axvdex/state/outbid/saleThunks";
import { updateNickname, updateNicknamesFromBlockchain } from "@axvdex/state/outbid/saleThunks";
import Button from "../common/Button";
import CustomModal from "../common/CustomModal";
import styles from "../../styles/MyAssetsManageTokensModal.module.scss";
import CustomInput from "../form-element/CustomInput";
import { ReactComponent as IcnSword } from "../../assets/icons/icn-sword.svg";
import CustomLoader from "../common/CustomLoader";
import { responsiveBreakpoints } from "@axvdex/constants";
import { useMediaQuery } from "usehooks-ts";

function SaleNicknameTargetModal({ targetAddress, chainID }: any) {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const isMobileSmBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const user = useAppSelector(selectUser);
  const nicknames = useAppSelector(selectNicknames);
  const contracts = useAppSelector(selectContracts);
  const walletInfo = useAppSelector(selectWalletInfo);
  const walletConnectedChainInfo = walletInfo.connectedChains[chainID];
  const assets = useAppSelector(selectAssets);
  const assetBalance = useAppSelector(selectAssetBalances);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkboxConfirmation, setCheckboxConfirmation] = useState(false);

  const [nickname, setNickname] = useState("");

  useEffect(() => {
    if (contracts && contracts["nicknames_" + chainID] && isOpen) {
      getNickname();
    }
  }, [isOpen, contracts]);

  useEffect(() => {
    if (checkboxConfirmation) {
      const actionInfo = getUpdateNicknameAction();
      const paymentAssetID =
        actionInfo.payment_assets[0].info.token?.contract_addr || actionInfo.payment_assets[0].info.native_token?.denom;
      if (!assetBalance[paymentAssetID]) {
        if (!assets[paymentAssetID].isNative) {
          dispatch(
            updateTokenBalance({
              client: walletConnectedChainInfo.signingClient,
              tokenAddress: assets[paymentAssetID].address,
              userAddress: walletConnectedChainInfo.address,
            })
          );
        } else {
          dispatch(
            updateNativeBalance({
              client: walletConnectedChainInfo.signingClient,
              denom: assets[paymentAssetID].denom,
              userAddress: walletConnectedChainInfo.address,
            })
          );
        }
      }
    }
  }, [checkboxConfirmation]);

  const getNickname = async () => {
    if (!nicknames[targetAddress]) {
      const nickname = await dispatch(
        updateNicknamesFromBlockchain({
          chainId: chainID,
          client: walletConnectedChainInfo.signingClient,
          addresses: [targetAddress],
        })
      );
      setNickname(nickname.payload[targetAddress]?.nickname || "");
    } else {
      setNickname(nicknames[targetAddress]?.nickname || "");
    }
  };

  const getUpdateNicknameAction = () => {
    if (!contracts["nicknames_" + chainID]) return null;
    const actions = contracts["nicknames_" + chainID].extraFields.actions;
    if (!actions) return null;
    if (!nicknames[targetAddress] || "" === nicknames[targetAddress].nickname) {
      return actions.InitSetOtherNickname;
    }
    return actions.OverrideOtherNickname;
  };

  const getFeeString = (action: any) => {
    if (!action) return null;
    const asset =
      assets[action.payment_assets[0].info.token?.contract_addr || action.payment_assets[0].info.native_token?.denom];
    const amount = BigNumber(action.payment_assets[0].amount).div(Math.pow(10, asset.decimals));
    return `${amount} ${asset.symbol}`;
  };

  const getPaymentAssetID = (action: any) =>
    action?.payment_assets[0].info.token?.contract_addr || action?.payment_assets[0].info.native_token?.denom;

  if (user?.myGlobalSettings?.noNicknames) return null;
  return (
    <>
      {
        <Button
          title="Edit Nickname"
          btnColor="dark-medium"
          style={{
            padding: ".25em",
            paddingLeft: "1em",
            paddingRight: "1em",
            width: isMobileSmBreakpoint ? ".75em" : "auto",
          }}
          icon={<Edit style={{ color: "white", width: isMobileSmBreakpoint ? ".75em" : "auto" }} />}
          onClick={() => setIsOpen(true)}
        />
      }

      <CustomModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        extraClassName={clsx(styles.permitAuthenticationModal, "permitAuthenticationModal")}
      >
        <section className="sectionModalHeader">
          <h2 className="h2">{i18("Update Nickname", "userWallet.settings.title")}</h2>
        </section>

        <section className="sectionModalContent withGradientBorderBottom">
          <div className="inlineFlexbox" style={{ width: "100%", placeContent: "space-between" }}>
            <CustomInput
              name="nickname"
              labelText={i18(
                "Nickname (A-Z, 0-9, _ and - with 20 characters max.)",
                "userWallet.settings.nicknameDetail"
              )}
              title={i18(`Nickname`, "userWallet.settings.nickname")}
              // description="Used in: Outbid"
              value={nickname}
              onChange={e => {
                const regexPattern = /^[A-Za-z0-9_-]{0,16}$/;
                if (regexPattern.test(e.target.value)) {
                  setNickname(e.target.value);
                  setCheckboxConfirmation(false);
                }
              }}
            />
          </div>
        </section>

        <div className="inlineFlexbox" style={{ width: "100%" }}>
          <div className="inlineFlexbox" style={{ width: "65%" }}>
            <input
              type="checkbox"
              checked={checkboxConfirmation}
              onChange={e => setCheckboxConfirmation(e.target.checked)}
            />

            {<span>pay to update this nickname</span>}
          </div>
          <div style={{ marginLeft: "auto", textAlign: "center" }}>
            {loading ? (
              <CustomLoader size="xs" />
            ) : (
              <Button
                btnColor="gradientOrange"
                title={"Update"}
                style={{ marginBottom: ".5em" }}
                onClick={async () => {
                  setLoading(true);
                  await dispatch(
                    updateNickname({
                      walletConnectedChainInfo,
                      payment: getUpdateNicknameAction().payment_assets[0],
                      nickname: nickname,
                      target_address: targetAddress,
                      i18,
                    })
                  );
                  setLoading(false);
                  setIsOpen(false);
                }}
                disabled={
                  !checkboxConfirmation ||
                  BigNumber(assetBalance[getPaymentAssetID(getUpdateNicknameAction())]).lt(
                    getUpdateNicknameAction().payment_assets[0].amount
                  )
                }
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {getFeeString(getUpdateNicknameAction())}

                  <div className="inlineFlexbox" style={{ marginTop: ".5em" }}>
                    <IcnSword /> Update
                  </div>
                </div>
              </Button>
            )}
            <br />
            {assetBalance[getPaymentAssetID(getUpdateNicknameAction())] && (
              <small>
                {"Balance: " +
                  BigNumber(assetBalance[getPaymentAssetID(getUpdateNicknameAction())])
                    .div(Math.pow(10, assets[getPaymentAssetID(getUpdateNicknameAction())].decimals))
                    .decimalPlaces(2) +
                  " " +
                  assets[getPaymentAssetID(getUpdateNicknameAction())].symbol}
              </small>
            )}
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default SaleNicknameTargetModal;
