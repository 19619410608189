import clsx from "clsx";
import { Activity, DollarSign, Droplet, Home, Repeat } from "react-feather";
import useLanguage from "@axvdex/hooks/useLanguage";
import { selectFarms } from "@axvdex/state/wallet/walletSelectors";
import { useAppSelector } from "@axvdex/state";
import styles from "../styles/NavItems.module.scss";
import { ReactComponent as IcnFarm } from "../assets/icons/icn-grow-heart.svg";
import ActiveLink from "./common/ActiveLink";
// import { ReactComponent as IcnDollar } from "../assets/icons/icn-dollar-sign.svg";

interface DashboardNavItemsProps {
  hideText?: boolean;
  hideIndex?: number;
}

function NavItems({ hideText, hideIndex }: DashboardNavItemsProps) {
  const { i18 } = useLanguage();
  const farms = useAppSelector(selectFarms);

  const DashboardMenuLinks = [
    {
      title: i18("See Dashboard", "navItems.link.dashboard.title"),
      text: i18("Dashboard", "navItems.link.dashboard.text"),
      href: "/",
      icon: <Home />,
      isDisabled: false,
      isHidden: false,
    },
    {
      title: i18("See Move & Trade", "navItems.link.trade.title"),
      text: i18("Move & Trade", "navItems.link.trade.text"),
      href: "/trade",
      icon: <Repeat />,
      isDisabled: false,
      isHidden: false,
    },
    {
      title: i18("See Pool", "navItems.link.pool.title"),
      text: i18("Pool", "navItems.link.pool.text"),
      href: "/pool",
      icon: <Droplet />,
      isDisabled: false,
      isHidden: false,
    },
    {
      title: i18("See Farm", "navItems.link.farm.title"),
      text: i18("Farm", "navItems.link.farm.text"),
      href: "/farm",
      icon: <IcnFarm />,
      isDisabled: false,
      isHidden: 0 === Object.keys(farms).length,
    },
    // { title: "Vote", href: "/vote", icon: <i className="feather icon-message-circle" />, isDisabled: true },
    {
      title: i18("See Statistics", "navItems.link.statistics.title"),
      text: i18("Statistics", "navItems.link.statistics.text"),
      href: "/statistics",
      icon: <Activity />,
      isDisabled: false,
      isHidden: false,
    },
    {
      title: i18("See Outbid", "navItems.link.sales.title"),
      text: i18("Outbid", "navItems.link.sales.text"),
      href: "/outbid",
      icon: <DollarSign />,
      isDisabled: false,
    },
  ];

  return (
    <ul className={clsx(styles.navItemsList, "navItemsList")}>
      {DashboardMenuLinks.map(
        (link, idx) =>
          hideIndex !== idx &&
          !link.isHidden && (
            <li key={idx}>
              <ActiveLink
                href={link.href}
                title={`${link.title}`}
                icon={link.icon}
                extraClassName={clsx(hideText && "textHidden")}
                isDisabled={link.isDisabled}
              >
                {!hideText && <span className="navLinkText">{link.text}</span>}
              </ActiveLink>
            </li>
          )
      )}
    </ul>
  );
}

export default NavItems;
