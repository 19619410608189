import clsx from "clsx";
import { Trans } from "react-i18next";
import { useMemo, useState } from "react";
import { findIndex } from "lodash";
import { Lightbox } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import LogoGravitate from "@axvdex/assets/images/help/dashboard/logo-gravitate-white.svg";
import HelpSubPageSection from "@axvdex/components/HelpSubPageSection";
import HelpSubPageSidebar from "@axvdex/components/HelpSubPageSidebar";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import useLanguage from "@axvdex/hooks/useLanguage";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/Help.module.scss";

function HelpTrade() {
  const { i18 } = useLanguage();

  const sectionSummary = [
    {
      name: i18("Trading assets", "help.trade.sectionSummary.tradingAssets.name"),
      url: "#tradingAssets",
    },
    {
      name: i18("Minting X assets", "help.trade.sectionSummary.mintingAssets.name"),
      url: "#mintingAssets",
    },
  ];

  const [index, setIndex] = useState(-1);

  const slides = useMemo(() => {
    return [
      // add images with id passed to onImageClick function
      {
        src: require("../assets/images/help/trade/Picture1.webp"),
        id: "trade/Picture1",
        title: i18("Trade view", "help.trade.slides.Picture1.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture2.webp"),
        id: "trade/Picture2",
        title: i18("Asset to be traded", "help.trade.slides.Picture2.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture3.webp"),
        id: "trade/Picture3",
        title: i18("Asset wanted in trade", "help.trade.slides.Picture3.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture4.webp"),
        id: "trade/Picture4",
        title: i18("Amount of asset to trade", "help.trade.slides.Picture4.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture5.webp"),
        id: "trade/Picture5",
        title: i18("Asset value in a currency", "help.trade.slides.Picture5.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture6.webp"),
        id: "trade/Picture6",
        title: i18('Wallet balance, "Max" and trade settings', "help.trade.slides.Picture6.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture7.webp"),
        id: "trade/Picture7",
        title: i18('Trade "Max" button result', "help.trade.slides.Picture7.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture8.webp"),
        id: "trade/Picture8",
        title: i18("Trade action slippage settings", "help.trade.slides.Picture8.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture9.webp"),
        id: "trade/Picture9",
        title: i18("Trade action slippage settings custom", "help.trade.slides.Picture9.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture10.webp"),
        id: "trade/Picture10",
        title: i18("Trade action, wanted asset", "help.trade.slides.Picture10.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture11.webp"),
        id: "trade/Picture11",
        title: i18("Trade reverse button", "help.trade.slides.Picture11.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture12.webp"),
        id: "trade/Picture12",
        title: i18("Trade action reverse button outcome", "help.trade.slides.Picture12.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture13.webp"),
        id: "trade/Picture13",
        title: i18("Trade action bottom information", "help.trade.slides.Picture13.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture14.webp"),
        id: "trade/Picture14",
        title: i18("Trade wallet confirm transaction", "help.trade.slides.Picture14.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture15.webp"),
        id: "trade/Picture15",
        title: i18("Trade transaction confirmation success", "help.trade.slides.Picture15.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture16-cancelled.webp"),
        id: "trade/Picture16.cancelled",
        title: i18("Trade transaction cancelled", "help.trade.slides.Picture16.title.cancelled"),
      },
      {
        src: require("../assets/images/help/trade/Picture16.webp"),
        id: "trade/Picture16",
        title: i18("Trade transaction confirmation failed", "help.trade.slides.Picture16.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture17.webp"),
        id: "trade/Picture17",
        title: i18("Trade minting X assets", "help.trade.slides.Picture17.title"),
      },
      {
        src: require("../assets/images/help/trade/Picture18.webp"),
        id: "trade/Picture18",
        title: i18("Trade minting actions", "help.trade.slides.Picture18.title"),
      },
    ];
  }, []);

  const onImageClick = (imageName: string) => {
    const index_ = findIndex(slides, { id: imageName });
    setIndex(index_);
  };

  return (
    <MainLayout
      pageClass={clsx(styles.help, "help", "helpTrade")}
      headTitle={i18("Help :: Trade", "help.trade.head.title")}
      headDescription={i18("Help for trade on Astrovault", "help.trade.head.description")}
    >
      <div className="helpPageWrapper">
        <div className="sectionWrapperLg">
          <section className="helpSectionHeader withGradientBorderBottom">
            <h1>{i18("Exploring Trade", "help.trade.section.title")}</h1>

            <p>
              <Trans i18nKey={"help.trade.section.description.text.1"}>
                <strong>Astrovault&#8217;s</strong> innovative trading solution, that provides unparalleled liquidity,
                is the reason many of you are here.
              </Trans>
            </p>

            <p>
              <Trans i18nKey={"help.trade.section.description.text.2"}>
                So, let&#8217;s delve into the trade view and see how you will use Astrovault&#8217;s benefits every
                day.
              </Trans>
            </p>
          </section>

          <div className="helpSubPageGrid">
            <HelpSubPageSidebar summaryTitle={i18("Trade", "help.trade.sidebar.title")} items={sectionSummary} />

            <div className="helpSubPageContent">
              <HelpSubPageSection
                sectionId="tradingAssets"
                sectionTitle={i18("Trading assets", "help.trade.subpage.section.tradingAssets.title")}
              >
                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.1"}>
                    After clicking the trade tab on the left of your view you should see this.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture1.webp`)}
                  onClick={() => onImageClick("trade/Picture1")}
                  alt={i18("Trade", "help.trade.tradingAssets.picture1.alt")}
                  width={885}
                  height={1022}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.2"}>
                    This should look familiar to you if you have used a DEX (Decentralised Exchange) in the past, if
                    not, let&#8217;s dive into the meaning of the content on this view.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture2.webp`)}
                  onClick={() => onImageClick("trade/Picture2")}
                  alt={i18("Trade First Asset", "help.trade.tradingAssets.picture2.alt")}
                  width={797}
                  height={319}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.3"}>
                    This is the first asset in the trade or exchange. It gives the ticker for the asset, in this case
                    ARCH. The downward pointing arrow can be clicked to reveal other assets that can be traded.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture3.webp`)}
                  onClick={() => onImageClick("trade/Picture3")}
                  alt={i18("Trade Other Assets", "help.trade.tradingAssets.picture3.alt")}
                  width={679}
                  height={384}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.4"}>
                    These are the assets that Astrovault has a market for. The amount of each asset that you own in your
                    connected wallet is shown on the right.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.5"}>
                    Let&#8217;s select ARCH and explore further.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture4.webp`)}
                  onClick={() => onImageClick("trade/Picture4")}
                  alt={i18("Trade action example", "help.trade.tradingAssets.picture4.alt")}
                  width={739}
                  height={183}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.6"}>
                    The number 3 is entered and underneath it gives an option to modify as USD (United States Dollar).
                    Let&#8217;s do that and see what happens.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture5.webp`)}
                  onClick={() => onImageClick("trade/Picture5")}
                  alt={i18("Trade action example result", "help.trade.tradingAssets.picture5.alt")}
                  width={776}
                  height={199}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.7"}>
                    Astrovault is designed with YOU in mind, and this automatic calculation into dollars can make it
                    much easier to understand the value of your transaction.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.8"}>
                    Taking a further look at the bottom below the horizontal line.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture6.webp`)}
                  onClick={() => onImageClick("trade/Picture6")}
                  alt={i18("Trade action buttons", "help.trade.tradingAssets.picture6.alt")}
                  width={738}
                  height={296}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.9"}>
                    You can see what your total wallet balance of ARCH is, as well as two other buttons Max and the
                    Gear.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.10"}>Pressing Max will do the following.</Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture7.webp`)}
                  onClick={() => onImageClick("trade/Picture7")}
                  alt={i18("Trade action Max button result", "help.trade.tradingAssets.picture7.alt")}
                  width={733}
                  height={294}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.11"}>
                    This will put the full balance of ARCH in the wallet into the trade input panel. And again if we
                    wish to see what that whole balance is in dollars, we can simply click the Modify as USD button.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.12"}>
                    Now, the gear shows trade config, and here we can set Slippage Tolerance.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture8.webp`)}
                  onClick={() => onImageClick("trade/Picture8")}
                  alt={i18("Trade action Slippage Settings", "help.trade.tradingAssets.picture8.alt")}
                  width={764}
                  height={224}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.13"}>
                    All markets have a degree of slippage. Even the most liquid equities listed on the New York Stock
                    Exchange have a degree of slippage. Trades using Astrovault&#8217;s stable pools are slippage-free,
                    however, slippage can occur on the standard pools (read more about{" "}
                    <a
                      href="https://www.investopedia.com/terms/s/slippage.asp"
                      target="_blank"
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      slippage
                    </a>
                    ). Due to the nature of DEX trading, the assets you are exchanging come from liquidity pools, as
                    theses pools are balanced, the exchange rate may differ from what is shown on the trade view. This
                    will require you to set a tolerance for price change. To have the trade go through. You can choose
                    from the options or set your own custom percentage.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture9.webp`)}
                  onClick={() => onImageClick("trade/Picture9")}
                  alt={i18("Trade action Slippage Settings Custom", "help.trade.tradingAssets.picture9.alt")}
                  width={750}
                  height={285}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.14"}>
                    Rest assured that despite your slippage tolerance, Astrovault will seek the lowest possible slippage
                    amount for the trade to go through. So, it is possible that you will receive less slippage than the
                    amount you select.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.15"}>
                    Let&#8217;s continue and look at the other side of the trade.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture10.webp`)}
                  onClick={() => onImageClick("trade/Picture10")}
                  alt={i18("Trade action next", "help.trade.tradingAssets.picture10.alt")}
                  width={770}
                  height={269}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.16"}>
                    In the above example we can see the amount of ATOM we will get from trading ARCH, as well as the
                    balance of ATOM in our wallet.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.17"}>
                    Please note that this amount CANNOT be edited. Due to the innovative way that Astrovault provides
                    its unparalleled liquidity, only the top token can have its amount edited.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.18"}>Instead, you must use this button:</Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture11.webp`)}
                  onClick={() => onImageClick("trade/Picture11")}
                  alt={i18("Trade reverse button", "help.trade.tradingAssets.picture11.alt")}
                  width={40}
                  height={40}
                  loading="lazy"
                  className="icon lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.19"}>When pressed the following will happen:</Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture12.webp`)}
                  onClick={() => onImageClick("trade/Picture12")}
                  alt={i18("Trade action reverse button result", "help.trade.tradingAssets.picture12.alt")}
                  width={784}
                  height={537}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.20"}>
                    Where ATOM is now on top and the amount can be edited.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.21"}>
                    There is further important information on this page to look at. Let us take a look at the bottom.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture13.webp`)}
                  onClick={() => onImageClick("trade/Picture13")}
                  alt={i18("Trade action bottom information", "help.trade.tradingAssets.picture13.alt")}
                  width={783}
                  height={1010}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.22"}>
                    The &quot;Exchange rate&quot; is self-explanatory. It shows how much 1 ATOM is worth in ARCH, and
                    the arrows to the right will swap the tokens to show how much 1 ARCH is worth in ATOM.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.23"}>
                    The &quot;Minimum received&quot; will show the minimum amount you will get given your slippage
                    tolerance.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.24"}>
                    &quot;Price impact&quot; will show what percent of the pool will be impacted by the trade. Very
                    large orders will impact the pool greatly and will require a higher slippage tolerance. Most trades
                    will have very little effect on the pool. Each pool has its own amount of liquidity, so this will
                    vary greatly.
                  </Trans>
                </p>

                {/*<p>*/}
                {/*  <Trans i18nKey={"help.trade.tradingAssets.text.25"}>*/}
                {/*    This example is a pre-release version of Astrovault, and it demonstrates that high degrees of*/}
                {/*    &quot;Price impact&quot; are highlighted in <span className="textRed">red</span>.*/}
                {/*  </Trans>*/}
                {/*</p>*/}

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.26"}>
                    &quot;Fees&quot; show how much is charged for the transaction.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.27"}>
                    Gravitate{" "}
                    <span className="iconWrapper">
                      (
                      <img
                        src={LogoGravitate}
                        alt={i18("GRVT8 - Gravitate Icon", "grvt8.icon.alt")}
                        width={10}
                        height={14}
                        loading="lazy"
                        className="isIcon GRVT8"
                      />
                      &nbsp;GRVT8)
                    </span>{" "}
                    rewards are received for trading. That&#8217;s right, Astrovault gives you rewards for trading on
                    the platform.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.28"}>
                    Finally, the big button on the bottom is the Trade/Mint button. Click that and watch the magic of
                    Astrovault happen.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.29"}>
                    After clicking the button, your wallet will open a popup asking you to confirm the trade. It will
                    look like this:
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture14.webp`)}
                  onClick={() => onImageClick("trade/Picture14")}
                  alt={i18("Trade Wallet Confirm Transaction", "help.trade.tradingAssets.picture14.alt")}
                  width={343}
                  height={652}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.30"}>
                    This will display the smart contract on Archway that you are interacting with. It will also show
                    your transaction cost or “gas” fee. Gas is the amount you pay the network to settle a transaction.
                    For Archway this is paid in the ARCH token. Make sure you have ARCH in your wallet to pay for this
                    fee so your trade will be successful.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.31"}>
                    After you approve the transaction Astrovault will notify you of the results of the transaction,
                    similar to the following image.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture15.webp`)}
                  onClick={() => onImageClick("trade/Picture15")}
                  alt={i18("Trade Transaction Confirmation Success", "help.trade.tradingAssets.picture15.alt")}
                  width={457}
                  height={348}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.32"}>
                    You can dismiss this or click the &quot;Link&quot; button to view the transaction in an explorer.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.33.cancel"}>
                    If the transaction is cancelled you will be shown this:
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture16-cancelled.webp`)}
                  onClick={() => onImageClick("trade/Picture16.cancelled")}
                  alt={i18("Trade Transaction Confirmation Failed", "help.trade.tradingAssets.picture16.alt")}
                  width={420}
                  height={643}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.33"}>
                    If the transaction does not go through you will be shown this:
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture16.webp`)}
                  onClick={() => onImageClick("trade/Picture16")}
                  alt={i18("Trade Transaction Confirmation Failed", "help.trade.tradingAssets.picture16.alt")}
                  width={420}
                  height={643}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey={"help.trade.tradingAssets.text.34"}>
                    This can happen for a variety of reasons. One reason may be that your slippage tolerance is not high
                    enough, or there could be a sudden change in gas fees. Feel free to try the transaction again, it
                    may go through.
                  </Trans>
                </p>
              </HelpSubPageSection>

              <HelpSubPageSection
                extraClassName="withGradientBorderTop"
                sectionId="mintingAssets"
                sectionTitle={i18("Minting X assets", "help.trade.subpage.section.mintingAssets.title")}
              >
                {/*
                "help.trade.miningAssets.text.1": "One of the unique and powerful features of Astrovault is its ability to mint derivative assets. As showcased below, these assets are designated with a lower-case x in front of their name and a blue colour compared to the base asset.",
                "help.trade.miningAssets.text.2": "Tip; see how two letters &quot;co&quot; are entered to show only those tokens in the list.",
                "help.trade.miningAssets.text.3": "Minting derivatives is done at the Trade view just like an asset exchange.",
                "help.trade.miningAssets.text.4": "Note that the &quot;Exchange rate&quot; is 1:1 for the main asset and its derivative.",
                "help.trade.miningAssets.text.5": "The &quot;Minimum received&quot; will be equal to 100% of the main asset.",
                "help.trade.miningAssets.text.6": "There is no &quot;Price impact&quot; as this asset will be generated (minted).",
                "help.trade.miningAssets.text.7": "The &quot;Trade&quot; button will be a &quot;Mint&quot; button.",
                "help.trade.miningAssets.text.8": "Congratulations! With this knowledge you are now the master of your own assets and can exchange them using the cutting-edge technology of Astrovault.",

                */}
                <p>
                  <Trans i18nKey={"help.trade.mintingAssets.text.1"}>
                    One of the unique and powerful features of Astrovault is its ability to mint derivative assets. As
                    showcased below, these assets are designated with a lower-case x in front of their name and a blue
                    colour compared to the base asset.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture17.webp`)}
                  onClick={() => onImageClick("trade/Picture17")}
                  alt={i18("Trade Minting X assets", "help.trade.tradingAssets.picture17.alt")}
                  width={189}
                  height={215}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />
                <p>
                  <Trans i18nKey={"help.trade.mintingAssets.text.2"}>
                    Tip; see how two letters &quot;co&quot; are entered to show only those tokens in the list.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.mintingAssets.text.3"}>
                    Minting derivatives may be enabled at the Trade view just like an asset exchange.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/trade/Picture18.webp`)}
                  onClick={() => onImageClick("trade/Picture18")}
                  alt={i18("Trade Minting Actions", "help.trade.tradingAssets.picture18.alt")}
                  width={780}
                  height={618}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.trade.mintingAssets.text.4"}>
                    Note that the &quot;Exchange rate&quot; is 1:1 for the main asset and its derivative.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.mintingAssets.text.5"}>
                    The &quot;Minimum received&quot; will be equal to 100% of the main asset.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.mintingAssets.text.6"}>
                    There is no &quot;Price impact&quot; as this asset will be generated (minted).
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.mintingAssets.text.7"}>
                    The &quot;Trade&quot; button will be a &quot;Mint&quot; button.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.trade.mintingAssets.text.8"}>
                    Congratulations! With this knowledge you are now the master of your own assets and can exchange them
                    using the cutting-edge technology of Astrovault.
                  </Trans>
                </p>
              </HelpSubPageSection>
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        index={index}
        slides={slides}
        open={index >= 0}
        close={() => setIndex(-1)}
        plugins={[Captions, Thumbnails]}
        className={styles.lightbox}
      />
    </MainLayout>
  );
}

export default HelpTrade;
