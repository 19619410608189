import TradeForm from "components/forms/TradeForm";

export default function TradeContainer({
  feeGrantAssetsToTrade,
  setTitle,
  tradeInfo,
  setTradeInfo,
  isTransactionStarts,
  setIsTransactionStarts,
}: any) {
  return (
    <>
      <TradeForm
        feeGrantAssetsToTrade={feeGrantAssetsToTrade}
        setTitle={setTitle}
        tradeInfo={tradeInfo}
        setTradeInfo={setTradeInfo}
        isTransactionStarts={isTransactionStarts}
        setIsTransactionStarts={setIsTransactionStarts}
      />
    </>
  );
}
