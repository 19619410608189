import { useState } from "react";
import { Circle, XCircle } from "react-feather";
import useLanguage from "@axvdex/hooks/useLanguage";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import { selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { fetchWrapper } from "@axvdex/api";
import { handleAuthentication } from "@axvdex/state/wallet/walletThunks";
import { hasPermitStored } from "@axvdex/utils/localStorage";
import { ReactComponent as IcnTwitter } from "../../assets/icons/icn-twitterx.svg";
import CustomInputButton from "../form-element/CustomInputButton";
import CustomModal from "../common/CustomModal";
import Button from "../common/Button";
import CustomLoader from "../common/CustomLoader";

function PollModal({ pollModalOpen, setPollModalOpen }: any) {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const walletInfo = useAppSelector(selectWalletInfo);
  const [loading, setLoading] = useState(false);

  const [selectedChoises0, setSelectedChoises0] = useState(null);
  const [selectedChoises1, setSelectedChoises1] = useState(null);

  return (
    <>
      <CustomModal isOpen={pollModalOpen} onClose={() => setPollModalOpen(false)}>
        <section className="sectionModalHeader">
          <h2 className="h2">{"Airdrop Survey"}</h2>
        </section>
        <div style={{ fontSize: "1em", marginBottom: "1.5em" }}>
          <span>To help us improve, please answer the following...</span>
        </div>
        <div style={{ fontSize: "1em", marginBottom: "1em", marginLeft: "1em" }}>
          <span>1. I use a Ledger device with Astrovault</span>
          {["Yes", "No"].map((option: string) => (
            <CustomInputButton
              key={option}
              type="radio"
              id={option}
              name="assets_sort"
              labelText={option}
              labelIcon={selectedChoises0 === option ? <XCircle /> : <Circle />}
              onClick={() => {
                setSelectedChoises0(option);
              }}
            />
          ))}
          <br />
          <span>2. I use Astrovault on</span>
          {["Mobile", "Desktop", "Both"].map((option: string) => (
            <CustomInputButton
              key={option}
              type="radio"
              id={option}
              name="assets_sort"
              labelText={option}
              labelIcon={selectedChoises1 === option ? <XCircle /> : <Circle />}
              onClick={() => {
                setSelectedChoises1(option);
              }}
            />
          ))}
          <hr />
          <p>
            <small>These answers do not affect Airdrop eligibility</small>
          </p>
        </div>
        <div className="buttonContainer" style={{ justifyContent: "right" }}>
          {loading ? (
            <CustomLoader size="xs" />
          ) : (
            hasPermitStored(walletInfo) && (
              <Button
                btnColor="gradient"
                text={"Submit"}
                title={"Submit"}
                disabled={!selectedChoises0 || !selectedChoises1}
                onClick={async () => {
                  setLoading(true);
                  await fetchWrapper(
                    "POST",
                    "/user/survey",
                    { surveyID: 0, options: [selectedChoises0, selectedChoises1] },
                    {},
                    {
                      pubkey: walletInfo.pubKey,
                      signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + walletInfo.pubKey],
                    }
                  );
                  await dispatch(handleAuthentication());
                  setLoading(false);
                  setPollModalOpen(false);
                }}
              />
            )
          )}
        </div>
      </CustomModal>
    </>
  );
}

export default PollModal;
