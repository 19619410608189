import { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { useReactTable, getCoreRowModel, flexRender, ColumnDef } from "@tanstack/react-table";
import BigNumber from "bignumber.js";
import { AlertTriangle } from "react-feather";
import { formatBalance, fromHumanAmountToTokenBalance, humanizeAmount } from "@axvdex/utils/formatNumber";
import unstakeSimulation from "@axvdex/utils/unstakeSimulation";
import {
  selectAssetBalances,
  selectAssets,
  selectGlobalConfig,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import {
  executePoolAction,
  // increasePoolAllowance,
  updateLpStakingBalance,
  updateNativeBalance,
  updateTokenBalance,
} from "@axvdex/state/wallet/walletThunks";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import Button from "@axvdex/components/common/Button";
import CustomSwitch from "@axvdex/components/form-element/CustomSwitch";
import imgSanitize from "@axvdex/utils/imgSanitize";
import estimatedFees, { GAS_ESTIMATION_STATIC_STABLE_POOL_WITHDRAWAL } from "@axvdex/utils/estimatedFees";
import useLanguage from "@axvdex/hooks/useLanguage";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import CustomNumericInput from "../form-element/CustomNumericInput";

import CustomLoader from "../common/CustomLoader";

interface PoolCardStableWithdrawalTableProps {
  id: string;
  symbol: string;
  image: string;
  total: number;
  allowanceLoading: boolean;
  disabled_withdrawal_assets: Array<number>;
  deposited: string;
  allowance?: {
    allowance: string;
    expires: any;
  } | null;
  input: string;
  balance: number | null;
}

interface ComponentProps {
  poolData: any;
  [key: string]: any;
}

function PoolCardStableWithdrawalTable({ poolData }: ComponentProps) {
  // these values aim to stop users from doing silly things to themselves
  const mediumFeeConfirm = 5;
  const highFeeConfirm = 15;

  const { i18 } = useLanguage();
  const assetBalances = useAppSelector(selectAssetBalances);
  const assets = useAppSelector(selectAssets);
  const globalConfig = useAppSelector(selectGlobalConfig);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<PoolCardStableWithdrawalTableProps[]>([]);
  const [simulationValues, setSimulationValues] = useState<any>(null);
  const [isTransactionStarts, setIsTransactionStarts] = useState(false);
  const [deposited, setDeposited] = useState<string | null>(null);
  const [isInstantWithDrawal, setIsInstantWithDrawal] = useState(false);
  const [largeFeeComponent, setLargeFeeComponent] = useState({
    visible: false,
    activated: false,
  });
  const dispatch = useAppDispatch();
  const walletInfo = useAppSelector(selectWalletInfo);

  const walletChainContext = walletInfo?.connectedChains[poolData.contextChainId];

  useEffect(() => {
    const update = async () => {
      // if there is no wallet connect and auto connect is enabled, we should show the loader as we are trying to auto connect
      if (!walletInfo.isConnected && loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) {
        setIsLoading(true);
        return;
      }
      if (data.length === 0) setIsLoading(true);
      // get asset balances of this pool that are not already in state
      const requests = [];
      for (const [i, poolAsset] of poolData.poolAssets.entries()) {
        if (!assetBalances[poolData.assetIDs[i]] && walletInfo.isConnected) {
          if (poolAsset.info.token) {
            requests.push(
              dispatch(
                updateTokenBalance({
                  client: walletChainContext.signingClient,
                  userAddress: walletChainContext.address,
                  tokenAddress: poolAsset.info.token.contract_addr,
                })
              )
            );
          } else {
            requests.push(
              dispatch(
                updateNativeBalance({
                  client: walletChainContext.signingClient,
                  userAddress: walletChainContext.address,
                  denom: poolAsset.info.native_token.denom,
                })
              )
            );
          }
        } else {
          requests.push(Promise.resolve());
        }
      }

      const poolAssetBalances = await Promise.all(requests);

      // get balance of this user on this pool
      let lpStakingBalance = assetBalances[poolData?.lp_staking];
      if (!assetBalances[poolData?.lp_staking] && walletInfo.isConnected) {
        lpStakingBalance = (
          await dispatch(
            updateLpStakingBalance({
              client: walletChainContext.signingClient,
              userAddress: walletChainContext.address,
              lpStakingAddress: poolData?.lp_staking,
            })
          )
        ).payload;
      }

      setDeposited(lpStakingBalance);

      // get allowances of this user for tokens on this pool
      const allowanceResponses = await Promise.all([
        //across all tokens on this pool, check if the allowance was already provided
        ...poolData.poolAssets.reduce((acc, asset) => {
          if (asset.info.token && walletInfo.isConnected) {
            const allowanceResponse = rpcClientQuerySmartContractWrapper(
              walletChainContext.signingClient,
              asset.info.token.contract_addr,
              {
                allowance: {
                  owner: walletChainContext.address,
                  spender: poolData.address,
                },
              }
            );

            acc = [...acc, allowanceResponse];
            return acc;
          }

          return [...acc, null];
        }, []),
      ]);

      const pPoolData = poolData.poolAssets.map((asset, i) => {
        return {
          id: poolData?.assetIDs[i],
          total: humanizeAmount(asset.amount, assets[poolData?.assetIDs[i]]?.decimals),
          symbol: assets[poolData?.assetIDs[i]]?.symbol,
          input: data && data[i] ? data[i].input : "",
          allowance: allowanceResponses[i],
          deposited: lpStakingBalance,
          disabled_withdrawal_assets: poolData.disabled_actions
            ? poolData.disabled_actions.withdrawal_disabled_for_indexes
            : [],
          balance: poolAssetBalances[i]
            ? formatBalance(
                poolAssetBalances[i].payload,
                assets[poolData?.assetIDs[i]].price,
                assets[poolData?.assetIDs[i]].decimals
              ).amount || 0
            : formatBalance(
                assetBalances[poolData?.assetIDs[i]],
                assets[poolData?.assetIDs[i]].price,
                assets[poolData?.assetIDs[i]].decimals
              ).amount || 0,
        };
      });
      setData(pPoolData);
      setIsLoading(false);
    };
    update();
  }, [poolData, walletInfo.isConnected]);

  useEffect(() => {
    setData(prevData => {
      const newData = [...prevData];
      newData.map((row, i) => {
        row.balance = formatBalance(
          assetBalances[poolData?.assetIDs[i]],
          assets[poolData?.assetIDs[i]].price,
          assets[poolData?.assetIDs[i]].decimals
        ).amount;
        return row;
      });
      return newData;
    });

    if (assetBalances[poolData?.lp_staking] !== undefined) {
      setDeposited(assetBalances[poolData?.lp_staking]);
    }
  }, [assetBalances]);

  const handleStablePoolInputChange = (amount, index) => {
    setData(prevData => {
      const newData = [...prevData];
      const amounts = prevData.map(row => {
        if (row.input === "" || !row.input) return (row.input = "0");
        return row.input.replaceAll(",", "");
      });
      if (index !== null) {
        amounts[index] = amount.replaceAll(",", "");
      }
      const sum = amounts.reduce((partialSum, a) => partialSum + Number(a), 0);
      if (sum > Number(assetBalances[poolData?.lp_staking])) {
        return newData.map((row, index) => {
          return { ...row, input: amounts[index] };
        });
      }

      const transformData = executeSimulation(amounts);

      if (!transformData) {
        return newData.map((row, _) => {
          return { ...row, input: "" };
        });
      }

      setSimulationValues(transformData);
      return newData.map((row, index) => {
        return { ...row, input: amounts[index] };
      });
    });
    setLargeFeeComponent({ visible: false, activated: false });
  };

  const executeSimulation = amounts => {
    const transformData = unstakeSimulation(
      poolData,
      "", // lpToken amount not used on the simulation for stable pools
      {
        amounts,
        is_instant_withdrawal: isInstantWithDrawal,
      },
      null,
      walletChainContext.address,
      assetBalances[poolData?.lp_staking]
    );

    setSimulationValues(transformData);
    return transformData;
  };

  useEffect(() => {
    executeSimulation(
      data.map(row => {
        if (row.input === "" || !row.input) return (row.input = "");
        return row.input.replaceAll(",", "");
      })
    );
  }, [isInstantWithDrawal]);

  // const handleIncreaseAllowance = async (tokenAddress, index) => {
  //   setData(prevData => {
  //     const newData = [...prevData];
  //     newData[index].allowanceLoading = true;
  //     return newData;
  //   });
  //
  //   const res = await dispatch(
  //     increasePoolAllowance({
  //       walletAddress: walletChainContext.address,
  //       poolData,
  //       tokenAddress,
  //       i18,
  //     })
  //   );
  //
  //   setData(prevData => {
  //     const newData = [...prevData];
  //     newData[index].allowanceLoading = false;
  //     newData[index].allowance = res.payload as { allowance: string; expires: any } | null;
  //     return newData;
  //   });
  // };

  const handleSubmit = async () => {
    if (
      !largeFeeComponent.visible &&
      simulationValues.withdrawalFeePer.find(per => parseFloat(per) >= highFeeConfirm)
    ) {
      setLargeFeeComponent({ visible: true, activated: false });
      return;
    }

    setIsTransactionStarts(true);

    await dispatch(
      executePoolAction({
        poolData,
        simulationValues,
        isDeposit: false,
        i18,
      })
    );

    poolData.poolAssets.forEach((_, i) => {
      handleStablePoolInputChange("0", i);
    });

    setIsTransactionStarts(false);
    setLargeFeeComponent({ visible: false, activated: false });
  };

  const defaultColumns = useMemo<ColumnDef<PoolCardStableWithdrawalTableProps>[]>(() => {
    return [
      {
        id: "token",
        header: () => <span>{i18("Token Balance", "poolCardTable.table.columns.token")}</span>,
        cell: ({ row }) => (
          <div className="flexbox">
            <span className="tokenIcon" title={row.original.symbol}>
              <img
                src={imgSanitize(row.original.symbol)}
                alt={row.original.symbol}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(
                    updateTokenBalance({
                      client: walletChainContext.signingClient,
                      userAddress: walletChainContext.address,
                      tokenAddress: row.original.id,
                    })
                  )
                }
              />
            </span>
            {row.original.balance !== null && <span>{row.original.balance}</span>}
          </div>
        ),
      },
      {
        id: "actions",
        header: () => <span className="visuallyHidden">{i18("Actions", "poolCardTable.table.columns.actions")}</span>,
        cell: ({ row }) => {
          return (
            <>
              {
                // remove approve on withdrawal as it is not mandatory (keep comment code as it can be needed in the future)
                //row.original.allowance?.allowance !== "0" || assets[row.original.id].isNative ? (
                <>
                  {!row.original.disabled_withdrawal_assets.includes(row.index) && (
                    <>
                      <div className="formGroupInline">
                        {row.original.balance !== null && (
                          <Button
                            text={i18("Max", "poolCardTable.maxBtn.text")}
                            title={i18("Set Max Value", "poolCardTable.maxBtn.title")}
                            btnColor="gradientText"
                            onClick={() => {
                              setData(prevData => {
                                const newData = [...prevData];
                                for (let i = 0; i < newData.length; i++) {
                                  if (i !== row.index) {
                                    newData[i].input = "";
                                  }
                                }
                                return newData;
                              });
                              if (row.original.deposited) {
                                handleStablePoolInputChange(
                                  BigNumber(row.original.deposited).div(Math.pow(10, 6)).toString(10),
                                  row.index
                                );
                              }
                            }}
                          />
                        )}
                        <CustomNumericInput
                          extraClassName="derivativeFromUsdFormGroup"
                          name={`amount_${row.index}`}
                          labelText={i18("Enter amount", "poolCardTable.input.label")}
                          hiddenLabel={true}
                          decimalScale={6}
                          placeholder={"0"}
                          value={row.original.input}
                          onChange={e => {
                            handleStablePoolInputChange(e.target.value, row.index);
                          }}
                        />
                      </div>
                      <div className="tableInputValueConversion withMarginTop">
                        ($
                        {
                          formatBalance(
                            fromHumanAmountToTokenBalance(
                              row.original.input?.replaceAll(",", ""),
                              assets[row.original.id].decimals
                            ) || "0",
                            assets[row.original.id].price,
                            assets[row.original.id].decimals
                          ).usdConversion
                        }
                        )
                      </div>
                    </>
                  )}
                </>
                // ) : (
                //   <>
                //     {!row.original.allowanceLoading ? (
                //       <Button
                //         text={i18(`Approve ${assets[row.original.id].symbol}`, "poolCardTable.approveBtn.text", {
                //           symbol: assets[row.original.id].symbol,
                //         })}
                //         title={i18(`Approve set ${assets[row.original.id].symbol}`, "poolCardTable.approveBtn.title", {
                //           symbol: assets[row.original.id].symbol,
                //         })}
                //         btnColor="gradientText"
                //         onClick={() => handleIncreaseAllowance(assets[row.original.id].address, row.index)}
                //       />
                //     ) : (
                //       <CustomLoader text={i18("Processing...", "poolCardTable.loader.text")} size="xs" />
                //     )}
                //   </>
                // )
              }
            </>
          );
        },
      },
    ];
  }, [i18, isInstantWithDrawal]);

  const [columns, setColumns] = useState(() => [...defaultColumns]);

  // useEffect(() => {
  //   setData(DummyPoolCardDetailsTableList);
  // }, [defaultColumns]);

  useEffect(() => {
    setColumns([...defaultColumns]);
  }, [defaultColumns]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const shouldDisableButton = amountWithinInput => {
    let totalAmount = BigNumber(0);
    amountWithinInput.forEach(amount => {
      totalAmount = totalAmount.plus(BigNumber(amount));
    });

    return totalAmount.times(Math.pow(10, 6)).gt(assetBalances[poolData?.lp_staking]);
  };

  const getDepositedBalanceDollarPrice = () => {
    let price = 0;
    poolData.assetIDs.forEach(assetID => {
      price += assets[assetID].price;
    });

    return price / poolData.assetIDs.length;
  };

  const submitButtonText = () => {
    const disabledConfig = { disabled: true, extraClass: "" };
    const action = i18("Withdrawal", "poolCardTable.submitBtn.text.withdrawal");

    if (!walletInfo.connectedChains[poolData.contextChainId]) {
      return {
        buttonText: i18("Not Connected", "poolCardTable.submintBtn.text.notConnected"),
        ...disabledConfig,
      };
    }

    if (data.every(value => !value.input || !Number(value.input))) {
      return {
        buttonText: i18("Enter Amount", "poolCardTable.submitBtn.text.enterAmount"),
        ...disabledConfig,
      };
    }

    if (!poolData?.owner_settings?.is_withdrawal_to_lockup_enabled) {
      return {
        buttonText: i18("Withdrawal disabled", "poolCardTable.submitBtn.text.disabled", { action }),
        ...disabledConfig,
      };
    }

    if (isTransactionStarts) {
      return {
        buttonText: i18("Executing...", "poolCardTable.submitBtn.text.executing"),
        ...disabledConfig,
      };
    }

    if (shouldDisableButton(data.map(row => row.input))) {
      return {
        buttonText: i18("Not enough deposited amount", "poolCardTable.submitBtn.text.notEnoughDeposited"),
        ...disabledConfig,
      };
    }

    // DISABLED THIS CHECK BECAUSE OF NEUTRON HAVING MULTIPLE GAS TOKENS
    // // check if user has sufficient gas fees to cover this
    // const calcEstimatedFees = estimatedFees(
    //   GAS_ESTIMATION_STATIC_STABLE_POOL_WITHDRAWAL,
    //   globalConfig.statusCounters[poolData.contextChainId].estimatedFeesReference
    // );
    // const feeDenom = globalConfig.statusCounters[poolData.contextChainId].estimatedFeesReference.estimatedFee[0].denom;
    // if (assetBalances[feeDenom] && BigNumber(assetBalances[feeDenom]).lt(calcEstimatedFees)) {
    //   return {
    //     buttonText: i18(
    //       `Wallet needs ${assets[feeDenom].symbol} for ${action}`,
    //       "poolCardTable.submitBtn.text.walletNoFunds",
    //       {
    //         action,
    //         symbol: assets[feeDenom].symbol,
    //       }
    //     ),
    //     ...disabledConfig,
    //   };
    // }

    if (simulationValues && simulationValues.withdrawalFeePer.find(feePer => feePer === "100")) {
      return {
        buttonText: i18("Fees at 100%", "poolCardTable.submit.text.fees100percent"),
        ...disabledConfig,
      };
    }

    if (largeFeeComponent.visible) {
      if (!largeFeeComponent.activated) {
        return {
          buttonText: i18(`Confirm HIGH FEE ${action}`, "poolCardTable.submitBtn.text.highFeeConfirm", { action }),
          ...disabledConfig,
        };
      }

      return {
        buttonText: i18(`Yes, make this HIGH FEE ${action}`, "poolCardTable.submitBtn.text.highFeeAgree", { action }),
        disabled: false,
        extraClass: "highFeeButton",
      };
    }

    return {
      buttonText: action,
      disabled: false,
      extraClass: "",
    };
  };

  const buttonConfig = submitButtonText();
  return (
    <section className={clsx("poolCardModalSectionTable withGradientBorderBottom")}>
      {isLoading ? (
        <div style={{ textAlign: "center", marginBottom: "1.5em" }}>
          <CustomLoader size="xs" />
        </div>
      ) : (
        <table
          className={clsx(
            "table",
            "withGradientBorderRows",
            "poolCardModalDetailsTable",
            "isResponsive",
            "col-2",
            "stableWithdraw"
          )}
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr>
              <td colSpan={2}>
                {deposited && (
                  <div className="tableTFootFlexbox">
                    <small>{i18("Deposited", "poolCardTable.withdrawTable.deposited")}:</small>
                    <span>
                      {formatBalance(deposited, getDepositedBalanceDollarPrice(), 6).amount}
                      <small>
                        &nbsp;(${formatBalance(deposited, getDepositedBalanceDollarPrice(), 6).usdConversion})
                      </small>
                    </span>
                  </div>
                )}
                <CustomSwitch
                  extraClassName="StableWithdrawalCustomSwitch withGradientBorderBottom"
                  name="instant_withdrawal"
                  labelText={
                    <>
                      <p>{i18("Instant Withdraw", "poolCardTable.withdrawTable.instantWithdraw")}</p>
                      <small className="textGrey">
                        {i18(
                          "Additional charges are shown when selected",
                          "poolCardTable.withdrawTable.instantWithdraw.info"
                        )}
                      </small>
                    </>
                  }
                  isToggled={isInstantWithDrawal}
                  onToggle={e => setIsInstantWithDrawal(e.target.checked)}
                />

                {simulationValues && simulationValues.withdrawalFeePer.length > 0 && (
                  <div className="tableTFootFlexbox flexStart">
                    <small>Withdrawal Fee Percentage</small>
                    <span className="tableTFootColumn">
                      {simulationValues.withdrawalFeePer.map((fee, index) => (
                        <small key={index}>
                          <div
                            className={clsx(
                              "inlineFlexbox",
                              "withdrawalFees",
                              Number(fee) > mediumFeeConfirm && "bold",
                              Number(fee) >= highFeeConfirm && "red"
                            )}
                          >
                            {Number(fee) >= highFeeConfirm && (
                              <span className="tradeListValuePreIcon">
                                <AlertTriangle />
                              </span>
                            )}{" "}
                            {fee}% {assets[poolData.assetIDs[index]].symbol}
                          </div>
                        </small>
                      ))}
                    </span>
                  </div>
                )}

                {/* Commented out as this text considered confusing */}
                {/*{simulationValues && simulationValues.withdrawalFeePer.length > 0 && (*/}
                {/*  <p className="tableTFootNote">*/}
                {/*    <span>{i18("NOTE: Withdrawal is free < 0.25%", "poolCardTable.withdrawTable.footNote.note")}</span>*/}
                {/*  </p>*/}
                {/*)}*/}

                {simulationValues && isInstantWithDrawal && (
                  <div className="tableTFootFlexbox">
                    <small>
                      {i18("Lockup Fee Percentage", "poolCardTable.withdrawTable.footNote.lockupFeePercentage")}
                    </small>
                    <small>{simulationValues.lockupFeePer} %</small>
                  </div>
                )}

                {simulationValues && isInstantWithDrawal && simulationValues.assets_lockup_fee_amount.length !== 0 && (
                  <div className="tableTFootFlexbox flexStart withBorderBottom">
                    <small>
                      {i18("Assets Lockup Fee Amount", "poolCardTable.withdrawTable.footNote.assetsLockupFee")}
                    </small>
                    <span className="tableTFootColumn">
                      {simulationValues.assets_lockup_fee_amount.map((fee, index) => (
                        <small key={index}>
                          {fee} {assets[poolData.assetIDs[index]].symbol}
                        </small>
                      ))}
                    </span>
                  </div>
                )}

                {simulationValues && simulationValues.final_withdrawal_assets_amount_lockup.length !== 0 && (
                  <div className="tableTFootFlexbox flexStart">
                    <small>{i18("Final Amount", "poolCardTable.withdrawTable.footNote.finalAmount")}</small>
                    <span className="tableTFootColumn">
                      {simulationValues.final_withdrawal_assets_amount_lockup.map((fee, index) => (
                        <span key={index}>
                          {fee} {assets[poolData.assetIDs[index]].symbol}
                        </span>
                      ))}
                    </span>
                  </div>
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      )}

      {!isTransactionStarts ? (
        <>
          <Button
            text={buttonConfig.buttonText}
            title={i18("Submit", "poolCardTable.submitBtn.title")}
            btnColor="gradient"
            isFullWidth={true}
            onClick={handleSubmit}
            disabled={buttonConfig.disabled}
            extraClassName={buttonConfig.extraClass}
          />
          {largeFeeComponent.visible && (
            <section className="highFee withGradientBorder">
              <CustomSwitch
                name="feeConfirm"
                labelText={
                  <>
                    {simulationValues.withdrawalFeePer
                      .filter(fee => Number(fee) >= highFeeConfirm)
                      .map((fee, index) => (
                        <div key={index} className={clsx("highFeeText", "inlineFlexbox")}>
                          <small>
                            {i18(`Warning HIGH fees: ${fee}%`, "poolCardTable.withdrawTable.highFee", {
                              fee: fee,
                            })}{" "}
                            {assets[poolData.assetIDs[index]].symbol}
                          </small>
                        </div>
                      ))}

                    <div>
                      <small className="textGrey">
                        {i18(
                          "Do you REALLY want to make this HIGH FEE withdrawal?",
                          "poolCardTable.withdrawTable.highFeeConfirm"
                        )}
                      </small>
                    </div>
                  </>
                }
                isToggled={largeFeeComponent.activated}
                onToggle={e => setLargeFeeComponent({ activated: e.target.checked, visible: true })}
              />
            </section>
          )}
          {simulationValues &&
            assetBalances &&
            poolData &&
            simulationValues.tx.withdrawal.amount === assetBalances[poolData?.lp_staking] && (
              <>
                <div className="flexbox helpText">
                  <span className="warning tokenIcon">
                    <AlertTriangle />
                  </span>
                  <span>
                    {i18(
                      `Withdrawing the full amount from a pool will automatically claim it's accumulated rewards`,
                      "poolCardTable.withdrawTable.withdrawFullAmount"
                    )}
                  </span>
                </div>
              </>
            )}
        </>
      ) : (
        <CustomLoader text={i18("Processing...", "poolCardTable.loader.text")} size="xs" />
      )}
    </section>
  );
}

export default PoolCardStableWithdrawalTable;
