import { createAsyncThunk } from "@reduxjs/toolkit";
import { postPoolFavourite } from "@axvdex/api/user";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import { AsyncThunkConfig } from "..";
import { addFavouritePool, deleteFavouritePool } from "../wallet/walletSlice";

export const triggerFavouriteChange = createAsyncThunk<
  void,
  { isFavourite: boolean; poolAddress: string },
  AsyncThunkConfig
>("pool/triggerFavoriteChange", async ({ isFavourite, poolAddress }, { dispatch, getState }) => {
  const { pubKey } = getState().wallet.walletInfo;
  const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
  if (permits["cosmos_" + pubKey]) {
    const method = isFavourite ? "POST" : "DELETE";

    try {
      await postPoolFavourite(
        method,
        { favoriteAddress: poolAddress },
        {
          pubkey: pubKey,
          signature: permits["cosmos_" + pubKey],
        }
      );

      // snackbar.success(i18("header.saveStarred", "Success saving starred"));

      if (isFavourite) {
        dispatch(addFavouritePool({ favoritePool: poolAddress }));
      } else {
        dispatch(deleteFavouritePool({ favoritePool: poolAddress }));
      }
    } catch (err) {
      console.error(err);
      // snackbar.error(i18("header.errorStarred", "Starred not saved. Please try later"));
    }
  }
});
