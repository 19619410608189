/* eslint-disable @typescript-eslint/no-var-requires */
import clsx from "clsx";
import { Trans } from "react-i18next";
// import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import HelpSubPageSidebar from "@axvdex/components/HelpSubPageSidebar";
import HelpSubPageSection from "@axvdex/components/HelpSubPageSection";
import { selectAssets, selectChains, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { useAppSelector } from "@axvdex/state";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import imgSanitize from "@axvdex/utils/imgSanitize";
import useLanguage from "@axvdex/hooks/useLanguage";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/Help.module.scss";

function HelpTokens() {
  const { i18 } = useLanguage();
  const assets = useAppSelector(selectAssets);
  const chains = useAppSelector(selectChains);
  const walletInfo = useAppSelector(selectWalletInfo);
  const [walletKind, setWalletKind] = useState(``);
  const [maybeWalletName, setMaybeWalletName] = useState(``);
  const [walletName, setWalletName] = useState(``);

  const getWallet = () => {
    const walletKind = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
    setWalletKind(walletKind);
    const walletName = walletKind.charAt(0).toUpperCase() + walletKind.slice(1);
    setWalletName(walletName);
    setMaybeWalletName(walletName || `wallet`);
  };

  const sectionSummary = [
    {
      name: i18(`Add tokens to ${walletName} wallet`, "help.tokens.sectionSummary.addTokensToWallet.name", {
        walletName,
      }),
      url: "#addTokensToWallet",
    },
    {
      name: i18(`How to identify layer 2 tokens`, "help.tokens.sectionSummary.aboutLayer2Tokens.name"),
      url: "#aboutLayer2Tokens",
    },
  ];

  useEffect(() => {
    getWallet();
  }, [walletInfo]);

  return (
    <MainLayout
      pageClass={clsx(styles.help, "help", "helpTokens")}
      headTitle={i18("Help :: Tokens", "help.tokens.headTitle")}
      headDescription={i18("Help for tokens on Astrovault", "help.tokens.headDescription")}
    >
      <div className="helpPageWrapper">
        <div className="sectionWrapperLg">
          <section className="helpSectionHeader withGradientBorderBottom">
            <h1>{i18("Tokens", "help.tokens.section.title")}</h1>
            <p>
              {"" === walletKind
                ? i18(`Use "Connect Wallet" to add and view tokens in your wallet`, "help.tokens.section.text.0")
                : i18(`Choose any token to add and view in your ${walletName} wallet`, "help.tokens.section.text.1", {
                    walletName,
                  })}
            </p>
          </section>

          <div className="helpSubPageGrid">
            <HelpSubPageSidebar
              summaryTitle={i18("Tokens", "help.tokens.sidebar.title")}
              items={sectionSummary}
              hardBack
            />

            <div className="helpSubPageContent">
              <HelpSubPageSection
                sectionId="addTokensToWallet"
                sectionTitle={i18(
                  `Add to ${maybeWalletName} view`,
                  "help.tokens.subpage.section.addTokensToWallet.title",
                  {
                    maybeWalletName,
                  }
                )}
              >
                <Trans>
                  <span className="tokenGrid">
                    {Object.values(assets)
                      .filter(asset => asset.address)
                      .sort((a, b) => a.symbol.localeCompare(b.symbol))
                      .map(asset => (
                        <div
                          className="flexbox addToWalletHelpTokenItem"
                          key={asset.id}
                          title={i18(`Manually Add ${asset.symbol} token`, "help.tokens.addTokensToWallet.btn.title", {
                            tokenSymbol: asset.symbol,
                          })}
                          onClick={async () => {
                            const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
                            if (!window[walletExtension]) {
                              // throw new Error("Please install " + walletExtension + " extension");
                              console.log(
                                i18(
                                  'Use "Connect Wallet" before trying to add tokens to wallet view',
                                  "help.tokens.addTokensToWallet.btn.action.log"
                                )
                              );
                              return;
                            }
                            const walletExtensionClient =
                              "cosmostation" === walletExtension
                                ? window.cosmostation.providers.keplr
                                : window[walletExtension];
                            const suggestToken =
                              "leap" === walletExtension
                                ? walletExtensionClient.suggestCW20Token
                                : walletExtensionClient.suggestToken;
                            try {
                              await suggestToken(asset.contextChainId, asset.address);
                            } catch {
                              console.log("Wallet closed, suggestToken() aborted");
                            }
                          }}
                        >
                          <span className="tokenIcon">
                            <img
                              src={imgSanitize(asset.symbol)}
                              alt={i18(`${asset.symbol} token`, "help.tokens.addTokensToWallet.btn.icon.alt", {
                                tokenSymbol: asset.symbol,
                              })}
                            />
                          </span>

                          <span className="tokenName">
                            {asset.symbol + " (" + chains[asset.contextChainId].displayName + ")"}
                          </span>
                        </div>
                      ))}
                  </span>
                </Trans>
              </HelpSubPageSection>
              <HelpSubPageSection
                sectionId="aboutLayer2Tokens"
                sectionTitle={i18(
                  `How to identify layer 2 tokens`,
                  "help.tokens.subpage.section.aboutLayer2Tokens.title"
                )}
              >
                <p>
                  <Trans i18nKey="help.tokens.aboutLayer2Tokens.text.0">
                    A layer 2 token type deposit from IBC may display in a wallet as an address with no name or token
                    image.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="help.tokens.aboutLayer2Tokens.text.1">
                    The following are Astrovault accepted token addresses;
                  </Trans>
                </p>
                <p>
                  <Trans>
                    <ul>
                      <li>
                        ALTER (2023 Oct 28){" "}
                        <code>cw20:secret17ljp7wwesff85ewt8xlauxjt7zrlr2hh27wgvr (Secret Network/channel-39)</code>
                      </li>
                    </ul>
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="help.tokens.aboutLayer2Tokens.text.2">
                    Use the above list to identify token address balances in the wallet.
                  </Trans>
                </p>
              </HelpSubPageSection>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default HelpTokens;
