import BigNumber from "bignumber.js";
import { Coin } from "@cosmjs/stargate";
import { IWalletConnectedChainInfo } from "@axvdex/state/wallet/initialState";
import { IAsset, IChain, IContract, IPool } from "../interfaces";
import { normalize_amount, toUSDAmount } from "../formatNumber";
import { div, minus, plus, times } from "../math";
import simulateSwap from "./simulateSwap";

const DEFAULT_TIMEOUT_SECONDS = 150; // 2.5m

export default (
  offerAssetWalletChainContext: IWalletConnectedChainInfo | undefined,
  askAssetWalletChainContext: IWalletConnectedChainInfo | undefined,
  allPools: {
    [key: string]: IPool;
  },
  allAssets: {
    [key: string]: IAsset;
  },
  allContracts: {
    [key: string]: IContract;
  },
  allChains: {
    [key: string]: IChain;
  },
  estimatedFeesReference: {
    estimatedFee: Coin[];
    gasLimit: number;
  },
  hops: {
    [assetX: string]: {
      [assetY: string]: string;
    };
  },
  offerAsset: IAsset,
  offerAssetAmountRaw: string | null,
  askAsset: IAsset,
  slippageTolerance: number
) => {
  const errorResponse = {
    offerAmount: offerAssetAmountRaw,
    offerAmountInUSD: BigNumber(offerAssetAmountRaw).times(offerAsset.price).toNumber(),
    askAmount: "0",
    askAmountInUSD: "0",
    offerToAskRatio: "0",
    askToOfferRatio: "0",
    error: {
      type: "crosschainSwap",
      message: "No route found...",
      buttonText: "No route for this action",
      data: null,
    },
    tx: null,
  };

  try {
    const {
      routeDataOfferAssetToIntermidiaryAsset,
      routeDataIntermidiaryAssettoAskAsset,
      ibcOperatorFee,
      ibcOperatorRoute,
      ibcOperator,
      intermidiaryAssetOnAskAssetChain,
      intermidiaryAssetOnofferAssetChain,
    } = simulateCrosschain(
      offerAssetWalletChainContext,
      askAssetWalletChainContext,
      allPools,
      allAssets,
      allContracts,
      allChains,
      estimatedFeesReference,
      hops,
      offerAsset,
      offerAssetAmountRaw,
      askAsset,
      slippageTolerance
    );

    const srcChain = allChains[offerAsset.contextChainId];
    const destChain = allChains[askAsset.contextChainId];

    // find the channel of the operator for this swap
    const ibcOperatorChannel = [...ibcOperator.config.channels]
      .reverse() // reverse to get latest created channel
      .find(channel =>
        Object.values(channel.cw20_mappings).find(
          (mapping: any) => mapping.target_cw20 === intermidiaryAssetOnAskAssetChain.address
        )
      );

    // make the full route to display on the visualizer
    const bestRoute = [
      ...(routeDataOfferAssetToIntermidiaryAsset.route ? routeDataOfferAssetToIntermidiaryAsset.route : []),
      ...ibcOperatorRoute,
      ...(routeDataIntermidiaryAssettoAskAsset.route ? routeDataIntermidiaryAssettoAskAsset.route : []),
    ];

    // calc other fields
    const minimumReceivedSource = bestRoute[bestRoute.length - 1].ignoreMinimumReceiveAmount
      ? offerAssetAmountRaw
      : minus(
        routeDataOfferAssetToIntermidiaryAsset.askAmount,
        div(times(routeDataOfferAssetToIntermidiaryAsset.askAmount, slippageTolerance), 100)
      );

    const minimumReceivedTarget = bestRoute[bestRoute.length - 1].ignoreMinimumReceiveAmount
      ? offerAssetAmountRaw
      : minus(
        routeDataIntermidiaryAssettoAskAsset.askAmount,
        div(times(routeDataIntermidiaryAssettoAskAsset.askAmount, slippageTolerance), 100)
      );

    const askAssetPoolIndex = allPools[bestRoute[bestRoute.length - 1].p]?.poolAssets.findIndex((asset: any) => {
      if (asset.info.token) {
        return asset.info.token.contract_addr === bestRoute[bestRoute.length - 1].y;
      }
      return asset.info.native_token.denom === bestRoute[bestRoute.length - 1].y;
    });

    const minimumReceivedInUSD = toUSDAmount(
      allAssets[bestRoute[bestRoute.length - 1].y].price!,
      BigInt(
        BigNumber(minimumReceivedTarget)
          .times(Math.pow(10, allAssets[askAsset.id].decimals))
          .decimalPlaces(0, BigNumber.ROUND_FLOOR)
          .toString(10)
      ),
      bestRoute[bestRoute.length - 1].p && askAssetPoolIndex !== -1
        ? allPools[bestRoute[bestRoute.length - 1].p].assetDecimals[askAssetPoolIndex]
        : intermidiaryAssetOnAskAssetChain.decimals // if there is no index it means there is no pool on the target side so its to the intermidiate token
    );

    let feePer = "0";
    let feeInUSD = 0;
    let expectedCashbackAmount = "0";
    for (const hop of bestRoute) {
      feePer = BigNumber(feePer)
        .plus(BigNumber(hop.feeAmount).div(BigNumber(hop.askAmount).plus(hop.feeAmount)).times(100))
        .decimalPlaces(2)
        .toString();
      feeInUSD += toUSDAmount(allAssets[hop.y].price!, hop.feeAmount, allAssets[hop.y].decimals);
      expectedCashbackAmount = plus(
        expectedCashbackAmount,
        div(hop.expectedCashbackMinted.toString(), Math.pow(10, 6))
      );
    }

    const inputDollarAmount = BigNumber(routeDataOfferAssetToIntermidiaryAsset.offerAmount).times(offerAsset.price);
    const outputDollarAmount = BigNumber(routeDataIntermidiaryAssettoAskAsset.askAmount).times(askAsset.price);
    const feeDollarAmount = BigNumber(feeInUSD);
    const marketImpactPer = inputDollarAmount
      .div(outputDollarAmount.plus(feeDollarAmount))
      .minus(1)
      .times(100)
      .toString();

    const swapRatio = BigNumber(
      normalize_amount(
        bestRoute[bestRoute.length - 1].askAmount,
        allAssets[bestRoute[bestRoute.length - 1].y].decimals
      ).toString(10)
    ).div(BigNumber(normalize_amount(bestRoute[0].offerAmount, allAssets[bestRoute[0].x].decimals).toString(10)));


    // price impact calc
    // To calc priceImpact need to calc a swap of the same assets on the same route with a small amount
    // then compare the small ratio with cur ratio
    // as the crosschain swap always have a fee in the middle it should be always less than $1, so we default to $1 offer asset amount
    // we ignore price impact if the amount is too small

    let priceImpact = null;
    let priceImpactPer = null;

    const smallOfferAmount =
      inputDollarAmount <= BigNumber(1)
        ? null
        : BigNumber(1)
          .div(offerAsset.price)
          .decimalPlaces(0)
          .toString(10);

    if (smallOfferAmount) {
      const {
        routeDataOfferAssetToIntermidiaryAsset: smallRouteDataOfferAssetToIntermidiaryAsset,
        routeDataIntermidiaryAssettoAskAsset: smallRouteDataIntermidiaryAssettoAskAsset,
      } = simulateCrosschain(
        offerAssetWalletChainContext,
        askAssetWalletChainContext,
        allPools,
        allAssets,
        allContracts,
        allChains,
        estimatedFeesReference,
        hops,
        offerAsset,
        smallOfferAmount,
        askAsset,
        slippageTolerance
      );

      const smallRatio = BigNumber(smallRouteDataIntermidiaryAssettoAskAsset.askAmount).div(
        smallRouteDataOfferAssetToIntermidiaryAsset.offerAmount
      );

      priceImpact = smallRatio.div(swapRatio).minus(1).times(100);
      priceImpactPer = !priceImpact ? "0" : priceImpact.lt(0) ? "0" : priceImpact.toString(10);
    }

    return {
      offerAmount: routeDataOfferAssetToIntermidiaryAsset.offerAmount,
      offerAmountInUSD: routeDataOfferAssetToIntermidiaryAsset.offerAmountInUSD,
      askAmount: routeDataIntermidiaryAssettoAskAsset.askAmount,
      askAmountInUSD: routeDataIntermidiaryAssettoAskAsset.askAmountInUSD,
      minimumReceived: minimumReceivedTarget,
      minimumReceivedInUSD,
      offerToAskRatio:
        routeDataOfferAssetToIntermidiaryAsset.offerAmount !== "0"
          ? div(routeDataIntermidiaryAssettoAskAsset.askAmount, routeDataOfferAssetToIntermidiaryAsset.offerAmount)
          : "-",
      askToOfferRatio:
        routeDataIntermidiaryAssettoAskAsset.askAmount !== "0"
          ? div(routeDataOfferAssetToIntermidiaryAsset.offerAmount, routeDataIntermidiaryAssettoAskAsset.askAmount)
          : "-",
      route: bestRoute,
      feePer,
      feeInUSD,
      expectedCashbackAmount,
      marketImpactPer: marketImpactPer !== "-" ? parseFloat(marketImpactPer).toFixed(2) : marketImpactPer,
      priceImpactPer: priceImpactPer ? parseFloat(priceImpactPer).toFixed(2) : "-",
      tx: {
        ibcOperatorAction: {
          toExecuteContract: !offerAsset.isNative
            ? offerAsset.address
            : ibcOperator.address,
          srcChain: {
            explorerURL: srcChain.explorerURL,
            chainId: srcChain.chainId,
            restURL: srcChain.rest,
            rpcURL: srcChain.rpc,
            isEVM: srcChain.isEVM,
          },
          dstChain: {
            explorerURL: destChain.explorerURL,
            chainId: destChain.chainId,
            restURL: destChain.rest,
            rpcURL: destChain.rpc,
            isEVM: destChain.isEVM,
          },
          assetBalancesToUpdate: [
            {
              client: offerAssetWalletChainContext?.signingClient,
              userAddress: offerAssetWalletChainContext?.address,
              tokens: offerAsset.address ? [offerAsset.address] : [],
              natives: offerAsset.denom ? [offerAsset.denom] : [],
            },
            {
              client: askAssetWalletChainContext?.signingClient,
              userAddress: askAssetWalletChainContext?.address,
              tokens: askAsset.address ? [askAsset.address] : [],
              natives: askAsset.denom ? [askAsset.denom] : [],
            },
          ],
          // different body when coming from intermidiary directly (send intermidiary from contract to operator directly)
          msgBody: !offerAsset.isNative
            ? {
              send: {
                contract: ibcOperator.address,
                amount: BigNumber(offerAssetAmountRaw)
                  .times(Math.pow(10, intermidiaryAssetOnofferAssetChain.decimals))
                  .decimalPlaces(0)
                  .toString(10),
                msg: Buffer.from(
                  JSON.stringify({
                    ibc_cross_swap: {
                      source_route: routeDataOfferAssetToIntermidiaryAsset.route
                        ? {
                          route_v2: {
                            hops: routeDataOfferAssetToIntermidiaryAsset.route.map((hop: any) => hop.hopTx),
                            minimum_receive: BigNumber(minimumReceivedSource)
                              .times(
                                Math.pow(
                                  10,
                                  allAssets[
                                    routeDataOfferAssetToIntermidiaryAsset.route[
                                      routeDataOfferAssetToIntermidiaryAsset.route?.length - 1
                                    ].y
                                  ].decimals
                                )
                              )
                              .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                              .toString(10),
                          },
                        }
                        : null,
                      ibc_channel_id: ibcOperatorChannel.id,
                      ibc_timeout: DEFAULT_TIMEOUT_SECONDS,
                      target_route: routeDataIntermidiaryAssettoAskAsset.route
                        ? {
                          route_v2: {
                            hops: routeDataIntermidiaryAssettoAskAsset.route.map((hop: any) => hop.hopTx),
                            minimum_receive: BigNumber(minimumReceivedTarget)
                              .times(
                                Math.pow(
                                  10,
                                  allAssets[
                                    routeDataIntermidiaryAssettoAskAsset.route[
                                      routeDataIntermidiaryAssettoAskAsset.route?.length - 1
                                    ].y
                                  ].decimals
                                )
                              )
                              .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                              .toString(10),
                          },
                        }
                        : null,
                      target_dst_address: askAssetWalletChainContext?.address,
                    },
                  })
                ).toString("base64"),
              },
            }
            : {
              ibc_cross_swap: {
                source_route: routeDataOfferAssetToIntermidiaryAsset.route
                  ? {
                    route_v2: {
                      hops: routeDataOfferAssetToIntermidiaryAsset.route.map((hop: any) => hop.hopTx),
                      minimum_receive: BigNumber(minimumReceivedSource)
                        .times(
                          Math.pow(
                            10,
                            allAssets[
                              routeDataOfferAssetToIntermidiaryAsset.route[
                                routeDataOfferAssetToIntermidiaryAsset.route?.length - 1
                              ].y
                            ].decimals
                          )
                        )
                        .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                        .toString(10),
                    },
                  }
                  : null,
                ibc_channel_id: ibcOperatorChannel.id,
                ibc_timeout: DEFAULT_TIMEOUT_SECONDS,
                target_route: routeDataIntermidiaryAssettoAskAsset.route
                  ? {
                    route_v2: {
                      hops: routeDataIntermidiaryAssettoAskAsset.route.map((hop: any) => hop.hopTx),
                      minimum_receive: BigNumber(minimumReceivedTarget)
                        .times(
                          Math.pow(
                            10,
                            allAssets[
                              routeDataIntermidiaryAssettoAskAsset.route[
                                routeDataIntermidiaryAssettoAskAsset.route?.length - 1
                              ].y
                            ].decimals
                          )
                        )
                        .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                        .toString(10),
                    },
                  }
                  : null,
                target_dst_address: askAssetWalletChainContext?.address,
              },
            },
          funds: routeDataOfferAssetToIntermidiaryAsset.tx?.contract?.funds || [],
        },
      },
    };
  } catch (e) {
    errorResponse.error.message = e.message;
    if (e.message.includes("ErrorDueToLowAmount"))
      errorResponse.error.buttonText = "Trade amount too low";
    return errorResponse;
  }
};

const simulateCrosschain = (
  offerAssetWalletChainContext: IWalletConnectedChainInfo,
  askAssetWalletChainContext: IWalletConnectedChainInfo,
  allPools: {
    [key: string]: IPool;
  },
  allAssets: {
    [key: string]: IAsset;
  },
  allContracts: {
    [key: string]: IContract;
  },
  allChains: {
    [key: string]: IChain;
  },
  estimatedFeesReference: {
    estimatedFee: Coin[];
    gasLimit: number;
  },
  hops: {
    [assetX: string]: {
      [assetY: string]: string;
    };
  },
  offerAsset: IAsset,
  offerAssetAmountRaw: string | null,
  askAsset: IAsset,
  slippageTolerance: number
) => {
  let result: any = null;
  let error: any = null;

  // for crosschain swaps we ALWAYS want to pass through intermidiary token as the "bridge" to the other chain using the ibc-operator
  // 0. Check allowed intermidiary tokens
  const ibcOperator = allContracts["ibc-operator_" + offerAsset.contextChainId];
  const allowedIntermidiaryAssetsOfferAssetChain: IAsset[] = ibcOperator.config.swap_config?.allowed_assets.map(asset => allAssets[asset.asset_id]);

  if (allowedIntermidiaryAssetsOfferAssetChain.length === 0) {
    throw new Error("No route found...");
  }

  // 1. Simulations for all allowed intermidiary tokens
  // 1.1. Begin by simulate the best route on offerAsset chain side to the allowed asset on the loop
  // 1.2. Check ibc fees for intermiodiate asset
  // 1.3. Simulate the best route on askAsset chain side from IntermidiaryAsset to askAsset
  // 1.4. Choose the best route based on the $ amount received on the askAsset chain side
  for (const intermidiaryAssetOnofferAssetChain of allowedIntermidiaryAssetsOfferAssetChain) {
    try {
      let routeDataOfferAssetToIntermidiaryAsset = null;
      // we dont need to simulate if the offerAsset is already intermidiary asset
      if (offerAsset.id === intermidiaryAssetOnofferAssetChain.id) {
        routeDataOfferAssetToIntermidiaryAsset = {
          offerAmount: offerAssetAmountRaw,
          offerAmountInUSD: BigNumber(offerAssetAmountRaw).times(offerAsset.price).toNumber(),
          askAmount: offerAssetAmountRaw,
          askAmountInUSD: BigNumber(offerAssetAmountRaw).times(offerAsset.price).toNumber(),
          expectedCashbackMinted: BigInt(0),
        };
      } else {
        routeDataOfferAssetToIntermidiaryAsset = simulateSwap(
          offerAssetWalletChainContext,
          allPools,
          allAssets,
          allContracts,
          estimatedFeesReference,
          hops,
          offerAsset,
          offerAssetAmountRaw,
          intermidiaryAssetOnofferAssetChain,
          undefined // no slipage tolerance here, only on the ask asset chain side
        );
      }

      if (!routeDataOfferAssetToIntermidiaryAsset) {
        throw new Error("No route found...");
      }

      // 2. Check if amount of Intermidiary asset received is enough to cover ibc operator fee and > 0
      let ibcOperatorFee = "0";
      if (routeDataOfferAssetToIntermidiaryAsset.askAmount !== "0") {
        const assetSwapConfig = ibcOperator.config.swap_config?.allowed_assets.find(
          asset => asset.asset_id === intermidiaryAssetOnofferAssetChain.address
        )?.asset_fee_config;

        if (assetSwapConfig) {
          if (assetSwapConfig.flat_fee_amount)
            ibcOperatorFee = BigNumber(assetSwapConfig.flat_fee_amount)
              .div(Math.pow(10, intermidiaryAssetOnofferAssetChain.decimals))
              .toString(10);
          if (assetSwapConfig.flat_fee_usd) {
            const cashbackMinter = allContracts["cashback-minter_" + offerAsset.contextChainId];
            const ratio =
              cashbackMinter.extraFields.assets.find(asset => asset.id === intermidiaryAssetOnofferAssetChain.address)?.ratio ||
              BigNumber(offerAsset.price * 1000000)
                .decimalPlaces(0)
                .toString(10);
            // to calc fee in USD, we need to use cashback minter ratios

            const feeAmountRaw = BigNumber(assetSwapConfig.flat_fee_usd.usd_amount).div(BigNumber(ratio).div(100000000))
              .times(Math.pow(10, intermidiaryAssetOnofferAssetChain.decimals))
              .decimalPlaces(0).toString(10);
            ibcOperatorFee = BigNumber(feeAmountRaw)
              .div(Math.pow(10, intermidiaryAssetOnofferAssetChain.decimals))
              .toString(10);
          }

          if (BigNumber(ibcOperatorFee).gt(routeDataOfferAssetToIntermidiaryAsset.askAmount)) {
            throw new Error("(ErrorDueToLowAmount) 1st chain: amount of Intermidiary Asset received not sufficient to cover ibc operator fee");
          }
        } else {
          throw new Error("1st chain: intermidiary asset provided is not allowed by ibc operator");
        }
      } else {
        throw new Error("(ErrorDueToLowAmount) 1st chain: 0 amount of Intermidiary Asset at ibc-operator");

      }

      // create the route element for the ibc operator
      const intermidiaryAssetAmount2ndChain = BigNumber(routeDataOfferAssetToIntermidiaryAsset.askAmount)
        .minus(ibcOperatorFee)
        .toString(10);
      const intermidiaryAssetOnAskAssetChain = Object.values(allAssets).find(
        asset => asset.contextChainId === askAsset.contextChainId && asset.symbol === intermidiaryAssetOnofferAssetChain.symbol
      );

      const ibcOperatorRoute = [
        {
          offerAmount: BigInt(
            BigNumber(routeDataOfferAssetToIntermidiaryAsset.askAmount)
              .times(Math.pow(10, intermidiaryAssetOnofferAssetChain.decimals))
              .decimalPlaces(0)
              .toString(10)
          ),
          askAmount: BigInt(
            BigNumber(intermidiaryAssetAmount2ndChain)
              .times(Math.pow(10, intermidiaryAssetOnAskAssetChain.decimals))
              .decimalPlaces(0)
              .toString(10)
          ),
          x: intermidiaryAssetOnofferAssetChain.id,
          y: intermidiaryAssetOnAskAssetChain.id,
          feeAmount: BigInt(
            BigNumber(ibcOperatorFee)
              .times(Math.pow(10, intermidiaryAssetOnofferAssetChain.decimals))
              .decimalPlaces(0)
              .toString(10)
          ),
          xChain: allChains[offerAsset.contextChainId].displayName,
          yChain: allChains[askAsset.contextChainId].displayName,
          expectedCashbackMinted: BigInt(0),
        },
      ];

      // 3. Simulate the best route on askAsset chain side from IntermidiaryAsset to askAsset
      let routeDataIntermidiaryAssettoAskAsset = null;
      if (askAsset.id === intermidiaryAssetOnAskAssetChain.id) {
        routeDataIntermidiaryAssettoAskAsset = {
          offerAmount: intermidiaryAssetAmount2ndChain,
          offerAmountInUSD: BigNumber(intermidiaryAssetAmount2ndChain).times(askAsset.price).toNumber(),
          askAmount: intermidiaryAssetAmount2ndChain,
          askAmountInUSD: BigNumber(intermidiaryAssetAmount2ndChain).times(askAsset.price).toNumber(),
          expectedCashbackMinted: BigInt(0),
          // route: [
          //   {
          //     offerAmount: BigInt(
          //       BigNumber(axvAmount2ndChain).times(Math.pow(10, allAssets[askAsset.id].decimals)).toString(10)
          //     ),
          //     askAmount: BigInt(
          //       BigNumber(axvAmount2ndChain).times(Math.pow(10, allAssets[askAsset.id].decimals)).toString(10)
          //     ),
          //     x: askAsset.id,
          //     y: axvOnAskAssetChain.id,
          //     feeAmount: BigInt(0),
          //   },
          // ],
        };
      } else {
        routeDataIntermidiaryAssettoAskAsset = simulateSwap(
          askAssetWalletChainContext,
          allPools,
          allAssets,
          allContracts,
          estimatedFeesReference,
          hops,
          intermidiaryAssetOnAskAssetChain,
          intermidiaryAssetAmount2ndChain,
          askAsset,
          slippageTolerance
        );
      }

      // if there is still no result or if this route is better than the previous one, save it
      if (
        !result ||
        (routeDataIntermidiaryAssettoAskAsset.askAmountInUSD > result.routeDataIntermidiaryAssettoAskAsset.askAmountInUSD)
      ) {
        result = {
          routeDataOfferAssetToIntermidiaryAsset,
          routeDataIntermidiaryAssettoAskAsset,
          ibcOperatorFee,
          ibcOperatorRoute,
          ibcOperator,
          intermidiaryAssetOnAskAssetChain,
          intermidiaryAssetOnofferAssetChain,
        };
      }
    } catch (e) {
      error = e;
      continue;
    }
  }

  // if not result found, throw error that that was saved from the last iteration
  if (!result) throw error;
  else return result;
};
