import { extend } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import { join, keys } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import { PoolAnalyticsDataSamples, poolTableData } from "@axvdex/views/Statistics";
import { abbreviateUSD } from "@axvdex/utils/formatNumber";
import { selectAssets, selectPools } from "@axvdex/state/wallet/walletSelectors";
import { useAppSelector } from "@axvdex/state";
import { responsiveBreakpoints } from "@axvdex/constants";
import useLanguage from "@axvdex/hooks/useLanguage";
import CustomInputButton from "../form-element/CustomInputButton";
import CustomSelect, { ListItemsProps } from "../form-element/CustomSelect";

extend(customParseFormat);
extend(utc);
HighchartsAccessibility(Highcharts);

const poolsFilterSorter = (a, b) => {
  if ("allPools" === a.value) return -1;
  else if ("allPools" === b.value) return 1;

  return ("" + a.label).localeCompare(b.label);
};

const VolumeChart = ({
  analyticsData,
  tableData,
  current24hVolume,
  topFilter,
}: {
  analyticsData: PoolAnalyticsDataSamples;
  tableData: poolTableData;
  current24hVolume: number;
  topFilter: string;
}) => {
  const { i18 } = useLanguage();

  const VolumeTimeFilters = [
    {
      id: "daily",
      label: i18("Daily", "statistics.volumeChart.daily"),
      mobileLabel: i18("D", "statistics.volumeChart.mobileLabel.daily"),
    },
    {
      id: "weekly",
      label: i18("Weekly", "statistics.volumeChart.weekly"),
      mobileLabel: i18("W", "statistics.volumeChart.mobileLabel.weekly"),
    },
    {
      id: "monthly",
      label: i18("Monthly", "statistics.volumeChart.monthly"),
      mobileLabel: i18("M", "statistics.volumeChart.mobileLabel.monthly"),
    },
  ];
  const pools = useAppSelector(selectPools);
  const assets = useAppSelector(selectAssets);

  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);

  const [poolOptions, setPoolOptions] = useState<ListItemsProps[]>([]);
  const [poolFilter, setPoolFilter] = useState("allPools");
  const [timeFilter, setTimeFilter] = useState("daily");

  const handleFilterChange = e => {
    setPoolFilter(e.value);
  };

  // every time top filter changes, reset the subfilters
  useEffect(() => {
    setPoolFilter("allPools");
    setTimeFilter("daily");
  }, [topFilter]);

  useEffect(() => {
    if (pools && Object.keys(pools).length > 0) {
      const poolIds = keys(analyticsData);

      const getPoolLabel = pool => {
        const assetIDs = [];
        pool?.poolAssets?.forEach(asset => {
          const assetId = asset.info?.token?.contract_addr || asset.info?.native_token?.denom;
          assetIDs.push(assetId);
        });
        return join(
          assetIDs.map(assetID => assets[assetID]?.symbol),
          " "
        );
      };

      const options = poolIds
        .filter(poolId => poolId === "allPools" || (poolId !== "allPools" && pools[poolId])) // added to prevent showing pools that are disabled for this contract env
        .map(poolId => {
          if ("allPools" === poolId) {
            return { label: i18("All Pools", "statistics.volumeChart.allPools"), value: "allPools" };
          }
          return { label: getPoolLabel(pools[poolId]), value: poolId };
        })
        .sort(poolsFilterSorter);
      setPoolOptions(options);
    }
  }, [analyticsData, pools]);

  const volumeChartData = useMemo(() => {
    let sortedData = analyticsData[poolFilter];

    if ("weekly" === timeFilter) {
      // Create an object to store weekly sums
      const weeklySums = {};
      const weeklyCounts = {};

      // Iterate through the data and group by week
      sortedData.forEach(entry => {
        // Calculate the start of the week for the current timestamp
        const startOfWeek = new Date(entry[0]);
        const dayOfWeek = startOfWeek.getDay();
        const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
        startOfWeek.setDate(startOfWeek.getDate() - daysToSubtract);
        const startOfWeekTimestamp = "" + startOfWeek.getTime();

        // If the week is not in the object, create a new entry
        if (!weeklySums[startOfWeekTimestamp]) {
          weeklySums[startOfWeekTimestamp] = [0, 0];
          weeklyCounts[startOfWeekTimestamp] = 0;
        }

        // Add the value to the corresponding week
        weeklySums[startOfWeekTimestamp][0] += entry[1];
        weeklySums[startOfWeekTimestamp][1] += entry[2];
        weeklyCounts[startOfWeekTimestamp] += 1;
      });

      sortedData = Object.keys(weeklySums).map(timestamp => {
        return [parseFloat(timestamp), weeklySums[timestamp][0] / weeklyCounts[timestamp], weeklySums[timestamp][1]];
      });
    }

    if ("monthly" === timeFilter) {
      // Create an object to store weekly sums
      const monthlySums = {};
      const monthlyCounts = {};

      // Iterate through the data and group by week
      sortedData.forEach(entry => {
        const year = new Date(entry[0]).getFullYear();
        const month = new Date(entry[0]).getMonth();
        const startOfMonth = new Date(year, month, 1).getTime();

        // If the week is not in the object, create a new entry
        if (!monthlySums[startOfMonth]) {
          monthlySums[startOfMonth] = [0, 0];
          monthlyCounts[startOfMonth] = 0;
        }

        // Add the value to the corresponding week
        monthlySums[startOfMonth][0] += entry[1];
        monthlySums[startOfMonth][1] += entry[2];
        monthlyCounts[startOfMonth] += 1;
      });

      sortedData = Object.keys(monthlySums).map(timestamp => {
        return [parseFloat(timestamp), monthlySums[timestamp][0] / monthlyCounts[timestamp], monthlySums[timestamp][1]];
      });
    }

    return sortedData;
  }, [poolOptions, poolFilter, timeFilter]);

  const disableFilter = label => {
    const startDate = new Date(1688338800000);
    if ("Daily" === label) return false;
    if ("Weekly" === label) {
      startDate.setDate(startDate.getDate() + 7);
      return new Date() < startDate;
    }
    if ("Monthly" === label) {
      startDate.setDate(startDate.getDate() + 30);
      return new Date() < startDate;
    }
  };

  const VolumeTitle = () => {
    let titleText = "";
    if ((current24hVolume && "allPools" === poolFilter) || volumeChartData) {
      if ("daily" === timeFilter) {
        titleText = i18("24h Volume", "statistics.volumeChart.Title.1d");
      } else if ("weekly" === timeFilter) {
        titleText = i18("7d Volume", "statistics.volumeChart.Title.7d");
      } else if ("monthly" === timeFilter) {
        titleText = i18("30d Volume", "statistics.volumeChart.Title.30d");
      }
    }
    if (0 === titleText.length) {
      return "-";
    }

    let value = 0,
      samples = 0;
    if (current24hVolume && "allPools" === poolFilter) {
      if ("daily" === timeFilter) {
        value = current24hVolume;
      } else {
        if ("weekly" === timeFilter) {
          samples = -7; // get last 7 samples that correspond to last 7 days
        } else if ("monthly" === timeFilter) {
          samples = -30; // get last 30 samples that correspond to last 30 days
        }
        // sum all volume numbers from the samples with reduce
        value =
          0 === samples ? 0 : analyticsData[poolFilter].slice(samples).reduce((acc, sample) => acc + sample[2], 0);
      }
    } else if (volumeChartData) {
      if ("daily" === timeFilter) {
        value = tableData[poolFilter]?.volume24hInUSD || 0;
      } else if ("weekly" === timeFilter) {
        value = tableData[poolFilter]?.volume7dInUSD || 0;
      } else if ("monthly" === timeFilter) {
        value = tableData[poolFilter]?.volume30dInUSD || 0;
      }
    }

    return (
      <div className="statsSectionGraphGridItemHeaderText">
        <p className="statsSectionGridItemTitle textGrey">{titleText}</p>
        <span className="statsSectionGridItemValue pricing">{abbreviateUSD(value)}</span>
      </div>
    );
  };

  return (
    <div className="statsSectionGridItem">
      <div className="statsSectionGraphGridItemHeader">
        <VolumeTitle />

        <div className="inlineFlexbox">
          <CustomSelect
            name="volume_pools"
            labelText={i18("Filter by pools", "statistics.volumeChart.labelText")}
            hiddenLabel={true}
            value={poolOptions[poolOptions.findIndex(option => option.value === poolFilter)]}
            items={poolOptions}
            onChange={handleFilterChange}
          />
        </div>
      </div>

      <div className="btnGroup statsSectionGraphBtnGroup">
        {VolumeTimeFilters.map((item, idx) => (
          <CustomInputButton
            key={idx}
            type="radio"
            id={item.id}
            name="volume_time_filter"
            labelText={isMobileBreakpoint ? item.mobileLabel : item.label}
            checked={timeFilter === item.id}
            onChange={() => setTimeFilter(item.id)}
            disabled={disableFilter(item.label)}
          />
        ))}
      </div>

      <div className="statsGraphWrapper" key={poolFilter + "_" + timeFilter + "_" + poolOptions}>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              panning: true,
              zooming: {
                mouseWheel: {
                  type: "x",
                },
              },
              height: "250rem",
              backgroundColor: "",
              events: {
                load: chartObject => {
                  if (volumeChartData && volumeChartData.length > 0) {
                    const min = volumeChartData[volumeChartData.length > 50 ? volumeChartData.length - 50 : 0][0];
                    const max = volumeChartData[volumeChartData.length - 1][0];
                    const maxValue = volumeChartData.reduce((a, b) => Math.max(a, b[2]), 0);

                    chartObject.target.xAxis[0].setExtremes(min, max);
                    chartObject.target.yAxis[0].setExtremes(0, maxValue + maxValue * 0.05);
                  }
                },
              },
            },
            title: {
              text: "",
            },
            accessibility: {
              enabled: true,
              description: "Volume",
            },
            xAxis: {
              type: "datetime",
              maxRange: 30 * 24 * 3600 * 1000,
              labels: {
                style: { color: "#666" },
              },
            },
            yAxis: {
              title: {
                text: "",
              },
              gridLineWidth: 0,
              opposite: true,
              labels: {
                formatter: sample => {
                  return abbreviateUSD(sample.value);
                },
                style: { color: "#666" },
              },
              //tickInterval: 100000,
              tickAmount: 8,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              formatter() {
                const options = {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                };
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const sampleDate = new Date(this.x).toLocaleDateString("en-uk", options);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return `${sampleDate}<br/><b>${abbreviateUSD(this.y)}</b>`;
              },
            },
            plotOptions: {
              area: {
                enableMouseTracking: true,
                color: color("#37c739").setOpacity(0.8).get("rgba"),
                fillColor: {
                  linearGradient: {
                    x1: 1,
                    y1: 1,
                    x2: 0,
                    y2: 0,
                  },
                  stops: [
                    [0, color("#00e272").setOpacity(0).get("rgba")],
                    [1, "#00e272"],
                    // [0, getOptions().colors[0]],
                    // [1, color(getOptions().colors[0]).setOpacity(0).get("rgba")],
                  ],
                },
                marker: {
                  radius: 2,
                },
                lineWidth: 1,
                states: {
                  hover: {
                    lineWidth: 1,
                  },
                },
                threshold: null,
              },
            },
            series: [
              {
                type: "area",
                name: "",
                data: volumeChartData.map(sample => [sample[0], sample[2]]),
              },
            ],
            credits: {
              enabled: false,
            },
          }}
        />
      </div>
    </div>
  );
};

export default VolumeChart;
