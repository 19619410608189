export const checkedTimeoutPromises = (promises: Promise<any>[], timeoutMs = 3000): Promise<any> => {
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error('Operation timed out'));
    }, timeoutMs);
  });

  return Promise.race([Promise.all(promises), timeoutPromise]);
}

export const checkedTimeoutPromise = (promise: Promise<any>, timeoutMs = 3000): Promise<any> => {
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error('Operation timed out'));
    }, timeoutMs);
  });

  return Promise.race([promise, timeoutPromise]);
}