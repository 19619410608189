import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { connectWalletWithDispatch } from "@axvdex/state/wallet/walletThunks";
import { WHITE_LIST_PERSISTED_STATE_KEYS, persistState } from "@axvdex/state/persist";
import useLanguage from "@axvdex/hooks/useLanguage";
import { hasPermitStored } from "@axvdex/utils/localStorage";
import { ReactComponent as LogoCosmostation } from "../assets/logos/logo-wallet-cosmostation.svg";
import { ReactComponent as LogoKeplr } from "../assets/logos/logo-wallet-keplr.svg";
import { ReactComponent as LogoLeap } from "../assets/logos/logo-wallet-leap.svg";
// import { ReactComponent as LogoArchx } from "../../assets/logos/logo-wallet-archx.svg";
import styles from "../styles/WalletConnectListItems.module.scss";
import Button from "./common/Button";
import { useGlobalModalContext } from "./../providers/GlobalModalProvider";
import { selectChains, selectGlobalConfig } from "@axvdex/state/wallet/walletSelectors";
import { IWalletInfo } from "@axvdex/state/wallet/initialState";

function WalletConnectListItems({
  isLoading,
  setIsLoading,
  selectedChains,
}: {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedChains: string[];
}) {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const chains = useAppSelector(selectChains);
  const globalConfig = useAppSelector(selectGlobalConfig);
  const { hideModal, showModal } = useGlobalModalContext();

  return (
    <ul className={clsx(styles.walletConnectList, "walletConnectList")}>
      {/*  todo: ArchX Wallet */}
      {/*<li className="walletConnectListItem">*/}
      {/*  <Button*/}
      {/*    iconPlacement="left"*/}
      {/*    icon={<LogoArchx />}*/}
      {/*    text="ArchX"*/}
      {/*    onClick={async () => {*/}
      {/*      persistState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet, "archx");*/}
      {/*      await dispatch(connectWalletWithDispatch());*/}
      {/*      hideModal();*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</li>*/}

      <li className="walletConnectListItem">
        <Button
          iconPlacement="left"
          icon={<LogoCosmostation />}
          text={i18("Cosmostation", "walletConnectList.btnConnectCosmostation.text")}
          title={i18("Connect Wallet to Cosmostation", "walletConnectList.btnConnectCosmostation.title")}
          disabled={isLoading}
          onClick={async () => {
            persistState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet, "cosmostation");
            setIsLoading(true);
            const chainsToConnect = selectedChains.map(chainId => chains[chainId]);

            const walletConnect: IWalletInfo | null = (
              await dispatch(connectWalletWithDispatch({ chainsToConnect, showModal }))
            ).payload as IWalletInfo | null;
            if (walletConnect) {
              hideModal();
              if (
                !hasPermitStored({
                  pubKey: walletConnect.pubKey,
                })
              )
                showModal("permitAuthenticationModal");
            }
            setIsLoading(false);
          }}
        />
        <Button
          iconPlacement="left"
          icon={<i className="feather icon-download-cloud" />}
          text={i18("Download", "walletConnectList.btnDownloadCosmostation.text")}
          btnVariant="link"
          btnColor="gradientText"
          title={i18("Download Cosmostation App", "walletConnectList.btnDownloadCosmostation.title")}
          btnSize="sm"
          onClick={() => {
            window.open("https://cosmostation.io/wallet/#extension", "_blank", "noopener, noreferrer");
          }}
        />
      </li>

      <li className="walletConnectListItem">
        <Button
          extraClassName="btnKeplr"
          iconPlacement="left"
          icon={<LogoKeplr />}
          text={i18("Keplr", "walletConnectList.btnConnectKeplr.text")}
          title={i18("Connect Wallet to Keplr", "walletConnectList.btnConnectKeplr.title")}
          disabled={isLoading}
          onClick={async () => {
            persistState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet, "keplr");
            setIsLoading(true);
            const chainsToConnect = selectedChains.map(chainId => chains[chainId]);

            const walletConnect: IWalletInfo | null = (
              await dispatch(connectWalletWithDispatch({ chainsToConnect, showModal }))
            ).payload as IWalletInfo | null;
            if (walletConnect) {
              hideModal();
              if (
                !hasPermitStored({
                  pubKey: walletConnect.pubKey,
                })
              )
                showModal("permitAuthenticationModal");
            }
            setIsLoading(false);
          }}
        />
        <Button
          iconPlacement="left"
          icon={<i className="feather icon-download-cloud" />}
          text={i18("Download", "walletConnectList.btnDownloadKeplr.text")}
          title={i18("Download Keplr App", "walletConnectList.btnDownloadKeplr.title")}
          btnVariant="link"
          btnColor="gradientText"
          btnSize="sm"
          onClick={() => {
            window.open("https://www.keplr.app/download", "_blank", "noopener, noreferrer");
          }}
        />
      </li>

      <li className="walletConnectListItem">
        <Button
          iconPlacement="left"
          icon={<LogoLeap />}
          text={i18("Leap", "walletConnectList.btnConnectLeap.text")}
          title={i18("Connect Wallet to Leap", "walletConnectList.btnConnectLeap.title")}
          disabled={isLoading}
          onClick={async () => {
            persistState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet, "leap");
            setIsLoading(true);
            const chainsToConnect = selectedChains.map(chainId => chains[chainId]);

            const walletConnect: IWalletInfo | null = (
              await dispatch(connectWalletWithDispatch({ chainsToConnect, showModal }))
            ).payload as IWalletInfo | null;
            if (walletConnect) {
              hideModal();
              if (
                !hasPermitStored({
                  pubKey: walletConnect.pubKey,
                })
              )
                showModal("permitAuthenticationModal");
            }
            setIsLoading(false);
          }}
        />
        <Button
          iconPlacement="left"
          icon={<i className="feather icon-download-cloud" />}
          text={i18("Download", "walletConnectList.btnDownloadLeap.text")}
          title={i18("Download Leap App", "walletConnectList.btnDownloadLeap.title")}
          btnVariant="link"
          btnColor="gradientText"
          btnSize="sm"
          onClick={() => {
            window.open("https://www.leapwallet.io/download", "_blank", "noopener, noreferrer");
          }}
        />
      </li>

      <li className="walletConnectListItem">
        <Button
          iconPlacement="left"
          icon={<LogoLeap />}
          text={i18("Leap Mobile", "walletConnectList.btnConnectLeapMobile.text")}
          title={i18("Connect Wallet to Leap Mobile", "walletConnectList.btnConnectLeapMobile.title")}
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            const chainsToConnect = selectedChains.map(chainId => chains[chainId]);

            if (window.leap) {
              persistState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet, "leap");
              const walletConnect: IWalletInfo | null = (
                await dispatch(connectWalletWithDispatch({ chainsToConnect, showModal }))
              ).payload as IWalletInfo | null;
              if (walletConnect) {
                hideModal();
                if (
                  !hasPermitStored({
                    pubKey: walletConnect.pubKey,
                  })
                )
                  showModal("permitAuthenticationModal");
              }
            } else {
              window.open(
                process.env.REACT_APP_LEAP_MOBILE_URL && process.env.REACT_APP_LEAP_MOBILE_URL !== ""
                  ? process.env.REACT_APP_LEAP_MOBILE_URL
                  : "https://leapcosmoswallet.page.link/skfBuHxbQWBA31v99",
                "_blank",
                "noopener, noreferrer"
              );
            }
            setIsLoading(false);
          }}
        />
        <Button
          iconPlacement="left"
          icon={<i className="feather icon-download-cloud" />}
          text={i18("Download", "walletConnectList.btnDownloadLeapMobile.text")}
          title={i18("Download Leap Mobile App", "walletConnectList.btnDownloadLeapMobile.title")}
          btnVariant="link"
          btnColor="gradientText"
          btnSize="sm"
          onClick={() => {
            window.open("https://www.leapwallet.io/download", "_blank", "noopener, noreferrer");
          }}
        />
      </li>
    </ul>
  );
}

export default WalletConnectListItems;
