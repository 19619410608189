/* eslint-disable react/jsx-key */
import { useEffect, useRef, useState } from "react";
import {
  ArrowLeft,
  Award,
  BarChart,
  BookOpen,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Heart,
  Info,
  Shield,
  Star,
} from "react-feather";
import BigNumber from "bignumber.js";
import { NumericFormat } from "react-number-format";
import { gsap } from "gsap";
import { Tooltip } from "react-tooltip";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import Visibility from "visibilityjs";
import { useMediaQuery } from "usehooks-ts";
// import clsx from "clsx";
import { abbreviateUSD } from "@axvdex/utils/formatNumber";
import imgSanitize from "@axvdex/utils/imgSanitize";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  selectAssets,
  selectNicknames,
  selectOutbid,
  selectOutbidServerTime,
  selectSalesBids,
  selectUser,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import useLanguage from "@axvdex/hooks/useLanguage";
import { updateNicknamesFromBlockchain, updateUserSaleBids } from "@axvdex/state/outbid/saleThunks";
import { responsiveBreakpoints } from "@axvdex/constants";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import { INicknameInfo } from "@axvdex/utils/interfaces";
import { noNicknamesCheck } from "@axvdex/utils/nicknamesSanitization";
import CustomNumericInput from "../form-element/CustomNumericInput";
import { ReactComponent as IcnSword } from "../../assets/icons/icn-sword.svg";
import { ListItemsProps } from "../form-element/CustomSelect";
import Button from "../common/Button";
import UserSettingsModal from "../modals/UserSettingsModal";
import CustomInputButton from "../form-element/CustomInputButton";
import DashboardPaginationTable from "../DashboardPaginationTable";
import { ReactComponent as IcnTicket } from "../../assets/icons/icn-outbid-ticket.svg";
import { ReactComponent as IcnTrophy } from "../../assets/icons/icn-trophy.svg";
// import styles from "../../styles/Outbid.module.scss";
import SaleMyTicketsModal from "./SaleMyTicketsModal";
// import SaleTestnetControls from "./SaleTestnetControls";
import SaleBidSection from "./SaleBidSection";
import SaleRedeemSection, { NFTModal } from "./SaleRedeemSection";
import SaleDocsModal from "./SaleDocsModal";
import SaleOperatorControls from "./SaleOperatorControls";
import SaleLeaderboardModal from "./SaleLeaderboardModal";
import SaleNicknameTargetModal from "./SaleNicknameTargetModal";

interface SalesSelectGridProps {
  outbidSale: string;
  setOutbidSaleSelected: React.Dispatch<React.SetStateAction<any>>;
}

// const SECTIONS_DIVISIONS = [
//   {
//     label: "VIP",
//     percentage: 25,
//   },
//   {
//     label: "",
//     percentage: 50,
//   },
//   {
//     label: "Bonus",
//     percentage: 25,
//   },
// ];

export const Sales = ({ outbidSale, setOutbidSaleSelected }: SalesSelectGridProps) => {
  // detecting change of url, if its /outbid it means the user wants to go back to the grid
  const location = useLocation();
  useEffect(() => {
    if ("/outbid" === location.pathname) {
      setOutbidSaleSelected(null);
    }
  }, [location]);

  const { i18 } = useLanguage();
  const routeParams = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isMobileSmBreakpoint = useMediaQuery(responsiveBreakpoints.mobileSm);

  const walletInfo = useAppSelector(selectWalletInfo);
  const salesBids = useAppSelector(selectSalesBids);
  const outbidSales = useAppSelector(selectOutbid);
  const outbidSelected = outbidSales[outbidSale];

  const walletChainContext = walletInfo?.connectedChains[outbidSelected.contextChainId];

  const [saleDocsModalOpen, setSaleDocsModalOpen] = useState(false);
  const [dropdownSaleList, setDropdownSaleList] = useState<ListItemsProps[]>([]);
  const [selectedSale, setSelectedSale] = useState<ListItemsProps | null>(null);
  const [onWhitelist, setOnWhitelist] = useState<boolean>(null);

  useEffect(() => {
    if (dropdownSaleList.length > 0 && routeParams?.saleID && routeParams?.saleID !== "" + selectedSale?.id)
      setSelectedSale(dropdownSaleList.find(sale => "" + sale.id === routeParams?.saleID));
  }, [routeParams]);

  useEffect(() => {
    if (walletInfo.isConnected) getAddressList();
  }, [walletInfo]);

  // useEffect(() => {
  //   if (Object.keys(outbid[outbidSaleSelected].salesState).length !== dropdownSaleList.length)
  //     parseAllSalesToDropdown();
  //   if (
  //     selectedSale &&
  //     (outbid[outbidSaleSelected].salesState[selectedSale.value].sale_state.status === "EndedSoldOut" ||
  //       outbid[outbidSaleSelected].salesState[selectedSale.value].sale_state.status === "EndedNotSoldOut" ||
  //       outbid[outbidSaleSelected].salesState[selectedSale.value].sale_state.status === "EndedBonusTimeSoldOut")
  //   ) {
  //     dispatch(
  //       updateUserSaleBids({
  //         walletAddress: walletChainContext.address,
  //         saleAddress: selectedSale.value,
  //       })
  //     );
  //     parseAllSalesToDropdown();
  //   }
  // }, [outbid[outbidSaleSelected]]);

  useEffect(() => {
    if (Object.keys(outbidSales[outbidSale].salesState).length !== dropdownSaleList.length || selectedSale)
      parseAllSalesToDropdown();
  }, [outbidSales[outbidSale], selectedSale, salesBids]);

  // this use effect if for when the active sale changes, it will update the user bids for that sale as it can have the last bid and receive the bonus bag
  useEffect(() => {
    if (
      walletInfo.isConnected &&
      selectedSale &&
      ["EndedSoldOut", "EndedNotSoldOut", "EndedBonusTimeSoldOut"].includes(
        outbidSales[outbidSale].salesState[selectedSale.value].sale_state.status
      )
    ) {
      dispatch(
        updateUserSaleBids({
          client: walletChainContext.signingClient,
          walletAddress: walletChainContext.address,
          saleAddress: selectedSale.value,
          withDetails: true,
        })
      );
    }
  }, [walletInfo, outbidSales[outbidSale]]);

  useEffect(() => {
    walletInfo.isConnected && parseAllSalesToDropdown();
  }, [walletInfo]);

  const [loadingUserList, setLoadingUserList] = useState(false);
  const getAddressList = async () => {
    setLoadingUserList(true);
    const res = await rpcClientQuerySmartContractWrapper(walletChainContext.signingClient, outbidSelected.address, {
      check_address_list: { address: walletChainContext.address },
    });
    setOnWhitelist(res.is_whitelisted);
    await Promise.all(
      res.sale_ids.map((saleID: number) =>
        dispatch(
          updateUserSaleBids({
            client: walletChainContext.signingClient,
            walletAddress: walletChainContext.address,
            saleAddress: Object.keys(outbidSales[outbidSale].salesState).find(
              (saleAddr: string) => saleID === outbidSales[outbidSale].salesState[saleAddr].config.id
            ),
            withDetails: true,
          })
        )
      )
    );
    setLoadingUserList(false);
  };

  const parseAllSalesToDropdown = () => {
    let liveSaleFound = false;
    const list: ListItemsProps[] = Object.keys(outbidSelected.salesState)
      .map((saleAddress: string) => {
        const isEnded = ["EndedSoldOut", "EndedNotSoldOut", "EndedBonusTimeSoldOut"].includes(
          outbidSelected.salesState[saleAddress].sale_state.status
        );

        const isActive = !isEnded;

        const isWaiting = "WaitingToStart" === outbidSelected.salesState[saleAddress].sale_state.status;

        const withBid = salesBids && selectedSale && salesBids[saleAddress] && salesBids[saleAddress].bids.length > 0;

        let withIncentive = false;
        if (withBid && outbidSelected.salesState[saleAddress].incentives) {
          const bidIncentive = outbidSelected.salesState[saleAddress].incentives?.specific_bids_incentives?.find(
            (incentive: any) => salesBids[saleAddress].bids.includes(incentive.bid_id)
          );

          if (
            bidIncentive ||
            (isEnded &&
              outbidSelected.salesState[saleAddress].sale_state.last_bidder_addr === walletChainContext.address)
          ) {
            withIncentive = true;
          }
        }

        // only add one live sale to the dropdown
        if (isActive && liveSaleFound) return null;
        if (isActive) liveSaleFound = true;
        return {
          id: outbidSelected.salesState[saleAddress].config.id,
          value: "" + saleAddress,
          label: i18(`Sale ${outbidSelected.salesState[saleAddress].config.id}`, "sale.header.title", {
            saleid: outbidSelected.salesState[saleAddress].config.id,
          }),
          extraFields: { isActive, withBid, isEnded, isWaiting, withIncentive },
        };
      })
      .filter(sale => sale)
      .sort((a, b) => a.id - b.id);

    setDropdownSaleList(list);

    // if first time doing this and there is a redirectSaleID from the url
    if (!selectedSale && list.length > 0 && routeParams?.saleID) {
      setSelectedSale(list.find((sale: any) => "" + sale.id === routeParams?.saleID));
    } else if (!selectedSale && list.length > 0) {
      setSelectedSale(list[list.length - 1]);
      navigate(`/outbid/` + outbidSale + "/" + list[list.length - 1].id);
    } else {
      // refresh the value that is selected in case changes
      for (const sale of list) {
        if (
          selectedSale &&
          sale.id === selectedSale.id &&
          selectedSale.extraFields.isActive !== sale.extraFields.isActive
        ) {
          setSelectedSale(sale);
          navigate(`/outbid/` + outbidSale + "/" + sale.id);
          return;
        }
      }
    }
  };

  const calcTotalRaised = () => {
    return Object.values(outbidSelected.salesState)
      .reduce(function (acc, obj) {
        return acc.plus(BigNumber(obj.sale_state.state.tickets_count));
      }, BigNumber(0))
      .div(Math.pow(10, 6))
      .decimalPlaces(2)
      .toNumber();
  };

  return (
    <>
      <div className="salesDetailsHeader flexbox" style={{ marginBottom: "2em" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="inlineFlexbox">
            <Button
              style={{ marginRight: ".5em" }}
              title={i18("Go back", "sales.goBack")}
              icon={<ArrowLeft />}
              onClick={() => {
                setOutbidSaleSelected(null);
                navigate("/outbid");
              }}
              btnSize="sm"
            />
            {outbidSelected.projectSymbol && (
              <img src={imgSanitize(outbidSelected.projectSymbol)} alt={outbidSelected.projectSymbol} />
            )}
            <h1>{outbidSelected.project}</h1>
          </div>
          <div className="inlineFlexbox" style={{ marginTop: "1em" }}>
            <Button
              style={{ marginRight: "1.5em" }}
              title={i18("Documentation", "sales.docs")}
              //btnVariant="icon"
              //btnColor="dark-medium"
              icon={<BookOpen />}
              onClick={() => setSaleDocsModalOpen(true)}
              btnSize="sm"
            >
              {i18("Documentation", "sales.docs")}
            </Button>
            {walletInfo.isConnected && <UserSettingsModal outbidIcon={true} />}

            {walletChainContext &&
              (outbidSales[outbidSale].config?.operator === walletChainContext.address ||
                outbidSales[outbidSale].config?.owner === walletChainContext.address) && (
                <SaleOperatorControls outbidSale={outbidSales[outbidSale]} />
              )}
            {/* {walletInfo.isConnected &&
              process.env.REACT_APP_MODE === "TESTNET" &&
              process.env.REACT_APP_SUB_MODE === "INTERNAL" && <SaleTestnetControls outbidSale={outbidSelected} />} */}
          </div>
        </div>

        {selectedSale && (
          <section className="withGradientBorder" style={{ marginLeft: "auto" }}>
            <div style={{ display: "flex" }}>
              {null !== onWhitelist &&
                !loadingUserList && ( // only shows up after the user list is loaded
                  <UserTotalTicketsComponent outbidSale={outbidSelected} selectedSaleValue={selectedSale.value} />
                )}

              <TotalRaisedComponent
                tickets_count={outbidSelected.salesState[selectedSale.value].sale_state.state.tickets_count}
                calcTotalRaised={calcTotalRaised}
              />
            </div>
          </section>
        )}
      </div>
      {selectedSale && (
        <SalesBar
          salesList={dropdownSaleList}
          outbidSaleSelected={outbidSale}
          selectedSale={selectedSale}
          setSelectedSale={setSelectedSale}
        />
      )}
      <br />
      {!isMobileSmBreakpoint ? (
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            {outbidSelected.config.sales_process_ended ? (
              <SaleRedeemSection outbidSale={outbidSelected} selectedSale={selectedSale} salesBids={salesBids} />
            ) : (
              selectedSale && (
                <SaleBidSection
                  outbidSale={outbidSelected}
                  selectedSale={selectedSale}
                  salesBids={salesBids}
                  onWhitelist={onWhitelist}
                  setOnWhitelist={setOnWhitelist}
                />
              )
            )}
            {selectedSale && (
              <TopBiddersComponent
                topBidders={outbidSelected.salesState[selectedSale.value].top_bidders}
                topBiddersInfo={outbidSelected.salesState[selectedSale.value].bidders_info}
                selectedSaleValue={selectedSale.value}
                outbid={outbidSelected}
                walletChainContext={walletChainContext}
              />
            )}
          </div>
          <SaleDetails
            selectedSale={selectedSale}
            outbidSelected={outbidSelected}
            outbidSale={outbidSale}
            onWhitelist={onWhitelist}
            setSaleDocsModalOpen={setSaleDocsModalOpen}
          />
        </div>
      ) : (
        <div>
          <SaleDetails
            selectedSale={selectedSale}
            outbidSelected={outbidSelected}
            outbidSale={outbidSale}
            onWhitelist={onWhitelist}
            setSaleDocsModalOpen={setSaleDocsModalOpen}
          />
          <br />
          {outbidSelected.config.sales_process_ended ? (
            <SaleRedeemSection outbidSale={outbidSelected} selectedSale={selectedSale} salesBids={salesBids} />
          ) : (
            selectedSale && (
              <SaleBidSection
                outbidSale={outbidSelected}
                selectedSale={selectedSale}
                salesBids={salesBids}
                onWhitelist={onWhitelist}
                setOnWhitelist={setOnWhitelist}
              />
            )
          )}
          {selectedSale && (
            <TopBiddersComponent
              topBidders={outbidSelected.salesState[selectedSale.value].top_bidders}
              topBiddersInfo={outbidSelected.salesState[selectedSale.value].bidders_info}
              selectedSaleValue={selectedSale.value}
              outbid={outbidSelected}
            />
          )}
        </div>
      )}
      <SaleDocsModal
        saleDocsModalOpen={saleDocsModalOpen}
        setSaleDocsModalOpen={setSaleDocsModalOpen}
        outbidSelected={outbidSelected}
      />
    </>
  );
};

// create a component that is a bar with multiple sections, each section represents a sale and has the sale status also on the section title as a label, this bar extends the full width of the screen and on its sides have an arrow to navigate to the next or previous sale
interface SalesBarProps {
  salesList: ListItemsProps[];
  selectedSale?: ListItemsProps;
  setSelectedSale: React.Dispatch<React.SetStateAction<ListItemsProps>>;
  outbidSaleSelected: string;
}
const SalesBar: React.FC<SalesBarProps> = ({
  salesList,
  selectedSale,
  setSelectedSale,
  outbidSaleSelected,
}: SalesBarProps) => {
  const { i18 } = useLanguage();
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const isMobileSmBreakpoint = useMediaQuery(responsiveBreakpoints.mobileSm);

  const swiperRef = useRef(null);
  const navigate = useNavigate();
  // initializes as null, after swiper loads up it is set to false, then it triggers the useEffect that will swipe to the end of the swiper
  const [initSwipperPosition, setInitSwipperPosition] = useState(null);

  useEffect(() => {
    if (swiperRef && swiperRef.current && salesList.length > 0 && initSwipperPosition === false) {
      swiperRef.current.swiper.slideTo(salesList.length - 1);
      setInitSwipperPosition(true);
    }
  }, [swiperRef, salesList, initSwipperPosition]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Button
        title={i18("Previous", "dashboard.pools.slider.previous")}
        btnVariant="icon"
        btnColor="dark-medium"
        extraClassName="swiper-button-prev"
        icon={<ChevronLeft />}
      />

      <Swiper
        ref={swiperRef}
        style={{ border: ".1em solid var(--transparent-white)" }}
        slidesPerView={!isMobileBreakpoint ? 4 : isMobileSmBreakpoint ? 1 : 2}
        slidesPerGroup={1}
        spaceBetween={0}
        centeredSlides={false}
        slidesPerGroupSkip={1}
        modules={[Navigation, Pagination]}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
        }}
        onAfterInit={() => setInitSwipperPosition(false)}
      >
        {salesList.map(sale => (
          <SwiperSlide key={sale.id} style={{ backgroundColor: "#272727" }}>
            <CustomInputButton
              type="radio"
              id={sale.id}
              name={sale.label}
              labelText={sale.label}
              onClick={() => {
                setSelectedSale(sale);
                navigate(`/outbid/` + outbidSaleSelected + "/" + sale.id);
              }}
              checked={sale.id === selectedSale?.id}
              fullWidth={true}
              onChange={() => null}
              suffixLabel={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {sale.extraFields.withIncentive ? (
                    <IcnTrophy style={{ fontSize: "1.2em", color: "yellow" }} />
                  ) : sale.extraFields.withBid ? (
                    <IcnTicket style={{ fontSize: "1.2em" }} />
                  ) : null}
                  <span
                    style={{
                      backgroundColor: sale.extraFields.isActive ? "green" : "#ccc",
                      color: sale.extraFields.isActive ? "white" : "black",
                      padding: ".40em",
                      borderRadius: "1em",
                      fontSize: "0.7em",
                      marginLeft: ".5em",
                    }}
                  >
                    {sale.extraFields.isActive
                      ? "live"
                      : sale.extraFields.isEnded
                      ? "ended"
                      : sale.extraFields.isWaiting
                      ? "waiting"
                      : ""}
                  </span>
                </div>
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Button
        title={i18("Next", "dashboard.pools.slider.next")}
        btnVariant="icon"
        btnColor="dark-medium"
        extraClassName="swiper-button-next"
        icon={<ChevronRight />}
      />
    </div>
  );
};

const SaleDetails = ({ selectedSale, outbidSelected, outbidSale, onWhitelist, setSaleDocsModalOpen }: any) => {
  const { i18 } = useLanguage();
  const walletInfo = useAppSelector(selectWalletInfo);
  const isMobileSmBreakpoint = useMediaQuery(responsiveBreakpoints.mobileSm);
  const salesBids = useAppSelector(selectSalesBids);
  const outbidSales = useAppSelector(selectOutbid);
  const assets = useAppSelector(selectAssets);
  const user = useAppSelector(selectUser);
  const nicknames = useAppSelector(selectNicknames);
  const [paginationMyBidsModal, setPaginationMyBidsModal] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [nftModalOpen, setNFTModalOpen] = useState(false);
  const walletChainContext = walletInfo?.connectedChains[outbidSelected.contextChainId];

  const isElegibleForNFT = () => {
    return !!(
      ["EndedSoldOut", "EndedNotSoldOut", "EndedBonusTimeSoldOut"].includes(
        outbidSales[outbidSale].salesState[selectedSale.value].sale_state.status
      ) &&
      outbidSales[outbidSale].salesState[selectedSale.value].top_bidders &&
      outbidSales[outbidSale].salesState[selectedSale.value].top_bidders.find(
        (topBidder: any) => topBidder.address === walletChainContext?.address
      )
    );
  };

  const displayBonusComponent = () => {
    const dispatch = useAppDispatch();
    if (
      !outbidSelected ||
      !selectedSale ||
      "BonusBidding" !== outbidSelected!.salesState[selectedSale!.value].sale_state.status
    )
      return null;

    const remainingTickets = BigNumber(
      outbidSelected!.salesState[selectedSale!.value].config.target_allocation.target_ticket_amount
    )
      .minus(outbidSelected!.salesState[selectedSale!.value].sale_state.state.tickets_count)
      .div(Math.pow(10, 6))
      .decimalPlaces(0)
      .toString(10);

    // useEffect(() => {
    //   if (!nicknames[outbidSelected!.salesState[selectedSale!.value].sale_state.last_bidder_addr]) {
    //     dispatch(
    //       updateNicknamesFromBlockchain({
    //         addresses: [outbidSelected!.salesState[selectedSale!.value].sale_state.last_bidder_addr],
    //       })
    //     );
    //   }
    // }, [outbidSelected!.salesState[selectedSale!.value].sale_state.last_bidder_addr]);

    return (
      <div style={{ backgroundColor: "#d9480b", width: "100%", borderRadius: "1em", padding: "1em" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: ".5em" }}>
          <Star style={{ width: "1.5em", marginRight: "1em" }} />
          <span style={{ fontSize: "1.25em" }}>{"Bonus Time"}</span>
          <Info data-tooltip-id="tooltip_bonusTime" style={{ marginLeft: "auto", width: "1em" }} />
          <Tooltip
            id="tooltip_bonusTime"
            place="bottom"
            style={{ backgroundColor: "var(--dark)", color: "white", zIndex: 3 }}
            render={() => (
              <div>
                <span>{i18("Each new bid restarts the bonus time", "sales.eachNewBidRestartsBonusTime")}</span>
              </div>
            )}
          />
        </div>
        <div style={{ textAlign: "left", marginLeft: "2.5em" }}>
          <span style={{ fontSize: "1em", color: "var(--warm-grey)" }}>
            <NumericFormat
              className="ticketAmount"
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              prefix={
                (!user?.myGlobalSettings?.noDisplayNicknames &&
                nicknames[outbidSelected!.salesState[selectedSale!.value].sale_state.last_bidder_addr]
                  ? noNicknamesCheck(
                      nicknames[outbidSelected!.salesState[selectedSale!.value].sale_state.last_bidder_addr].nickname,
                      user
                    )
                  : "------") + i18(" is holding the ", "sales.isHoldingThe")
              }
              suffix={"💰"}
              value={remainingTickets}
            />
          </span>
        </div>
      </div>
    );
  };

  // do not show individual incentives (Eric Request)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const displayIndividualIncentivesComponent = assets => {
    if (!outbidSelected || !selectedSale) return null;

    const incentives = outbidSelected!.salesState[selectedSale!.value].incentives;
    let nextIncentiveTitle = null;
    let nextIncentiveDetails = null;
    let nextIncentiveNFT = null;
    if (incentives?.specific_bids_incentives) {
      const nextIncentive = incentives.specific_bids_incentives.find(
        (incentive: any) =>
          incentive.bid_id >= outbidSelected!.salesState[selectedSale!.value].sale_state.state.bids_count
      );

      if (
        nextIncentive &&
        !["EndedSoldOut", "EndedNotSoldOut", "EndedBonusTimeSoldOut", "Canceled"].includes(
          outbidSelected!.salesState[selectedSale!.value].sale_state.status
        )
      ) {
        nextIncentiveTitle =
          "Reward: " +
          nextIncentive.incentives
            .map((incentive: any) => {
              if (incentive.asset) {
                const asset =
                  assets[incentive.asset.info.token?.contract_addr || incentive.asset.info.native_token?.denom];
                return `${BigNumber(incentive.asset.amount).div(Math.pow(10, asset.decimals)).toString(10)} ${
                  asset.symbol
                }`;
              }
              if (incentive.nft) {
                nextIncentiveNFT = incentive.nft;
                return `NFT`;
              }
            })
            .join(", ");

        nextIncentiveDetails = `in ${
          nextIncentive.bid_id + 1 - outbidSelected!.salesState[selectedSale!.value].sale_state.state.bids_count
        } bids`;
      }
    }

    const NftRender = ({ nft }: any) => {
      const [nftImage, setNftImage] = useState<string | null>(null);

      useEffect(() => {
        getNftDetails();
      }, []);

      const getNftDetails = async () => {
        const res = await rpcClientQuerySmartContractWrapper(walletChainContext.signingClient, nft.contract_addr, {
          all_nft_info: { token_id: nft.token_id },
        });

        if (res?.info?.extension.image) setNftImage(res.info.extension.image);
      };
      return (
        <div>
          <img src={nftImage} alt={nft.token_id} style={{ width: "25em" }} />
        </div>
      );
    };

    if (!nextIncentiveTitle) return null;
    return (
      <div
        style={{ backgroundColor: "#8B8000", width: "100%", borderRadius: "1em", padding: "1em", marginTop: ".5em" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IcnTrophy style={{ width: "1.5em", marginRight: "1em" }} />
          <div>
            <span style={{ fontSize: "1.25em" }}>{nextIncentiveTitle}</span>
            <br />
            <small>{nextIncentiveDetails}</small>
          </div>
          {nextIncentiveNFT && (
            <>
              <Info data-tooltip-id="tooltip_individualIncentive" style={{ marginLeft: "auto", width: "1em" }} />
              <Tooltip
                className={"opaque"}
                id="tooltip_individualIncentive"
                place="bottom"
                opacity={1}
                style={{ backgroundColor: "var(--dark)", color: "white", zIndex: 3 }}
                render={() => <NftRender nft={nextIncentiveNFT} />}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const displaySoldOutIncentivesComponent = assets => {
    if (!outbidSelected || !selectedSale) return null;

    const incentives = outbidSelected!.salesState[selectedSale!.value].incentives;
    let incentiveTitle = null;
    let incentiveDetails = null;
    let nextIncentiveNFT = null;
    if (
      incentives?.sold_out_bid_incentives &&
      incentives?.sold_out_bid_incentives.length > 0 &&
      !["EndedSoldOut", "EndedNotSoldOut", "EndedBonusTimeSoldOut", "Canceled"].includes(
        outbidSelected!.salesState[selectedSale!.value].sale_state.status
      )
    ) {
      incentiveTitle =
        "Reward: " +
        incentives?.sold_out_bid_incentives
          .map((incentive: any) => {
            if (incentive.asset) {
              const asset =
                assets[incentive.asset.info.token?.contract_addr || incentive.asset.info.native_token?.denom];
              return `${BigNumber(incentive.asset.amount).div(Math.pow(10, asset?.decimals)).toString(10)} ${
                asset?.symbol
              }`;
            }
            if (incentive.nft) {
              nextIncentiveNFT = incentive.nft;
              return `NFT`;
            }
          })
          .join(", ");

      incentiveDetails = `to the bidder that closes out this sale`;
    }

    const NftRender = ({ nft }: any) => {
      const [nftImage, setNftImage] = useState<string | null>(null);

      useEffect(() => {
        getNftDetails();
      }, []);

      const getNftDetails = async () => {
        const res = await rpcClientQuerySmartContractWrapper(walletChainContext.signingClient, nft.contract_addr, {
          all_nft_info: { token_id: nft.token_id },
        });

        if (res?.info?.extension.image) setNftImage(res.info.extension.image);
      };
      return (
        <div>
          <img src={nftImage} alt={"NFT"} style={{ width: "25em" }} />
        </div>
      );
    };

    if (!incentiveTitle) return null;
    return (
      <div
        style={{ backgroundColor: "#8B8000", width: "100%", borderRadius: "1em", padding: "1em", marginTop: ".5em" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IcnTrophy style={{ width: "1.5em", marginRight: "1em" }} />
          <div>
            <span style={{ fontSize: "1.25em" }}>{incentiveTitle}</span>
            <br />
            <small>{incentiveDetails}</small>
          </div>
          {nextIncentiveNFT && (
            <>
              <Info data-tooltip-id="tooltip_individualIncentive" style={{ marginLeft: "auto", width: "1em" }} />
              <Tooltip
                className={"opaque"}
                id="tooltip_individualIncentive"
                place="bottom"
                opacity={1}
                style={{ backgroundColor: "var(--dark)", color: "white", zIndex: 3 }}
                render={() => <NftRender nft={nextIncentiveNFT} />}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const displayExtraMessages = () => {
    const msgs = [];
    if (salesBids && selectedSale && salesBids[selectedSale.value]) {
      if (false === onWhitelist)
        msgs.push(i18("Bid now to secure your VIP spot for the next round(s)", "sales.bidNow"));
    }

    if (
      outbidSelected &&
      selectedSale &&
      "BonusBidding" === outbidSelected!.salesState[selectedSale!.value].sale_state.status
    ) {
      if (outbidSelected!.salesState[selectedSale!.value].sale_state.last_bidder_addr === walletChainContext?.address) {
        msgs.push(i18("You are holding the bag", "sales.holdingBag"));
      } else {
        msgs.push(i18("Bid now to hold the bag, if time is up and not sold out, you win", "sales.bidToHoldBag"));
      }
      msgs.push(i18("Each new bid restarts the bonus time", "sales.eachNewBidRestartsBonusTime"));
    }
    return msgs;
  };

  return (
    <div style={{ flex: 1, paddingLeft: !isMobileSmBreakpoint ? "2em" : "0em" }}>
      {selectedSale && (
        <>
          <StatusComponent
            status={outbidSelected.salesState[selectedSale.value].sale_state.status}
            selectedSaleValue={selectedSale.value}
          />
          <div className="withGradientBorder">
            <div style={{ display: "flex", marginBottom: "1em" }}>
              <TimeLeftComponent selectedSale={selectedSale} outbidSelected={outbidSelected} />
              <Tooltip
                id="tooltip_timeInfo"
                place="bottom"
                style={{ backgroundColor: "var(--dark)", color: "white", zIndex: 3 }}
                render={() => (
                  <div style={{ textAlign: "right" }}>
                    <h3 style={{ marginBottom: ".5em" }}>Sale times</h3>
                    <small>
                      {i18("Start", "sales.start")}:{" "}
                      {new Date(
                        outbidSelected.salesState[selectedSale.value].sale_state.state.start_timestamp * 1000
                      ).toLocaleString()}
                    </small>
                    <br />
                    <small>
                      {i18("VIP end", "sales.vipEnd")}:{" "}
                      {new Date(
                        (outbidSelected.salesState[selectedSale.value].sale_state.state.start_timestamp +
                          outbidSelected.salesState[selectedSale.value].config.sale_settings.whitelist_duration) *
                          1000
                      ).toLocaleString()}
                    </small>{" "}
                    <br />
                    <small>
                      {i18("Bidding end", "sales.biddingEnd")}:{" "}
                      {new Date(
                        (outbidSelected.salesState[selectedSale.value].sale_state.state.end_timestamp -
                          outbidSelected.salesState[selectedSale.value].config.sale_settings.bonus_duration) *
                          1000
                      ).toLocaleString()}
                    </small>
                    {["BonusBidding", "EndedNotSoldOut", "EndedBonusTimeSoldOut"].includes(
                      outbidSales[outbidSale].salesState[selectedSale.value].sale_state.status
                    ) && (
                      <>
                        <br />
                        <small>
                          {i18("Bonus end", "sales.bonusEnd")}:{" "}
                          {new Date(
                            outbidSelected.salesState[selectedSale.value].sale_state.state.end_timestamp * 1000
                          ).toLocaleString()}
                        </small>
                      </>
                    )}
                  </div>
                )}
              />
              <div className="inlineFlexbox" style={{ marginLeft: "auto" }}>
                <div style={{ alignSelf: "center" }}>
                  <div className="inlineFlexbox">
                    {/* <Info data-tooltip-id="tooltip_raised" style={{ width: "16px", height: "16px" }} />
                <Tooltip
                  id="tooltip_raised"
                  place="bottom"
                  style={{ backgroundColor: "var(--dark)", color: "white", zIndex: 3 }}
                  render={() => (
                    <div style={{ textAlign: "right" }}>
                      <h3 style={{ marginBottom: ".5em" }}>Sale {selectedSale.id}</h3>
                    </div>
                  )}
                /> */}
                    <p className="textGrey" style={{ marginBottom: "0px", textAlign: "right" }}>
                      {i18("Current raised", "sales.currentRaised")}
                    </p>
                  </div>
                  <div style={{ fontSize: "1.5em" }}>
                    {abbreviateUSD(
                      BigNumber(outbidSales[outbidSale].salesState[selectedSale.value].sale_state.state.tickets_count)
                        .div(Math.pow(10, 6))
                        .decimalPlaces(6)
                        .toString(10)
                    ) +
                      " of " +
                      abbreviateUSD(
                        BigNumber(
                          outbidSales[outbidSale].salesState[selectedSale.value].config.target_allocation
                            .target_ticket_amount
                        )
                          .div(Math.pow(10, 6))
                          .decimalPlaces(6)
                          .toString(10)
                      )}
                  </div>
                </div>
              </div>
            </div>

            <ProgressBarComponent
              isComplete={["EndedSoldOut", "EndedNotSoldOut", "EndedBonusTimeSoldOut"].includes(
                outbidSales[outbidSale].salesState[selectedSale.value].sale_state.status
              )}
              sale={outbidSales[outbidSale].salesState[selectedSale.value]}
            />

            <div
              style={{
                display: "flex",
                marginTop: "1em",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: ".5em" }}>
                  {isElegibleForNFT()
                    ? i18("Your NFT/tickets on", "sale.yourTicketsAndNFTs")
                    : i18("Your tickets on", "sale.yourTickets")}
                </span>
                {salesBids && salesBids[selectedSale.value] && 0 < salesBids[selectedSale.value].bids.length ? (
                  <SaleMyTicketsModal
                    outbidSale={outbidSelected}
                    selectedSale={selectedSale}
                    paginationMyBidsModal={paginationMyBidsModal}
                    setPaginationMyBidsModal={setPaginationMyBidsModal}
                  />
                ) : (
                  "this sale"
                )}
              </div>

              <span
                style={{
                  backgroundImage: "linear-gradient(92.22deg, #0BF6CC 0.58%, #073DC8 100%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  marginLeft: "auto",
                  // marginRight:
                  //   salesBids && salesBids[selectedSale.value] && 0 < salesBids[selectedSale.value].bids.length
                  //     ? "1em"
                  //     : "0em",
                }}
              >
                {salesBids && salesBids[selectedSale.value]
                  ? BigNumber(salesBids[selectedSale.value].total_final_ticket_amount)
                      .div(Math.pow(10, 6))
                      .toNumber()
                      .toLocaleString("en-US")
                  : "0"}
              </span>

              {/* {salesBids && salesBids[selectedSale.value] && 0 < salesBids[selectedSale.value].bids.length && (
                <SaleMyTicketsModal
                  outbidSale={outbidSelected}
                  selectedSale={selectedSale}
                  paginationMyBidsModal={paginationMyBidsModal}
                  setPaginationMyBidsModal={setPaginationMyBidsModal}
                />
              )} */}

              {
                // show nft modal button if sale has ended and user is on the top bidders list
                isElegibleForNFT() && (
                  <>
                    <Button
                      style={{ marginLeft: "1em" }}
                      title={i18("See your NFTs", "sale.nfts.title")}
                      btnVariant="icon"
                      btnColor="dark-medium"
                      icon={<Award />}
                      onClick={() => {
                        setNFTModalOpen(true);
                      }}
                      btnSize="sm"
                    />
                    <NFTModal
                      NftModalOpen={nftModalOpen}
                      setNFTModalOpen={setNFTModalOpen}
                      saleID={selectedSale.id}
                      topNumber={
                        outbidSales[outbidSale].salesState[selectedSale.value].top_bidders.findIndex(
                          topBidder => topBidder.address === walletChainContext?.address
                        ) + 1
                      }
                      saleAddress={selectedSale.value}
                      outbidSale={outbidSales[outbidSale]}
                    />
                  </>
                )
              }
            </div>
            {displayBonusComponent() && (
              <div
                style={{
                  marginTop: "1em",
                }}
              >
                {displayBonusComponent()}
              </div>
            )}
            {/* do not show individual incentives (Eric Request) */}
            {/*{displayIndividualIncentivesComponent(assets)}*/}
            {displaySoldOutIncentivesComponent(assets)}
            <ActivityMessageHistoryComponent
              sale={outbidSales[outbidSale].salesState[selectedSale.value]}
              chainID={outbidSelected.contextChainId}
            />
            <div
              style={{
                borderTop: "var(--border)",
                marginTop: "1em",
                paddingTop: "1em",
                fontSize: ".85em",
              }}
            >
              {0 < displayExtraMessages().length && (
                <ul style={{ paddingLeft: "var(--px24)" }}>
                  {/* <Info style={{ marginRight: "0.5em", width: "1.5em" }} /> */}
                  {displayExtraMessages().map((msg, i) => (
                    <li key={"displayMsg_" + i}>{msg}</li>
                  ))}
                </ul>
              )}
              <Button
                style={{ padding: ".5em", fontSize: ".80em" }}
                btnColor="gradientText"
                text={i18("More about this sale", "sale.details.moreAboutThisSale")}
                title={i18("More about this sale", "sale.details.moreAboutThisSale")}
                onClick={() => setSaleDocsModalOpen(true)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const TotalRaisedComponent = ({ tickets_count, calcTotalRaised }: any) => {
  const { i18 } = useLanguage();
  const app = useRef<HTMLDivElement>(null);
  const [calcTotalRaisedValue, setCalcTotalRaisedValue] = useState(null);
  const prevAmount = useRef({ calcTotalRaisedValue }).current;

  useEffect(() => {
    setCalcTotalRaisedValue(calcTotalRaised());
  }, [tickets_count]);

  useEffect(() => {
    if (prevAmount.calcTotalRaisedValue !== calcTotalRaisedValue) {
      const ctx = gsap.context(() => {
        gsap.from(`.totalRaised`, 1, {
          ease: "Power3.easeInOut",
          duration: 0.5,
          y: "50%",
          opacity: 0,
        });
      }, app);
      return () => ctx.revert();
    }
  }, [calcTotalRaisedValue]);

  return (
    <div ref={app}>
      <p className="textGrey" style={{ marginBottom: ".5rem" }}>
        {i18("Total raised", "sale.details.totalRaised")}
      </p>

      <CustomNumericInput
        extraClassName="gradientText totalRaised"
        name="totalRaised"
        labelText={i18("Total raised", "sale.details.totalRaised")}
        hiddenLabel={true}
        placeholder={"0"}
        prefix="$"
        value={calcTotalRaisedValue}
        disabled={true}
        inputStyle={{ maxWidth: "8em" }}
      />
    </div>
  );
};

const UserTotalTicketsComponent = ({ outbidSale }: any) => {
  const { i18 } = useLanguage();
  const walletInfo = useAppSelector(selectWalletInfo);
  const salesBids = useAppSelector(selectSalesBids);
  const [totalUserTickets, setTotalUserTickets] = useState(null);
  const app = useRef<HTMLDivElement>(null);
  const prevAmount = useRef({ totalUserTickets }).current;

  useEffect(() => {
    if (totalUserTickets && totalUserTickets !== prevAmount.totalUserTickets) {
      const ctx = gsap.context(() => {
        gsap.from(`.userTotalTickets`, 1, {
          ease: "Power3.easeInOut",
          duration: 0.5,
          y: "50%",
          opacity: 0,
        });
      }, app);
      return () => ctx.revert();
    }
  }, [totalUserTickets]);

  useEffect(() => {
    if (outbidSale) {
      let totalUserTickets = BigNumber(0);
      for (const saleAddr of Object.keys(outbidSale.salesState)) {
        if (salesBids && salesBids[saleAddr]) {
          totalUserTickets = totalUserTickets.plus(salesBids[saleAddr].total_final_ticket_amount);
        }
      }

      setTotalUserTickets(totalUserTickets);
    }
  }, [salesBids]);

  if (false === walletInfo.isConnected) return null;
  return (
    <div ref={app}>
      <p className="textGrey" style={{ marginBottom: ".5rem" }}>
        {i18("Your total tickets", "sale.details.userTotalTickets")}
      </p>
      {totalUserTickets && (
        <CustomNumericInput
          extraClassName="gradientText userTotalTickets"
          name="userTotalTickets"
          labelText={i18("Your total tickets", "sale.details.userTotalTickets")}
          hiddenLabel={true}
          placeholder={"0"}
          // prefix="$"
          value={BigNumber(totalUserTickets).div(Math.pow(10, 6)).decimalPlaces(2).toNumber()}
          disabled={true}
          inputStyle={{ maxWidth: "8em" }}
        />
      )}
    </div>
  );
};

export interface TopBidder {
  rank: string;
  user: string;
  connected: string;
  tickets: string;
  nicknameInfo?: INicknameInfo;
  address: string;
  chainId: string;
}

const TopBiddersComponent = ({ topBidders, topBiddersInfo, selectedSaleValue, outbid, walletChainContext }: any) => {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const walletInfo = useAppSelector(selectWalletInfo);
  const user = useAppSelector(selectUser);
  const nicknames = useAppSelector(selectNicknames);
  const [saleLeaderboardModalOpen, setSaleLeaderboardModalOpen] = useState({
    open: false,
    outbid: null,
    selectedSale: null,
  });
  const [data, setData] = useState<TopBidder[]>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [prevState, setPrevState] = useState({ topBidders, topBiddersInfo });
  const [prevSelectedSaleValue, setPrevSelectedSaleValue] = useState(selectedSaleValue);

  useEffect(() => {
    setPrevState({ topBidders, topBiddersInfo });
    setPrevSelectedSaleValue(selectedSaleValue);
  }, [topBidders, topBiddersInfo, selectedSaleValue, walletInfo]);

  useEffect(() => {
    if (walletInfo.isConnected) {
      const topBiddersWithoutNicknames = [];
      for (const topBidder of topBidders) {
        if (!nicknames[topBidder.address]) {
          topBiddersWithoutNicknames.push(topBidder.address);
        }
      }
      if (0 !== topBiddersWithoutNicknames.length) {
        dispatch(
          updateNicknamesFromBlockchain({
            chainId: walletChainContext.chainState.chainId,
            client: walletChainContext.signingClient,
            addresses: topBiddersWithoutNicknames,
          })
        );
      }
    }
  }, [topBidders, walletInfo]);

  useEffect(() => {
    if (topBidders && topBidders.length > 0 && walletInfo.isConnected) {
      const data = topBidders.map((topBidder, i) => {
        const topBidderNicknameInfo = nicknames[topBidder.address];

        const nickname = `${
          topBidderNicknameInfo?.nickname ? noNicknamesCheck(topBidderNicknameInfo.nickname, user) : "------"
        }`;
        return {
          rank: "" + (i + 1),
          user: nickname,
          nicknameInfo: topBidderNicknameInfo,
          connected: topBidder.address === walletChainContext?.address,
          tickets: BigNumber(topBidder.total_ticket_amount)
            .div(Math.pow(10, 6))
            .decimalPlaces(4)
            .toNumber()
            .toLocaleString("en-US"),
          address: topBidder.address,
          chainId: walletChainContext?.chainState.chainId,
        };
      });
      setData(data);
    }
  }, [topBidders, nicknames, walletInfo, user, walletChainContext]);

  const app = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      selectedSaleValue === prevSelectedSaleValue &&
      JSON.stringify(topBidders) !== JSON.stringify(prevState.topBidders)
    ) {
      const ctx = gsap.context(() => {
        gsap.from(`.topBidders`, 1, {
          ease: "Power3.easeInOut",
          duration: 0.5,
          //y: "50%",
          opacity: 0,
        });
      }, app);
      return () => ctx.revert();
    }
  }, [selectedSaleValue, topBidders]);

  const ticketsColumns: ColumnDef<TopBidder>[] = [
    {
      accessorKey: "rank",
      header: () => <span>{i18("Rank", "sale.details.rank")}</span>,
      cell: ({ row }) => <span style={{ color: "gray" }}>{row.original.rank}</span>,
    },
    {
      id: "message",
      header: () => <span>{i18("User", "sale.details.user")}</span>,
      cell: ({ row }) => (
        <div
          className="inlineFlexbox"
          style={{
            fontWeight: row.original.connected ? "bold" : undefined,
            backgroundImage: row.original.connected
              ? "linear-gradient(92.22deg, #0BF6CC 0.58%, #073DC8 100%)"
              : undefined,
            backgroundClip: row.original.connected ? "text" : undefined,
            WebkitBackgroundClip: row.original.connected ? "text" : undefined,
            color: row.original.connected ? "transparent" : undefined,
          }}
        >
          {row.original.nicknameInfo?.is_protected && <Shield style={{ color: "white", width: "1.5em" }} />}
          {row.original.nicknameInfo?.changed_other_at && <IcnSword style={{ width: "1.4em" }} />}
          {noNicknamesCheck(row.original.user, user)}
          {row.original.chainId && !row.original.nicknameInfo?.is_protected && !row.original.connected && (
            <SaleNicknameTargetModal targetAddress={row.original.address} chainID={row.original.chainId} />
          )}
        </div>
      ),
    },
    {
      id: "tickets",
      header: () => <span style={{ textAlign: "right" }}>{i18("Tickets", "sale.details.tickets")}</span>,
      cell: ({ row }) => <span style={{ textAlign: "right" }}>{row.original.tickets}</span>,
    },
  ];

  return (
    <div ref={app}>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}>
        <h3 style={{ marginBottom: "0em" }}>{i18("Top bidders", "sale.details.topBiddersTitle")}</h3>
        <Button
          title={i18("Leaderboard", "sale.leaderboard")}
          btnColor="dark-medium"
          style={{ marginLeft: "1em", color: "white", padding: "1em" }}
          icon={<BarChart />}
          onClick={() =>
            setSaleLeaderboardModalOpen({
              open: true,
              outbid,
              selectedSale: selectedSaleValue,
            })
          }
        />
        <SaleLeaderboardModal
          saleLeaderboardModalOpen={saleLeaderboardModalOpen}
          setSaleLeaderboardModalOpen={setSaleLeaderboardModalOpen}
        />
      </div>

      <div className="withGradientBorder">
        {0 === topBidders.length ? (
          i18("Top bidders will show here...", "sale.details.topBiddersWillShowHere")
        ) : (
          <DashboardPaginationTable
            tableId="myHistoryTable"
            extraClassName="dashboardHistoryTable topBidders"
            customData={data}
            customColumns={ticketsColumns}
            pagination={pagination}
            setPagination={setPagination}
          />
        )}
      </div>
    </div>
  );
};

const StatusComponent = ({ status, selectedSaleValue }: any) => {
  const { i18 } = useLanguage();
  const app = useRef<HTMLDivElement>(null);
  const prevAmount = useRef({ status, selectedSaleValue }).current;

  const parseStatusText = (statusText: string) => {
    switch (statusText) {
      case "WaitingToStart":
        return i18("Waiting to start", "sale.details.waitingToStart");
      case "WhitelistBidding":
        return i18("VIP Bidding", "sale.details.vipBidding");
      case "Bidding":
        return i18("Bidding", "sale.details.bidding");
      case "BonusBidding":
        return i18("BONUS", "sale.details.bonus");
      case "EndedSoldOut":
        return i18("Sold Out", "sale.details.soldOut");
      case "EndedNotSoldOut":
        return i18("Not Sold Out", "sale.details.notSoldOut");
      case "EndedBonusTimeSoldOut":
        return i18("Bonus Sold Out", "sale.details.bonusSoldOut");
    }
  };

  useEffect(() => {
    if (selectedSaleValue === prevAmount.selectedSaleValue && status !== prevAmount.status) {
      const ctx = gsap.context(() => {
        gsap.from(`.status`, 1, {
          ease: "Power3.easeInOut",
          duration: 0.5,
          y: "50%",
          opacity: 0,
        });
      }, app);
      return () => ctx.revert();
    }
  }, [selectedSaleValue, status]);

  return (
    <div ref={app} style={{ display: "flex" }}>
      <h3 className="status">{i18("Status", "sale.details.status")}:</h3>
      <h3 className={"WaitingToStart" === status ? "statusPulse" : ""} style={{ marginLeft: ".5em" }}>
        {parseStatusText(status)}
      </h3>
    </div>
  );
};

export const ProgressBarComponent = ({ isComplete, sale }: any) => {
  const percentageFill = BigNumber(sale.sale_state.state.tickets_count)
    .div(sale.config.target_allocation.target_ticket_amount)
    .times(100)
    .decimalPlaces(2);

  return (
    <div style={{ width: "100%", backgroundColor: "#57586C", borderRadius: "0.5em" }}>
      <div
        style={{
          height: "1.0em",
          width: percentageFill.gt(0) && percentageFill.lt(5) ? "5%" : percentageFill.toString() + "%",
          borderRadius: "inherit",
          filter: isComplete ? "brightness(0.75)" : undefined,
          background:
            "BonusBidding" === sale?.sale_state?.status
              ? "var(--red-gradient)"
              : "WhitelistBidding" === sale?.sale_state?.status
              ? "var(--green-gradient)"
              : "var(--gradient)",
        }}
      />
    </div>
  );
};

const ActivityMessageHistoryComponent = ({ sale, chainID }: any) => {
  const msgs = sale.activityMessages ?? [];
  const dispatch = useAppDispatch();
  const app = useRef<HTMLDivElement>(null);
  const walletInfo = useAppSelector(selectWalletInfo);
  const walletConnectedChainInfo = walletInfo.connectedChains[chainID];
  const [prevState, setPrevState] = useState({ sale });
  const user = useAppSelector(selectUser);
  const assets = useAppSelector(selectAssets);
  const nicknames = useAppSelector(selectNicknames);

  useEffect(() => {
    setPrevState({ sale });
  }, [sale]);

  useEffect(() => {
    if (walletInfo.isConnected) {
      const msgsWithoutNicknames = [];
      for (const msg of msgs) {
        if (!nicknames[msg.address]) {
          msgsWithoutNicknames.push(msg.address);
        }
      }
      if (0 !== msgsWithoutNicknames.length) {
        dispatch(
          updateNicknamesFromBlockchain({
            chainId: chainID,
            client: walletConnectedChainInfo.signingClient,
            addresses: msgsWithoutNicknames,
          })
        );
      }
    }
  }, [msgs, walletInfo.isConnected]);

  useEffect(() => {
    if (
      sale.activityMessages &&
      0 < sale.activityMessages.length &&
      JSON.stringify(sale.activityMessages[0]) !== JSON.stringify(prevState.sale.activityMessages[0])
    ) {
      const ctx = gsap.context(() => {
        gsap.from(`.newActivity`, 1, {
          ease: "Power3.easeInOut",
          duration: 0.5,
          y: "50%",
          opacity: 0,
        });
      }, app);
      return () => ctx.revert();
    }
  }, [sale]);

  const parseIncentive = incentive => {
    if (incentive.asset) {
      const asset = assets[incentive.asset.info.token?.contract_addr || incentive.asset.info.native_token?.denom];
      if (!asset) return null;
      // return (
      //   <div className="inlineFlexbox">
      //     {BigNumber(incentive.asset.amount).div(Math.pow(10, asset.decimals)).toString(10)}
      //     <span className="visualiserListItemTokenImage">
      //       <img src={imgSanitize(asset.symbol)} alt={asset.symbol} />
      //     </span>
      //   </div>
      // );
      return `${BigNumber(incentive.asset.amount).div(Math.pow(10, asset.decimals)).toString(10)} ${asset.symbol}`;
    }
    if (incentive.nft) {
      return `NFT`;
    }
  };

  if (0 === msgs.length) return null;
  return (
    <div
      ref={app}
      style={{
        marginTop: "1em",
        maxHeight: "15em",
        overflowY: "scroll",
      }}
      className={"custom-scrollbar"}
    >
      {msgs.map((msg, i) => {
        const nickname = `${
          nicknames[msg.address]?.nickname ? noNicknamesCheck(nicknames[msg.address]?.nickname, user) : "------"
        }`;

        return (
          <div
            key={msg.timestamp + "_" + i}
            className={`${
              "topBidder" === msg.type && 0 === i
                ? "topBidderActivityRow"
                : "lastBidder" === msg.type && 0 === i
                ? "bonusActivityRow"
                : ""
            }`}
            style={{
              alignItems: "center",
              display: "flex",
              backgroundColor: "incentive" === msg.type ? "#8B8000" : "rgba(255,255,255,0.1)",
              borderRadius: "1em",
              padding: ".75em",
              marginTop: ".5em",
              marginRight: ".5em",
            }}
          >
            {"topBidder" === msg.type ? (
              <Award style={{ minWidth: "1em", width: "1.5em", marginRight: "1em", marginLeft: "0.25em" }} />
            ) : "lastBidder" === msg.type ? (
              <Heart style={{ minWidth: "1em", width: "1.5em", marginRight: "1em", marginLeft: "0.25em" }} />
            ) : "soldOut" === msg.type ? (
              <CheckCircle style={{ minWidth: "1em", width: "1.5em", marginRight: "1em", marginLeft: "0.25em" }} />
            ) : "incentive" === msg.type ? (
              <IcnTrophy style={{ minWidth: "1em", width: "1.75em", marginRight: "1em", marginLeft: "0.125em" }} />
            ) : null}

            <span style={{ fontSize: ".8em" }}>
              {"topBidder" === msg.type ? (
                `${nickname} ${msg.is_same_position ? "reinforced" : "took"} ${
                  0 === msg.index ? "1st" : 1 === msg.index ? "2nd" : "3rd"
                } place with ${BigNumber(msg.newAmount).div(Math.pow(10, 6)).decimalPlaces(4).toString(10)} tickets`
              ) : "lastBidder" === msg.type ? (
                `${nickname} took the bonus ${BigNumber(msg.newAmount)
                  .div(Math.pow(10, 6))
                  .decimalPlaces(4)
                  .toString(10)} 💰`
              ) : "soldOut" === msg.type ? (
                `${nickname} completed the sale`
              ) : "incentive" === msg.type ? (
                <div>
                  {`${nickname} got a reward: `} {parseIncentive(msg.incentive)}
                </div>
              ) : null}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const TimeLeftComponent = ({ selectedSale, outbidSelected }: any) => {
  const { i18 } = useLanguage();
  const outbidServerTime = useAppSelector(selectOutbidServerTime);
  const [countdownElement, setCountdownElement] = useState(null);

  const [curTime, setCurTime] = useState(new Date().getTime() / 1000);
  const [curVisibilityState, setCurVisibilityState] = useState(true);
  const curTimeRef = useRef(new Date().getTime() / 1000);
  const curVisibilityStateRef = useRef(true);

  useEffect(() => {
    curTimeRef.current = curTime;
  });

  useEffect(() => {
    curVisibilityStateRef.current = curVisibilityState;
  });

  useEffect(() => {
    if (outbidSelected && selectedSale) {
      getCurrentProgressForBar(outbidSelected, selectedSale);
      const interval = setInterval(() => {
        getCurrentProgressForBar(outbidSelected, selectedSale);
        setCurTime(curTimeRef.current + 1);
        if (false === curVisibilityStateRef.current && "visible" == Visibility.state()) {
          setCurTime(new Date().getTime() / 1000);
          setCurVisibilityState(true);
        } else if ("hidden" == Visibility.state()) {
          setCurVisibilityState(false);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [outbidSelected, selectedSale]);

  useEffect(() => {
    if (outbidServerTime) setCurTime(outbidServerTime / 1000);
  }, [outbidServerTime]);

  const getTime = duration => {
    return {
      hours: Math.floor(duration / (60 * 60)),
      minutes: Math.floor((duration / 1 / 60) % 60),
      seconds: Math.floor((duration / 1) % 60),
    };
  };

  const getCurrentProgressForBar = (outbidSale, selectedSale) => {
    const curDate = curTimeRef.current;

    const defaultTimeLeftComponent = (
      <div className="inlineFlexbox">
        <div style={{ alignSelf: "center" }}>
          <div className="inlineFlexbox">
            <Info data-tooltip-id="tooltip_timeInfo" style={{ width: "16px", height: "16px" }} />
            <p className="textGrey" style={{ marginBottom: "0px", textAlign: "right" }}>
              {i18("Time left", "sales.timeLeft")}
            </p>
          </div>
          <div style={{ fontSize: "1.5em" }}>--:--:--</div>
        </div>
      </div>
    );
    if ("WaitingToStart" === outbidSale.salesState[selectedSale.value].sale_state.status) {
      const countdownTime = getTime(
        outbidSale.salesState[selectedSale.value].sale_state.state.start_timestamp - curDate > 0
          ? outbidSale.salesState[selectedSale.value].sale_state.state.start_timestamp - curDate
          : 0
      );
      setCountdownElement(
        <div className="inlineFlexbox">
          <div style={{ alignSelf: "center" }}>
            <div className="inlineFlexbox">
              <Info data-tooltip-id="tooltip_timeInfo" style={{ width: "16px", height: "16px" }} />
              <p className="textGrey" style={{ marginBottom: "0px", textAlign: "right" }}>
                {i18("Time left", "sales.timeLeft")}
              </p>
            </div>
            <div style={{ fontSize: "1.5em" }}>
              {String(countdownTime.hours).padStart(2, "0")}:{String(countdownTime.minutes).padStart(2, "0")}:
              {String(countdownTime.seconds).padStart(2, "0")}
            </div>
          </div>
        </div>
      );
    }
    if ("WhitelistBidding" === outbidSale.salesState[selectedSale.value].sale_state.status) {
      const curTimePast = curDate - outbidSale.salesState[selectedSale.value].sale_state.state.start_timestamp;
      const totalDuration = outbidSale.salesState[selectedSale.value].config.sale_settings.whitelist_duration;
      const countdownTime = getTime(totalDuration - curTimePast > 0 ? totalDuration - curTimePast : 0);
      setCountdownElement(
        <div className="inlineFlexbox">
          <div style={{ alignSelf: "center" }}>
            <div className="inlineFlexbox">
              <Info data-tooltip-id="tooltip_timeInfo" style={{ width: "16px", height: "16px" }} />
              <p className="textGrey" style={{ marginBottom: "0px", textAlign: "right" }}>
                {i18("Time left", "sales.timeLeft")}
              </p>
            </div>
            <div style={{ fontSize: "1.5em" }}>
              {String(countdownTime.hours).padStart(2, "0")}:{String(countdownTime.minutes).padStart(2, "0")}:
              {String(countdownTime.seconds).padStart(2, "0")}
            </div>
          </div>
        </div>
      );
    }
    if ("Bidding" === outbidSale.salesState[selectedSale.value].sale_state.status) {
      const curTimePast =
        curDate -
        (outbidSale.salesState[selectedSale.value].sale_state.state.start_timestamp +
          outbidSale.salesState[selectedSale.value].config.sale_settings.whitelist_duration);
      const totalDuration =
        outbidSale.salesState[selectedSale.value].sale_state.state.start_timestamp +
        outbidSale.salesState[selectedSale.value].config.sale_settings.duration -
        outbidSale.salesState[selectedSale.value].config.sale_settings.bonus_duration -
        (outbidSale.salesState[selectedSale.value].sale_state.state.start_timestamp +
          outbidSale.salesState[selectedSale.value].config.sale_settings.whitelist_duration);
      const countdownTime = getTime(totalDuration - curTimePast > 0 ? totalDuration - curTimePast : 0);
      setCountdownElement(
        <div className="inlineFlexbox ">
          <div style={{ alignSelf: "center" }}>
            <div className="inlineFlexbox">
              <Info data-tooltip-id="tooltip_timeInfo" style={{ width: "16px", height: "16px" }} />
              <p className="textGrey" style={{ marginBottom: "0px", textAlign: "right" }}>
                {i18("Time left", "sales.timeLeft")}
              </p>
            </div>
            <div style={{ fontSize: "1.5em" }}>
              {String(countdownTime.hours).padStart(2, "0")}:{String(countdownTime.minutes).padStart(2, "0")}:
              {String(countdownTime.seconds).padStart(2, "0")}
            </div>
          </div>
        </div>
      );
    }
    if ("BonusBidding" === outbidSale.salesState[selectedSale.value].sale_state.status) {
      const curTimePast =
        curDate -
        (outbidSale.salesState[selectedSale.value].sale_state.state.end_timestamp -
          outbidSale.salesState[selectedSale.value].config.sale_settings.bonus_duration);
      const totalDuration =
        outbidSale.salesState[selectedSale.value].sale_state.state.end_timestamp -
        (outbidSale.salesState[selectedSale.value].sale_state.state.end_timestamp -
          outbidSale.salesState[selectedSale.value].config.sale_settings.bonus_duration);
      const countdownTime = getTime(totalDuration - curTimePast > 0 ? totalDuration - curTimePast : 0);
      setCountdownElement(
        <div className="inlineFlexbox">
          <div style={{ alignSelf: "center" }}>
            <div className="inlineFlexbox">
              <Info data-tooltip-id="tooltip_timeInfo" style={{ width: "1em", height: "1em" }} />
              <p className="textGrey" style={{ marginBottom: "0px", textAlign: "right" }}>
                {i18("Time left", "sales.timeLeft")}
              </p>
            </div>
            <div style={{ fontSize: "1.5em", color: "#f6590b" }}>
              {String(countdownTime.hours).padStart(2, "0")}:{String(countdownTime.minutes).padStart(2, "0")}:
              {String(countdownTime.seconds).padStart(2, "0")}
            </div>
          </div>
        </div>
      );
    }
    if (
      ["EndedSoldOut", "EndedNotSoldOut", "EndedBonusTimeSoldOut"].includes(
        outbidSale.salesState[selectedSale.value].sale_state.status
      )
    ) {
      setCountdownElement(defaultTimeLeftComponent);
    }
  };

  return countdownElement;
};

export default Sales;
