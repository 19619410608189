import BigNumber from "bignumber.js";
import { Coin } from "@cosmjs/stargate";
import { normalize_amount, toUSDAmount } from "@axvdex/utils/formatNumber";
// import checkDerivativeMintOrTrade from "@axvdex/utils/swapScripts/checkDerivativeMintOrTrade";
import { IWalletConnectedChainInfo } from "@axvdex/state/wallet/initialState";
import { IAsset, IContract, IPool } from "../interfaces";
import { div, minus, plus, times } from "../math";
import { getAllPaths } from "../swapScripts/depthFirstSearchPathCalc";
import { cashbackToAXV } from "../swapScripts/simulateCashback";
import simulateSwaps from "../swapScripts/simulateSwaps";

const simulateSwap = (
  walletChainContext: IWalletConnectedChainInfo | undefined,
  allPools: {
    [key: string]: IPool;
  },
  allAssets: {
    [key: string]: IAsset;
  },
  allContracts: {
    [key: string]: IContract;
  },
  estimatedFeesReference: {
    estimatedFee: Coin[];
    gasLimit: number;
  },
  hops: {
    [assetX: string]: {
      [assetY: string]: string;
    };
  },
  offerAsset: IAsset,
  offerAssetAmountRaw: string | null,
  askAsset: IAsset,
  slippageTolerance: number,
  maxHops?: number
): any => {
  if (!offerAssetAmountRaw) {
    throw new Error("No offerAssetAmount specified");
  }

  // ignoreMinimumReceiveAmount variable to keep track of amounts that dont have slippage or fees (ex. mint xAssets or stable swap from native => xAsset)
  const offerAssetAmount = BigInt(
    BigNumber(offerAssetAmountRaw).times(Math.pow(10, allAssets[offerAsset.id].decimals)).decimalPlaces(0).toString(10)
  );
  const askAssetAmount = null;

  // find cashback and cashbackMinter on the allContracts object
  const cashback = allContracts[`cashback_` + allAssets[offerAsset.id].contextChainId];
  const cashbackMinter = allContracts[`cashback-minter_` + allAssets[offerAsset.id].contextChainId];

  const allPathsSwaps: any = [];
  // 2nd step: go thought all hops and add hops to the end that connect, at the end forming all possible paths starting from a specific hop
  // when user inputs on the top input field (offerAssetAmount has value and askAssetAmount is null)
  if (offerAssetAmount && !askAssetAmount) {
    const allPaths = getAllPaths(hops, offerAsset.id, askAsset.id).slice(0, 10); // only get the shortest 10 routes

    // 3rd step: simulate swaps thought all the paths coordinating to the most recent data of pools available
    for (const path of allPaths) {
      const simulatedSwap = simulateSwaps(
        allPools,
        allContracts,
        cashbackMinter,
        allAssets,
        estimatedFeesReference,
        cashback,
        offerAssetAmount,
        path
      );

      allPathsSwaps.push(simulatedSwap);
    }
  }

  const errorResponse = {
    offerAmount: offerAssetAmountRaw,
    offerAmountInUSD: BigNumber(offerAssetAmountRaw).times(offerAsset.price).toNumber(),
    askAmount: "0",
    askAmountInUSD: "0",
    offerToAskRatio: "0",
    askToOfferRatio: "0",
    error: {
      type: "swap",
      message: "No route found...",
      data: null,
    },
    tx: null,
  };
  if (allPathsSwaps.length === 0) {
    return errorResponse;
  }

  // sort all paths by the amount of askAmount, so the 1st one will be the BEST route
  if (offerAssetAmount && !askAssetAmount) {
    allPathsSwaps.sort((a: any, b: any) =>
      BigNumber(b[b.length - 1].estimatedFinalAskAmountUSD_withGasFee).minus(
        a[a.length - 1].estimatedFinalAskAmountUSD_withGasFee
      )
    );
  }

  // parse the best route to the result object used to fill all fields on the frontend
  let indexOfRoute = 0;
  if (maxHops) {
    indexOfRoute = allPathsSwaps.findIndex((route: any) => route.length <= maxHops);
  }
  const bestRoute = allPathsSwaps[indexOfRoute];

  const offerAmount = offerAssetAmount ? offerAssetAmount : bestRoute[0].offerAmount;
  const offerAssetPoolIndex = allPools[bestRoute[0].p].poolAssets.findIndex((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === bestRoute[0].x;
    }
    return asset.info.native_token.denom === bestRoute[0].x;
  });
  const offerAmountInUSD = toUSDAmount(
    allAssets[bestRoute[0].x].price!,
    offerAmount,
    allPools[bestRoute[0].p].assetDecimals[offerAssetPoolIndex]
  );
  const askAmount = askAssetAmount ? askAssetAmount : bestRoute[bestRoute.length - 1].askAmount;
  const askAssetPoolIndex = allPools[bestRoute[bestRoute.length - 1].p].poolAssets.findIndex((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === bestRoute[bestRoute.length - 1].y;
    }
    return asset.info.native_token.denom === bestRoute[bestRoute.length - 1].y;
  });
  const askAmountInUSD = toUSDAmount(
    allAssets[bestRoute[bestRoute.length - 1].y].price!,
    askAmount,
    allPools[bestRoute[bestRoute.length - 1].p].assetDecimals[askAssetPoolIndex]
  );
  const offerAmountInputFormated = div(offerAmount.toString(), Math.pow(10, allAssets[offerAsset.id].decimals));
  const askAmountInputFormated = div(askAmount.toString(), Math.pow(10, allAssets[askAsset.id].decimals));

  const minimumReceived = bestRoute[bestRoute.length - 1].ignoreMinimumReceiveAmount
    ? offerAmountInputFormated
    : minus(askAmountInputFormated, div(times(askAmountInputFormated, slippageTolerance), 100));

  // NOTE: This is irrelevant now because it will always be a trade
  // only if route is 1 hop, and it's a mint, then minimumReceived = askAmount
  // if (bestRoute.length === 1 && bestRoute[0].derivativeOperation === "mint") {
  //   minimumReceived = askAmountInputFormated;
  // }

  const minimumReceivedInUSD = toUSDAmount(
    allAssets[bestRoute[bestRoute.length - 1].y].price!,
    BigInt(
      BigNumber(minimumReceived)
        .times(Math.pow(10, allAssets[askAsset.id].decimals))
        .decimalPlaces(0, BigNumber.ROUND_FLOOR)
        .toString(10)
    ),
    allPools[bestRoute[bestRoute.length - 1].p].assetDecimals[askAssetPoolIndex]
  );

  let feePer = "0";
  let feeInUSD = 0;
  let expectedCashbackAmount = "0";
  for (const hop of bestRoute) {
    feePer = BigNumber(feePer)
      .plus(BigNumber(hop.feeAmount).div(BigNumber(hop.askAmount).plus(hop.feeAmount)).times(100))
      .decimalPlaces(2)
      .toString();
    feeInUSD += toUSDAmount(allAssets[hop.y].price!, hop.feeAmount, allAssets[hop.y].decimals);
    expectedCashbackAmount = plus(expectedCashbackAmount, div(hop.expectedCashbackMinted.toString(), Math.pow(10, 6)));
  }

  const cashbackAXVAmount = cashbackToAXV(cashback, BigInt(parseInt(times(expectedCashbackAmount, Math.pow(10, 6)))));

  const expectedCashbackInUSD =
    cashback && cashback.extraFields.reward_source
      ? toUSDAmount(allAssets[cashback.extraFields.reward_source].price!, cashbackAXVAmount, cashback.config.decimals)
      : 0;

  const inputDollarAmount = BigNumber(bestRoute[0].offerAmount)
    .div(Math.pow(10, allAssets[bestRoute[0].x].decimals))
    .times(allAssets[bestRoute[0].x].price);
  const outputDollarAmount = BigNumber(bestRoute[bestRoute.length - 1].askAmount)
    .div(Math.pow(10, allAssets[bestRoute[bestRoute.length - 1].y].decimals))
    .times(allAssets[bestRoute[bestRoute.length - 1].y].price);
  const feeDollarAmount = BigNumber(feeInUSD);
  const marketImpactPer = inputDollarAmount
    .div(outputDollarAmount.plus(feeDollarAmount))
    .minus(1)
    .times(100)
    .toString();

  // price impact calc
  // To calc priceImpact need to calc a swap of the same assets on the same route with a small amount
  // then compare the small ratio with cur ratio
  // 1$ worth of offer asset
  const smallOfferAmount = BigInt(
    BigNumber(1)
      .div(allAssets[bestRoute[0].x].price)
      .times(Math.pow(10, allAssets[bestRoute[0].x].decimals))
      .decimalPlaces(0)
      .toString(10)
  );

  const simulatedSwapSmallAmount = simulateSwaps(
    allPools,
    allContracts,
    cashbackMinter,
    allAssets,
    estimatedFeesReference,
    cashback,
    smallOfferAmount,
    bestRoute.map(bestRouteHop => {
      return {
        x: bestRouteHop.x,
        y: bestRouteHop.y,
        p: bestRouteHop.p,
      };
    })
  );
  const smallRatio = BigNumber(
    normalize_amount(
      simulatedSwapSmallAmount[simulatedSwapSmallAmount.length - 1].askAmount,
      allAssets[simulatedSwapSmallAmount[simulatedSwapSmallAmount.length - 1].y].decimals
    ).toString(10)
  ).div(
    BigNumber(
      normalize_amount(
        simulatedSwapSmallAmount[0].offerAmount,
        allAssets[simulatedSwapSmallAmount[0].x].decimals
      ).toString(10)
    )
  );

  const swapRatio = BigNumber(
    normalize_amount(
      bestRoute[bestRoute.length - 1].askAmount,
      allAssets[bestRoute[bestRoute.length - 1].y].decimals
    ).toString(10)
  ).div(BigNumber(normalize_amount(bestRoute[0].offerAmount, allAssets[bestRoute[0].x].decimals).toString(10)));

  const priceImpact = smallRatio.div(swapRatio).minus(1).times(100);
  const priceImpactPer = priceImpact.lt(0) ? "0" : priceImpact.toString(10);

  // create the TX to execute
  const tx = {
    contract: {
      toExecuteContract: null,
      msgBody: null,
      funds: bestRoute.length > 0 ? bestRoute[0].funds || [] : [],
    },
  };

  if (bestRoute.length === 0) {
    return errorResponse;
  } else if (bestRoute.length === 1) {
    const route = bestRoute[0];
    if (route.hopTx.mint_staking_derivative) {
      tx.contract.toExecuteContract = route.hopTx.mint_staking_derivative.contract_addr;
      tx.contract.msgBody = {
        mint_derivative: {},
      };
    } else if (route.hopTx.standard_hop_info) {
      if (allAssets[route.x].isNative) {
        tx.contract.toExecuteContract = route.p;
        tx.contract.msgBody = {
          swap: {
            offer_asset: {
              info: allPools[route.p].poolAssets[route.hopTx.standard_hop_info.from_asset_index].info,
              amount: route.offerAmount.toString(),
            },
            expected_return: BigNumber(minimumReceived)
              .times(Math.pow(10, allAssets[route.y].decimals))
              .decimalPlaces(0, BigNumber.ROUND_FLOOR)
              .toString(10),
          },
        };
      } else {
        tx.contract.toExecuteContract = route.x;
        tx.contract.msgBody = {
          send: {
            contract: route.p,
            amount: route.offerAmount.toString(),
            msg: Buffer.from(
              JSON.stringify({
                swap: {
                  expected_return: BigNumber(minimumReceived)
                    .times(Math.pow(10, allAssets[route.y].decimals))
                    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                    .toString(10),
                },
              })
            ).toString("base64"),
          },
        };
      }
    } else if (route.hopTx.stable_hop_info) {
      if (allAssets[route.x].isNative) {
        tx.contract.toExecuteContract = route.p;
        tx.contract.msgBody = {
          swap: {
            swap_to_asset_index: route.hopTx.stable_hop_info.to_asset_index,
            expected_return: BigNumber(minimumReceived)
              .times(Math.pow(10, allAssets[route.y].decimals))
              .decimalPlaces(0, BigNumber.ROUND_FLOOR)
              .toString(10),
          },
        };
      } else {
        tx.contract.toExecuteContract = route.x;
        tx.contract.msgBody = {
          send: {
            contract: route.p,
            amount: route.offerAmount.toString(),
            msg: Buffer.from(
              JSON.stringify({
                swap: {
                  swap_to_asset_index: route.hopTx.stable_hop_info.to_asset_index,
                  expected_return: BigNumber(minimumReceived)
                    .times(Math.pow(10, allAssets[route.y].decimals))
                    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                    .toString(10),
                },
              })
            ).toString("base64"),
          },
        };
      }
    } else if (route.hopTx.ratio_hop_info) {
      if (allAssets[route.x].isNative) {
        tx.contract.toExecuteContract = route.p;
        tx.contract.msgBody = {
          swap: {
            expected_return: BigNumber(minimumReceived)
              .times(Math.pow(10, allAssets[route.y].decimals))
              .decimalPlaces(0, BigNumber.ROUND_FLOOR)
              .toString(10),
          },
        };
      } else {
        tx.contract.toExecuteContract = route.x;
        tx.contract.msgBody = {
          send: {
            contract: route.p,
            amount: route.offerAmount.toString(),
            msg: Buffer.from(
              JSON.stringify({
                swap: {
                  expected_return: BigNumber(minimumReceived)
                    .times(Math.pow(10, allAssets[route.y].decimals))
                    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                    .toString(10),
                },
              })
            ).toString("base64"),
          },
        };
      }
    } else {
      return errorResponse;
    }
  } else {
    const router = allContracts[`router_${allAssets[offerAsset.id].contextChainId}`];
    if (allAssets[bestRoute[0].x].isNative) {
      tx.contract.toExecuteContract = router.address;
      tx.contract.msgBody = {
        receive: {
          sender: walletChainContext?.address, // undefined if wallet is not connected
          amount: bestRoute[0].offerAmount.toString(),
          msg: Buffer.from(
            JSON.stringify({
              route_v2: {
                hops: bestRoute.map(r => r.hopTx),
                minimum_receive: BigNumber(minimumReceived)
                  .times(Math.pow(10, allAssets[bestRoute[bestRoute?.length - 1].y].decimals))
                  .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                  .toString(10),
              },
            })
          ).toString("base64"),
        },
      };
    } else {
      tx.contract.toExecuteContract = bestRoute[0].x;
      tx.contract.msgBody = {
        send: {
          contract: router.address,
          amount: bestRoute[0].offerAmount.toString(),
          msg: Buffer.from(
            JSON.stringify({
              route_v2: {
                hops: bestRoute.map(r => r.hopTx),
                minimum_receive: BigNumber(minimumReceived)
                  .times(Math.pow(10, allAssets[bestRoute[bestRoute.length - 1].y].decimals))
                  .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                  .toString(10),
              },
            })
          ).toString("base64"),
        },
      };
    }
  }

  return {
    offerAmount: offerAmountInputFormated,
    offerAmountInUSD,
    askAmount: askAmountInputFormated,
    askAmountInUSD,
    minimumReceived,
    minimumReceivedInUSD,
    offerToAskRatio: offerAmountInputFormated !== "0" ? div(askAmountInputFormated, offerAmountInputFormated) : "-",
    askToOfferRatio: askAmountInputFormated !== "0" ? div(offerAmountInputFormated, askAmountInputFormated) : "-",
    feePer,
    feeInUSD,
    expectedCashbackAmount,
    expectedCashbackAXVAmount:
      cashback && cashback.extraFields.reward_source
        ? div(cashbackAXVAmount.toString(), Math.pow(10, allAssets[cashback.extraFields.reward_source].decimals))
        : "0",
    expectedCashbackInUSD,
    marketImpactPer: marketImpactPer !== "-" ? parseFloat(marketImpactPer).toFixed(2) : marketImpactPer,
    priceImpactPer: priceImpactPer ? parseFloat(priceImpactPer).toFixed(2) : "-",
    route: bestRoute,
    tx,
  };
};

export default simulateSwap;
