import Select from "react-select";
import clsx from "clsx";
import { Plus, Save, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { hasPermitStored } from "@axvdex/utils/localStorage";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { selectChains, selectGlobalSettings, selectUser, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import useLanguage from "@axvdex/hooks/useLanguage";
import { IAssetTemplate } from "@axvdex/utils/interfaces";
import { updateUserGlobalSettingsFields } from "@axvdex/state/wallet/walletThunks";
import { AssetChainLogo } from "@axvdex/utils/chains";
import imgSanitize from "@axvdex/utils/imgSanitize";
import { setBackgroundTemporaryState } from "@axvdex/state/wallet/walletSlice";
import Button from "../common/Button";
import styles from "../../styles/CustomSelect.module.scss";
import { useMediaQuery } from "usehooks-ts";
import { responsiveBreakpoints } from "@axvdex/constants";
//import CustomSelect from "../form-element/CustomSelect";

export default function TemplateContainer({ tradeInfo, isTransactionStarts }: any) {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobileSm);
  const user = useAppSelector(selectUser);
  const chains = useAppSelector(selectChains);
  const walletInfo = useAppSelector(selectWalletInfo);
  const globalSettings = useAppSelector(selectGlobalSettings);

  const navigate = useNavigate();

  const buildLabel = template => {
    // label format depends on the action being done:
    if (!chains[template.fromAssetChain] || !chains[template.toAssetChain]) return null;
    return (
      <>
        {
          // Same symbol different chains is a Move
          template.fromAssetSymbol === template.toAssetSymbol && template.fromAssetChain !== template.toAssetChain && (
            <>
              <div style={{ display: "flex", justifyContent: "center", marginTop: ".25em" }}>
                {`Move ${template.amount} `}
                <img
                  style={{
                    marginLeft: ".5em",
                    marginRight: ".5em",
                    flexShrink: 0,
                    display: "inline-flex",
                    width: "var(--px24)",
                    height: "var(--px24)",
                  }}
                  src={imgSanitize(template.fromAssetSymbol)}
                  alt={`${template.amount} ${template.fromAssetSymbol}`}
                  title={template.fromAssetSymbol}
                />
                {`from`}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ marginRight: ".5em" }}>{chains[template.fromAssetChain].displayName}</span>
                <AssetChainLogo
                  chain={chains[template.fromAssetChain]}
                  noText={true}
                  imgStyle={{ marginRight: ".5em" }}
                />
                <span style={{ marginRight: ".5em" }}>
                  {" to "}
                  {chains[template.toAssetChain].displayName}
                </span>
                <AssetChainLogo chain={chains[template.toAssetChain]} noText={true} />
              </div>
            </>
          )
        }
        {
          // different symbols on different chains or different symbols on the same chain is a Trade
          ((template.fromAssetSymbol !== template.toAssetSymbol && template.fromAssetChain !== template.toAssetChain) ||
            (template.fromAssetSymbol !== template.toAssetSymbol &&
              template.fromAssetChain === template.toAssetChain)) && (
            <>
              <div style={{ display: "flex", justifyContent: "center", marginTop: ".25em" }}>
                {`Trade ${template.amount} `}
                <img
                  style={{
                    marginLeft: ".5em",
                    marginRight: ".5em",
                    flexShrink: 0,
                    display: "inline-flex",
                    width: "var(--px24)",
                    height: "var(--px24)",
                  }}
                  src={imgSanitize(template.fromAssetSymbol)}
                  alt={`${template.amount} ${template.fromAssetSymbol}`}
                  title={template.fromAssetSymbol}
                />
                {`to `}
                <img
                  style={{
                    marginLeft: ".5em",
                    marginRight: ".5em",
                    flexShrink: 0,
                    display: "inline-flex",
                    width: "var(--px24)",
                    height: "var(--px24)",
                  }}
                  src={imgSanitize(template.toAssetSymbol)}
                  alt={`${template.toAssetSymbol}`}
                  title={template.toAssetSymbol}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {template.fromAssetChain !== template.toAssetChain ? (
                  <>
                    <span style={{ marginRight: ".5em" }}>{chains[template.fromAssetChain].displayName}</span>
                    <AssetChainLogo
                      chain={chains[template.fromAssetChain]}
                      noText={true}
                      imgStyle={{ marginRight: ".5em" }}
                    />
                    <span style={{ marginRight: ".5em" }}>
                      {" -> "}
                      {chains[template.toAssetChain].displayName}
                    </span>
                    <AssetChainLogo chain={chains[template.toAssetChain]} noText={true} />
                  </>
                ) : (
                  <>
                    <span style={{ marginRight: ".5em" }}>{chains[template.fromAssetChain].displayName}</span>
                    <AssetChainLogo
                      chain={chains[template.fromAssetChain]}
                      noText={true}
                      imgStyle={{ marginRight: ".5em" }}
                    />
                  </>
                )}
              </div>
            </>
          )
        }
        {
          // sma symbols, same chain is a Send
          template.fromAssetSymbol === template.toAssetSymbol && template.fromAssetChain === template.toAssetChain && (
            <div style={{ display: "flex", justifyContent: "center", marginTop: ".25em" }}>
              {`Send ${template.amount} `}
              <img
                style={{
                  marginLeft: ".5em",
                  marginRight: ".5em",
                  flexShrink: 0,
                  display: "inline-flex",
                  width: "var(--px24)",
                  height: "var(--px24)",
                }}
                src={imgSanitize(template.fromAssetSymbol)}
                alt={`${template.amount} ${template.fromAssetSymbol}`}
                title={template.fromAssetSymbol}
              />
              {`on `}
              <span style={{ marginLeft: ".5em", marginRight: ".5em" }}>
                {chains[template.fromAssetChain].displayName}
              </span>
              <AssetChainLogo
                chain={chains[template.fromAssetChain]}
                noText={true}
                imgStyle={{ marginRight: ".5em" }}
              />
            </div>
          )
        }
      </>
    );
  };

  const defaultTemplates =
    process.env.REACT_APP_MODE === "LOCAL"
      ? [
          {
            label: "ATOM (Cosmoshub) -> ATOM (Archway)",
            value: {
              fromAssetSymbol: "ATOM",
              fromAssetChain: "cosmos-local",
              toAssetSymbol: "ATOM",
              toAssetChain: "arch-local",
              amount: "10",
              creationTimestamp: null,
              updatedTimestamp: null,
            },
          },
          {
            label: "ARCH (Archway) -> AXV (Archway)",
            value: {
              fromAssetSymbol: "ARCH",
              fromAssetChain: "arch-local",
              toAssetSymbol: "AXV",
              toAssetChain: "arch-local",
              amount: "10",
              creationTimestamp: null,
              updatedTimestamp: null,
            },
          },
        ]
      : process.env.REACT_APP_MODE === "TESTNET"
      ? [
          {
            label: buildLabel({
              fromAssetSymbol: "ATOM",
              fromAssetChain: "theta-testnet-001",
              toAssetSymbol: "ATOM",
              toAssetChain: "pion-1",
              amount: "10",
              updatedTimestamp: null,
            }),
            value: {
              fromAssetSymbol: "ATOM",
              fromAssetChain: "theta-testnet-001",
              toAssetSymbol: "ATOM",
              toAssetChain: "pion-1",
              amount: "10",
              creationTimestamp: null,
              updatedTimestamp: null,
            },
          },
          {
            label: buildLabel({
              fromAssetSymbol: "CONST",
              fromAssetChain: "constantine-3",
              toAssetSymbol: "NTRN",
              toAssetChain: "pion-1",
              amount: "10",
              updatedTimestamp: null,
            }),
            value: {
              fromAssetSymbol: "CONST",
              fromAssetChain: "constantine-3",
              toAssetSymbol: "NTRN",
              toAssetChain: "pion-1",
              amount: "10",
              creationTimestamp: null,
              updatedTimestamp: null,
            },
          },
        ]
      : [
          {
            label: buildLabel({
              fromAssetSymbol: "ATOM",
              fromAssetChain: "cosmoshub-4",
              toAssetSymbol: "ATOM",
              toAssetChain: "neutron-1",
              amount: "10",
              updatedTimestamp: null,
            }),
            value: {
              fromAssetSymbol: "ATOM",
              fromAssetChain: "cosmoshub-4",
              toAssetSymbol: "ATOM",
              toAssetChain: "neutron-1",
              amount: "10",
              creationTimestamp: null,
              updatedTimestamp: null,
            },
          },
          {
            label: buildLabel({
              fromAssetSymbol: "ARCH",
              fromAssetChain: "archway-1",
              toAssetSymbol: "NTRN",
              toAssetChain: "neutron-1",
              amount: "10",
              updatedTimestamp: null,
            }),
            value: {
              fromAssetSymbol: "ARCH",
              fromAssetChain: "archway-1",
              toAssetSymbol: "NTRN",
              toAssetChain: "neutron-1",
              amount: "10",
              creationTimestamp: null,
              updatedTimestamp: null,
            },
          },
        ];

  const [options, setOptions] = useState<{ label: any; value: IAssetTemplate }[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedTemplate, setSelectedTemplate] = useState<{ label: string; value: number } | null>(null);

  useEffect(() => {
    if (chains && Object.keys(chains).length > 0 && globalSettings?.assetTemplates) {
      setOptions([
        ...globalSettings.assetTemplates.map(template => ({
          label: buildLabel(template),
          value: template,
        })),
      ]);
    } else if (chains && Object.keys(chains).length > 0 && !globalSettings?.assetTemplates)
      setOptions(defaultTemplates);
  }, [chains, globalSettings]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const formatOptionLabel = ({ label, value }) => label;

  const isTemplateSelected = () => {
    return options
      .map(option => option.value)
      .some(
        template =>
          tradeInfo.fromAsset.symbol === template.fromAssetSymbol &&
          tradeInfo.fromAsset.contextChainId === template.fromAssetChain &&
          tradeInfo.toAsset.symbol === template.toAssetSymbol &&
          tradeInfo.toAsset.contextChainId === template.toAssetChain
      );
  };

  if (!chains || Object.keys(chains).length === 0 || !user?.isUserDetailsLoad) return null;
  return (
    <div
      style={{
        display: "flex",
        marginBottom: ".5em",
        flexDirection: isMobileBreakpoint ? "column-reverse" : undefined,
        width: isMobileBreakpoint ? "100%" : undefined,
      }}
    >
      <Select
        classNamePrefix="customSelect"
        className={clsx(
          styles.customSelect,
          "customSelect",
          !isMobileBreakpoint ? "customSelectMinWidthTemplate" : undefined
        )}
        placeholder="My quick tasks"
        onChange={e => {
          const { fromAssetSymbol, fromAssetChain, toAssetSymbol, toAssetChain, amount } = e.value;
          navigate(
            `/trade?from=${fromAssetSymbol}&fromChain=${fromAssetChain}&to=${toAssetSymbol}&toChain=${toAssetChain}&amount=${amount}`
          );
          setSelectedTemplate(e);
          // selecting template will clear the temporary state
          dispatch(
            setBackgroundTemporaryState({
              type: "crosschainSwap",
              data: null,
            })
          );
        }}
        isSearchable={false}
        value={null}
        options={options}
        formatOptionLabel={formatOptionLabel}
        isOptionSelected={option => {
          return (
            tradeInfo.fromAsset.symbol === option.value.fromAssetSymbol &&
            tradeInfo.fromAsset.contextChainId === option.value.fromAssetChain &&
            tradeInfo.toAsset.symbol === option.value.toAssetSymbol &&
            tradeInfo.toAsset.contextChainId === option.value.toAssetChain
          );
        }}
        isDisabled={isTransactionStarts}
      />
      <div style={{ textAlign: "right" }}>
        {hasPermitStored(walletInfo) && isTemplateSelected() && (
          <Button
            title={i18("Update quick task", "template.save.title")}
            btnVariant="icon"
            btnColor="dark-medium"
            style={{ marginLeft: ".5em", marginRight: "0.5em", marginBottom: isMobileBreakpoint ? ".5em" : 0 }}
            icon={<Save />}
            onClick={() => {
              const updatedOptions = options.map(option => {
                const template = option.value;
                if (
                  tradeInfo.fromAsset.symbol === template.fromAssetSymbol &&
                  tradeInfo.fromAsset.contextChainId === template.fromAssetChain &&
                  tradeInfo.toAsset.symbol === template.toAssetSymbol &&
                  tradeInfo.toAsset.contextChainId === template.toAssetChain
                ) {
                  return {
                    label: buildLabel({
                      ...template,
                      amount: tradeInfo.fromFieldValue.toString(),
                      updatedTimestamp: new Date().getTime(),
                    }),
                    value: {
                      ...template,
                      amount: tradeInfo.fromFieldValue.toString(),
                      updatedTimestamp: new Date().getTime(),
                    },
                  };
                }
                return option;
              });
              dispatch(
                updateUserGlobalSettingsFields({
                  assetTemplates: updatedOptions.map(option => option.value),
                })
              );
              setOptions(updatedOptions);
            }}
          />
        )}
        {hasPermitStored(walletInfo) && isTemplateSelected() && (
          <Button
            title={i18("Remove quick task", "template.delete.title")}
            btnVariant="icon"
            btnColor="dark-medium"
            icon={<Trash />}
            onClick={() => {
              // remove template
              const updatedOptions = options.filter(option => {
                const template = option.value;
                return !(
                  tradeInfo.fromAsset.symbol === template.fromAssetSymbol &&
                  tradeInfo.fromAsset.contextChainId === template.fromAssetChain &&
                  tradeInfo.toAsset.symbol === template.toAssetSymbol &&
                  tradeInfo.toAsset.contextChainId === template.toAssetChain
                );
              });
              dispatch(
                updateUserGlobalSettingsFields({
                  assetTemplates: updatedOptions.map(option => option.value),
                })
              );
              setOptions(updatedOptions);
            }}
          />
        )}
        {hasPermitStored(walletInfo) && !isTemplateSelected() && options.length < 10 && (
          <Button
            title={i18("Add to my quick tasks", "template.add.title")}
            btnVariant="icon"
            btnColor="dark-medium"
            style={{ marginLeft: ".5em", marginBottom: isMobileBreakpoint ? ".5em" : 0 }}
            icon={<Plus />}
            onClick={() => {
              const assetTemplate = {
                fromAssetSymbol: tradeInfo.fromAsset.symbol,
                fromAssetChain: tradeInfo.fromAsset.contextChainId,
                toAssetSymbol: tradeInfo.toAsset.symbol,
                toAssetChain: tradeInfo.toAsset.contextChainId,
                amount: tradeInfo.fromFieldValue.toString(),
                creationTimestamp: new Date().getTime(),
                updatedTimestamp: new Date().getTime(),
              };
              setOptions([
                ...options,
                {
                  label: buildLabel(assetTemplate),
                  value: assetTemplate,
                },
              ]);
              dispatch(
                updateUserGlobalSettingsFields({
                  assetTemplates: globalSettings.assetTemplates
                    ? [...globalSettings.assetTemplates, assetTemplate]
                    : [...defaultTemplates.map(template => template.value), assetTemplate],
                })
              );
            }}
          />
        )}
      </div>
    </div>
  );
}

// text format: Action: symbols
