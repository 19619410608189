import { useEffect, useState } from "react";
import clsx from "clsx";

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import BigNumber from "bignumber.js";
import { useMediaQuery } from "usehooks-ts";
import { Circle, Link, Package, Star } from "react-feather";
import { abbreviateNumber, abbreviateUSD, formatBalance } from "@axvdex/utils/formatNumber";
import {
  calcLockup,
  hybridLPTokenRepresentation,
  standardLPTokenRepresentation,
} from "@axvdex/utils/unstakeSimulation";
import DashboardWelcomeDialog from "@axvdex/components/DashboardWelcomeDialog";
import useLanguage from "@axvdex/hooks/useLanguage";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import { hasPermitStored } from "@axvdex/utils/localStorage";
import { updateUserGlobalSettingsFields } from "@axvdex/state/wallet/walletThunks";
import { updateMySoftLockups } from "@axvdex/state/wallet/walletSlice";
import { responsiveBreakpoints } from "@axvdex/constants";
import { IAsset, ILpBalance } from "@axvdex/utils/interfaces";
import imgSanitize from "@axvdex/utils/imgSanitize";
import styles from "../styles/DashboardMyPools.module.scss";
import CustomDropdown from "./common/CustomDropdown";
import CustomInputButton from "./form-element/CustomInputButton";
import CustomLoader from "./common/CustomLoader";
import DragHandle from "./common/DragHandle";
import {
  selectAssetBalances,
  selectAssets,
  selectAssetUnmints,
  selectChains,
  selectFarms,
  selectFarmsLpBalance,
  selectFarmsWithBalance,
  selectGlobalSettings,
  selectIsWalletLoading,
  selectMySoftLockups,
  selectPools,
  selectPoolsLpBalance,
  selectPoolsWithBalance,
  selectUserLoading,
  selectWalletInfo,
} from "state/wallet/walletSelectors";
import { useAppDispatch, useAppSelector } from "state";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import { AssetChainLogo } from "@axvdex/utils/chains";

HighchartsAccessibility(Highcharts);

//These are the colors for the chart copied from Statistics.module.scss, rearranged to match the order and no similar colors are next to each other
const colors = [
  "#00FF7F", // --chart-variation-electric-green
  "#00BFFF", // --chart-variation-electric-blue
  "#8A2BE2", // --chart-variation-electric-purple
  "#FF0033", // --chart-variation-electric-red
  // "#FF6600", // --chart-variation-electric-orange .. commented out so only used once as ARCH token reserved
  "#FF1493", // --chart-variation-electric-pink
  "#FFFF33", // --chart-variation-electric-yellow
  "#FF33FF", // --chart-variation-electric-magenta
  "#00FFFF", // --chart-variation-electric-cyan
  "#CCFF00", // --chart-variation-electric-lime
  "#00FFFF", // --chart-variation-neon-blue
  "#FF00FF", // --chart-variation-neon-purple
  "#00FF00", // --chart-variation-neon-green
  "#FFAA00", // --chart-variation-neon-orange
  "#FF00AA", // --chart-variation-neon-pink
  "#FFFF00", // --chart-variation-neon-yellow
  "#FF00FF", // --chart-variation-neon-magenta
  "#00FFEF", // --chart-variation-neon-turquoise
  "#00FFFF", // --chart-variation-neon-cyan
];

const generateColors = (n: number, idxArch: number) => {
  const backgroundColor = [];
  const borderColor = [];
  let i = 0;
  for (let idx = 0; idx < n; idx++) {
    if (idxArch === idx) {
      const reserved = "#FF4D00";
      backgroundColor.push(`${reserved}66`);
      borderColor.push(`${reserved}CC`);
    } else {
      backgroundColor.push(`${colors[i % colors.length]}66`);
      borderColor.push(`${colors[i % colors.length]}CC`);
      i++;
    }
  }
  return { backgroundColor, borderColor };
};

function DashboardMyAssetPortfolio() {
  const { i18 } = useLanguage();
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const dispatch = useAppDispatch();
  const isLoadingWallet = useAppSelector(selectIsWalletLoading);
  const walletInfo = useAppSelector(selectWalletInfo);
  const [showNotConnectedData, setShowNotConnectedData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const assets = useAppSelector(selectAssets);
  const assetBalances = useAppSelector(selectAssetBalances);
  const userAssetsUnmints = useAppSelector(selectAssetUnmints);
  const pools = useAppSelector(selectPools);
  const poolsWithBalance = useAppSelector(selectPoolsWithBalance);
  const farms = useAppSelector(selectFarms);
  const farmsWithBalance = useAppSelector(selectFarmsWithBalance);
  const chains = useAppSelector(selectChains);

  const poolsLpBalance = useAppSelector(selectPoolsLpBalance);
  const farmsLpBalance = useAppSelector(selectFarmsLpBalance);

  const mySoftLockups = useAppSelector(selectMySoftLockups);
  const userLoading = useAppSelector(selectUserLoading);
  const globalSettings = useAppSelector(selectGlobalSettings);

  const [filter, setFilter] = useState<{ id: string; label: string } | null>(null);
  const [display, setDisplay] = useState<string | null>(null);
  const [xMerge, setxMerge] = useState<boolean | null>(null);
  const [groupChains, setGroupChains] = useState<boolean | null>(null);

  const [data, setData] = useState<{
    total: string;
    totalRaw: string;
    assetsAmounts: { asset: IAsset; totalAmount: string; totalDollarAmount: string }[];
  } | null>(null);

  useEffect(() => {
    if (!isLoadingWallet && !walletInfo.isConnected) {
      setData(null);
      setIsLoading(false);
      setShowNotConnectedData(true);
    } else {
      setShowNotConnectedData(false);
      setIsLoading(true);
    }
  }, [walletInfo.isConnected, isLoadingWallet]);

  useEffect(() => {
    if (userLoading || !globalSettings) setIsLoading(true);
    else {
      setFilter(globalSettings?.dashboardPortfolioActiveFilters?.filter ?? { id: "Total", label: "Total" });
      setDisplay(globalSettings?.dashboardPortfolioActiveFilters?.display ?? "top5");
      setxMerge(
        globalSettings?.dashboardPortfolioActiveFilters?.xMerge !== undefined
          ? globalSettings?.dashboardPortfolioActiveFilters?.xMerge
          : true
      );
      setGroupChains(
        globalSettings?.dashboardPortfolioActiveFilters?.groupChains !== undefined
          ? globalSettings?.dashboardPortfolioActiveFilters?.groupChains
          : true
      );
      setIsLoading(false);
    }
  }, [userLoading, globalSettings]);

  useEffect(() => {
    if (
      !globalSettings ||
      Object.keys(assets).length === 0 ||
      //Object.keys(assetBalances).length === 0 ||
      Object.keys(pools).length === 0
      //Object.keys(poolsWithBalance).length === 0 ||
      //Object.keys(mySoftLockups).length === 0
    ) {
      return;
    }

    let totalAvailableUSD = Object.values(assetBalances).length > 0 ? BigNumber(0) : null;
    let totalPoolsUSD = Object.values(poolsWithBalance).length > 0 ? BigNumber(0) : null;
    let totalFarmsUSD = Object.values(farmsWithBalance).length > 0 ? BigNumber(0) : null;
    let totalUnmintUSD = Object.values(userAssetsUnmints).length > 0 ? BigNumber(0) : null;
    let totalSoftLockups = Object.values(mySoftLockups).length > 0 ? BigNumber(0) : null;
    let totalPendingRewardsUSD = BigNumber(0);
    // console.log(poolsLpBalance);
    // console.log(farmsLpBalance);

    const assetsUSD: { [key: string]: BigNumber } = {};
    const assetsAmounts: { [key: string]: BigNumber } = {};

    if ("Total" === filter?.id || "Available" === filter?.id) {
      for (const [key, value] of Object.entries(assetBalances)) {
        const assetState = assets[key];
        if (!assetState || !walletInfo.connectedChains[assetState.contextChainId]) continue;
        const assetAmountUSD = BigNumber(value).div(Math.pow(10, assetState.decimals)).times(assetState.price);
        totalAvailableUSD = totalAvailableUSD.plus(assetAmountUSD);
        let assetID = assetState.id;
        if (xMerge && assetState.isDerivative)
          assetID = assetState.derivativeSource !== "-" ? assetState.derivativeSource : assetState.id; // "-" check as on testnet there is no relayers for some derivatives and we make "-" as placeholder
        if (groupChains) assetID = assets[assetID].symbol;

        assetsUSD[assetID] = (assetsUSD[assetID] || BigNumber(0)).plus(assetAmountUSD);
        assetsAmounts[assetID] = (assetsAmounts[assetID] || BigNumber(0)).plus(
          BigNumber(value).div(Math.pow(10, assetState.decimals))
        );
      }
    }

    if ("Total" === filter?.id || "Unmints" === filter?.id) {
      for (const [key, value] of Object.entries(userAssetsUnmints)) {
        const assetState = assets[key];
        if (!assetState || !walletInfo.connectedChains[assetState.contextChainId]) continue;
        const unmintsSum = value.unmints.reduce((accumulator, currentValue) => {
          return BigNumber(accumulator).plus(currentValue);
        }, BigNumber(0));
        const readyUnmintsSum = value.readyUnmints.reduce((accumulator, currentValue) => {
          return BigNumber(accumulator).plus(currentValue);
        }, BigNumber(0));

        const assetAmountUSD = unmintsSum
          .plus(readyUnmintsSum)
          .div(Math.pow(10, assetState.decimals))
          .times(assetState.price);

        totalUnmintUSD = totalUnmintUSD.plus(assetAmountUSD);

        let assetID = assetState.id;
        if (xMerge && assetState.isDerivative)
          assetID = assetState.derivativeSource !== "-" ? assetState.derivativeSource : assetState.id; // "-" check as on testnet there is no relayers for some derivatives and we make "-" as placeholder
        if (groupChains) assetID = assets[assetID].symbol;

        assetsUSD[assetID] = (assetsUSD[assetID] || BigNumber(0)).plus(assetAmountUSD);
        assetsAmounts[assetID] = (assetsAmounts[assetID] || BigNumber(0)).plus(
          unmintsSum.plus(readyUnmintsSum).div(Math.pow(10, assetState.decimals))
        );
      }
    }

    if ("Total" === filter?.id || "Pools" === filter?.id) {
      for (const poolWithBalance of poolsWithBalance) {
        const poolState = pools[poolWithBalance.contract_addr];
        if (!poolState || !walletInfo.connectedChains[poolState.contextChainId]) continue;

        if ("stable" === poolState.type) {
          const priceOfStable =
            assets[
              poolState.poolAssets[0].info.token?.contract_addr || poolState.poolAssets[0].info.native_token?.denom
            ]?.price ?? 0;

          const stablePoolValue = formatBalance(
            assetBalances[poolWithBalance.contract_addr],
            priceOfStable,
            6
          ).usdConversion;
          totalPoolsUSD = totalPoolsUSD.plus(stablePoolValue);

          poolState.poolAssets.forEach(poolAsset => {
            let assetID = poolAsset.info.token?.contract_addr || poolAsset.info.native_token?.denom;
            if (xMerge && assets[assetID].isDerivative)
              assetID = assets[assetID].derivativeSource !== "-" ? assets[assetID].derivativeSource : assetID; // "-" check as on testnet there is no relayers for some derivatives and we make "-" as placeholder

            if (groupChains) assetID = assets[assetID].symbol;

            assetsUSD[assetID] = (assetsUSD[assetID] || BigNumber(0)).plus(
              stablePoolValue / poolState.poolAssets.length
            );
            assetsAmounts[assetID] = (assetsAmounts[assetID] || BigNumber(0)).plus(
              formatBalance(assetBalances[poolWithBalance.contract_addr], priceOfStable, 6).amount /
                poolState.poolAssets.length
            );
          });
        }
        if ("standard" === poolState.type) {
          let totalAmountStandards = 0;
          for (let i = 0; i < poolState.poolAssets.length; i++) {
            const assetState =
              assets[
                poolState.poolAssets[i].info.token?.contract_addr || poolState.poolAssets[i].info.native_token?.denom
              ];

            let assetID =
              poolState.poolAssets[i].info.token?.contract_addr || poolState.poolAssets[i].info.native_token?.denom;
            if (xMerge && assets[assetID].isDerivative)
              assetID = assets[assetID].derivativeSource !== "-" ? assets[assetID].derivativeSource : assetID; // "-" check as on testnet there is no relayers for some derivatives and we make "-" as placeholder
            if (groupChains) assetID = assets[assetID].symbol;

            const assetPoolValue = formatBalance(
              standardLPTokenRepresentation(
                assetBalances[poolWithBalance.contract_addr],
                pools[poolWithBalance.contract_addr]
              ).refund_assets[i],
              assetState.price,
              assetState.decimals
            ).usdConversion;
            totalAmountStandards = totalAmountStandards + assetPoolValue;

            assetsUSD[assetID] = (assetsUSD[assetID] || BigNumber(0)).plus(assetPoolValue);
            assetsAmounts[assetID] = (assetsAmounts[assetID] || BigNumber(0)).plus(
              formatBalance(
                standardLPTokenRepresentation(
                  assetBalances[poolWithBalance.contract_addr],
                  pools[poolWithBalance.contract_addr]
                ).refund_assets[i],
                assetState.price,
                assetState.decimals
              ).amount
            );
          }
          totalPoolsUSD = totalPoolsUSD.plus(totalAmountStandards);
        }

        if ("hybrid" === poolState.type) {
          let totalAmountHybrid = 0;
          for (let i = 0; i < poolState.poolAssets.length; i++) {
            const assetState =
              assets[
                poolState.poolAssets[i].info.token?.contract_addr || poolState.poolAssets[i].info.native_token?.denom
              ];
            let assetID =
              poolState.poolAssets[i].info.token?.contract_addr || poolState.poolAssets[i].info.native_token?.denom;
            if (xMerge && assets[assetID].isDerivative)
              assetID = assets[assetID].derivativeSource !== "-" ? assets[assetID].derivativeSource : assetID; // "-" check as on testnet there is no relayers for some derivatives and we make "-" as placeholder
            if (groupChains) assetID = assets[assetID].symbol;

            const assetPoolValue = formatBalance(
              hybridLPTokenRepresentation(
                assetBalances[poolWithBalance.contract_addr],
                pools[poolWithBalance.contract_addr]
              ).refund_assets[i],
              assetState.price,
              assetState.decimals
            ).usdConversion;
            totalAmountHybrid = totalAmountHybrid + assetPoolValue;

            assetsUSD[assetID] = (assetsUSD[assetID] || BigNumber(0)).plus(assetPoolValue);
            assetsAmounts[assetID] = (assetsAmounts[assetID] || BigNumber(0)).plus(
              formatBalance(
                hybridLPTokenRepresentation(
                  assetBalances[poolWithBalance.contract_addr],
                  pools[poolWithBalance.contract_addr]
                ).refund_assets[i],
                assetState.price,
                assetState.decimals
              ).amount
            );
          }
          totalPoolsUSD = totalPoolsUSD.plus(totalAmountHybrid);
        }
      }
    }

    if ("Total" === filter?.id || "Farms" === filter?.id) {
      for (const farmWithBalance of farmsWithBalance) {
        const farmState = farms[farmWithBalance.contract_addr];
        if (!farmState || !walletInfo.connectedChains[farmState.contextChainId]) continue;
        let incAsset = assets[farmState.config.inc_token].id;
        if (!incAsset) continue;
        if (xMerge && assets[incAsset].isDerivative)
          incAsset = assets[incAsset].derivativeSource !== "-" ? assets[incAsset].derivativeSource : incAsset; // "-" check as on testnet there is no relayers for some derivatives and we make "-" as placeholder

        if (groupChains) incAsset = assets[incAsset].symbol;

        const incAssetAmount = BigNumber(farmWithBalance.balance).div(
          Math.pow(10, assets[farmState.config.inc_token].decimals)
        );

        assetsUSD[incAsset] = (assetsUSD[incAsset] || BigNumber(0)).plus(
          incAssetAmount.times(assets[farmState.config.inc_token].price)
        );
        assetsAmounts[incAsset] = (assetsAmounts[incAsset] || BigNumber(0)).plus(incAssetAmount);

        totalFarmsUSD = totalFarmsUSD.plus(incAssetAmount.times(assets[farmState.config.inc_token].price));
      }
    }

    if ("Total" === filter?.id || "Soft-Lockups" === filter?.id) {
      for (const [key, value] of Object.entries(mySoftLockups)) {
        const poolState = pools[key];
        if (!poolState || !walletInfo.connectedChains[poolState.contextChainId]) continue;

        value.forEach(lockup => {
          const elapsed_time =
            (new Date().getTime() - new Date(parseInt(lockup.start_timestamp) / 1000000).getTime()) / 1000;
          const calcs = calcLockup(poolState, lockup.assets_amount, BigInt(Math.round(elapsed_time)));

          calcs.forEach((assetLockup, i) => {
            const assetState =
              assets[
                poolState.poolAssets[i].info.token?.contract_addr || poolState.poolAssets[i].info.native_token?.denom
              ];
            let assetID =
              poolState.poolAssets[i].info.token?.contract_addr || poolState.poolAssets[i].info.native_token?.denom;
            if (xMerge && assets[assetID].isDerivative)
              assetID = assets[assetID].derivativeSource !== "-" ? assets[assetID].derivativeSource : assetID; // "-" check as on testnet there is no relayers for some derivatives and we make "-" as placeholder

            if (groupChains) assetID = assets[assetID].symbol;

            const assetLockupValue = BigNumber(assetLockup.final_amount)
              .div(Math.pow(10, assetState.decimals))
              .times(assetState.price);
            totalSoftLockups = totalSoftLockups.plus(assetLockupValue);

            assetsUSD[assetID] = (assetsUSD[assetID] || BigNumber(0)).plus(assetLockupValue);
            assetsAmounts[assetID] = (assetsAmounts[assetID] || BigNumber(0)).plus(
              BigNumber(assetLockup.final_amount).div(Math.pow(10, assetState.decimals))
            );
          });
        });
      }
    }

    if ("Total" === filter?.id || "Pending Rewards" === filter?.id) {
      Object.values({ ...poolsLpBalance, ...farmsLpBalance }).forEach((balance: ILpBalance) => {
        balance.rewards.forEach(reward => {
          if (reward.rewards !== "0") {
            const assetState = assets[reward.asset.token?.contract_addr || reward.asset.native_token?.denom];
            const walletChainContext = walletInfo.connectedChains[assetState.contextChainId];
            if (!walletChainContext) return;
            let rewardAsset = reward.asset.token?.contract_addr || reward.asset.native_token?.denom;
            if (xMerge && assets[rewardAsset].isDerivative)
              rewardAsset =
                assets[rewardAsset].derivativeSource !== "-" ? assets[rewardAsset].derivativeSource : rewardAsset; // "-" check as on testnet there is no relayers for some derivatives and we make "-" as placeholder

            if (groupChains) rewardAsset = assets[rewardAsset].symbol;
            const rewardAmount = BigNumber(reward.rewards).div(Math.pow(10, assetState.decimals));

            totalPendingRewardsUSD = totalPendingRewardsUSD.plus(rewardAmount.times(assetState.price));

            assetsUSD[rewardAsset] = (assetsUSD[rewardAsset] || BigNumber(0)).plus(
              rewardAmount.times(assetState.price)
            );
            assetsAmounts[rewardAsset] = (assetsAmounts[rewardAsset] || BigNumber(0)).plus(rewardAmount);
          }
        });
      });
    }

    let total: string;
    let totalRaw: string;

    if ("Total" === filter?.id) {
      let aggregated = totalAvailableUSD || BigNumber(0);
      if (totalUnmintUSD !== null) aggregated = aggregated.plus(totalUnmintUSD);
      if (totalPoolsUSD !== null) aggregated = aggregated.plus(totalPoolsUSD);
      if (totalFarmsUSD !== null) aggregated = aggregated.plus(totalFarmsUSD);
      if (totalSoftLockups !== null) aggregated = aggregated.plus(totalSoftLockups);
      if (totalPendingRewardsUSD !== null) aggregated = aggregated.plus(totalPendingRewardsUSD);
      total = abbreviateUSD(aggregated.decimalPlaces(2, BigNumber.ROUND_FLOOR));
      totalRaw = aggregated.decimalPlaces(2, BigNumber.ROUND_FLOOR).toString(10);
    }
    if ("Available" === filter?.id && totalAvailableUSD !== null) {
      total = abbreviateUSD(totalAvailableUSD.decimalPlaces(2, BigNumber.ROUND_FLOOR));
      totalRaw = totalAvailableUSD.decimalPlaces(2, BigNumber.ROUND_FLOOR).toString(10);
    }
    if ("Pools" === filter?.id && totalPoolsUSD !== null) {
      total = abbreviateUSD(totalPoolsUSD.decimalPlaces(2, BigNumber.ROUND_FLOOR));
      totalRaw = totalPoolsUSD.decimalPlaces(2, BigNumber.ROUND_FLOOR).toString(10);
    }
    if ("Farms" === filter?.id && totalFarmsUSD !== null) {
      total = abbreviateUSD(totalFarmsUSD.decimalPlaces(2, BigNumber.ROUND_FLOOR));
      totalRaw = totalFarmsUSD.decimalPlaces(2, BigNumber.ROUND_FLOOR).toString(10);
    }
    if ("Unmints" === filter?.id && totalUnmintUSD !== null) {
      total = abbreviateUSD(totalUnmintUSD.decimalPlaces(2, BigNumber.ROUND_FLOOR));
      totalRaw = totalUnmintUSD.decimalPlaces(2, BigNumber.ROUND_FLOOR).toString(10);
    }
    if ("Soft-Lockups" === filter?.id && totalSoftLockups !== null) {
      total = abbreviateUSD(totalSoftLockups.decimalPlaces(2, BigNumber.ROUND_FLOOR));
      totalRaw = totalSoftLockups.decimalPlaces(2, BigNumber.ROUND_FLOOR).toString(10);
    }
    if ("Pending Rewards" === filter?.id && totalPendingRewardsUSD !== null) {
      total = abbreviateUSD(totalPendingRewardsUSD.decimalPlaces(2, BigNumber.ROUND_FLOOR));
      totalRaw = totalPendingRewardsUSD.decimalPlaces(2, BigNumber.ROUND_FLOOR).toString(10);
    }

    setIsLoading(false);
    setData({
      total,
      totalRaw,
      assetsAmounts: Object.entries(assetsUSD)
        .sort((a, b) => {
          return b[1].toNumber() - a[1].toNumber();
        })
        .map(assetAmounts => {
          return {
            asset: !groupChains
              ? assets[assetAmounts[0]]
              : Object.values(assets).find(({ symbol }) => symbol === assetAmounts[0]),
            totalAmount: assetsAmounts[assetAmounts[0]].decimalPlaces(4, BigNumber.ROUND_FLOOR).toString(),
            totalDollarAmount: assetAmounts[1].decimalPlaces(2, BigNumber.ROUND_FLOOR).toString(),
          };
        })
        .slice(0, "top5" === display ? 5 : Object.entries(assetsUSD).length),
    });
  }, [
    assets,
    assetBalances,
    pools,
    poolsWithBalance,
    farmsWithBalance,
    mySoftLockups,
    poolsLpBalance,
    farmsLpBalance,
    filter,
    xMerge,
    groupChains,
    display,
    walletInfo.isConnected,
    globalSettings,
  ]);

  useEffect(() => {
    if (walletInfo.isConnected && Object.keys(pools).length > 0 && Object.keys(mySoftLockups).length === 0)
      getUserSoftLockups();
  }, [walletInfo.isConnected, pools, mySoftLockups]);

  const onFilterChange = ({ display, filter, xMerge, groupChains }) => {
    dispatch(
      updateUserGlobalSettingsFields({
        dashboardPortfolioActiveFilters: { display, filter, xMerge, groupChains },
      })
    );
  };

  const getUserSoftLockups = async () => {
    const promises: Promise<any>[] = [];
    const poolsWithLockups = [];
    for (const poolState of Object.values(pools)) {
      const walletChainContext = walletInfo.connectedChains[poolState.contextChainId];
      if (!walletChainContext) continue;
      if (poolState.lockups) {
        poolsWithLockups.push(poolState);
        promises.push(
          rpcClientQuerySmartContractWrapper(walletChainContext.signingClient, poolState.lockups, {
            acc_lockups: { address: walletChainContext.address },
          })
        );
      }
    }

    const res = await Promise.all(promises);

    res.forEach((poolLockups, i) => {
      dispatch(
        updateMySoftLockups({
          [poolsWithLockups[i].address]: poolLockups,
        })
      );
    });
  };

  return (
    <section className={clsx(styles.dashboardGridItemMyPools, "dashboardGridItemMyPools")}>
      <div className="dashboardGridItemTitle">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <h2>{i18("My Portfolio", "dashboard.assetPortfolio.title")}</h2>
        </div>
        <div
          className="buttonContainer"
          style={
            showNotConnectedData ||
            (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet))
              ? { pointerEvents: "none", filter: "blur(0.3rem)" }
              : {}
          }
        >
          <CustomDropdown
            extraClassName="dashboardMenuDropdown portfolio"
            customDropdownToggle={{
              btnVariant: "icon",
              btnColor: "dark-medium",
              btnTitle: i18("Menu", "dashboard.assetPortfolio.menu.title"),
            }}
            content={
              <>
                <fieldset>
                  <legend className="label">{i18("Display", "dashboard.assetPortfolio.filter.display")}</legend>
                  <div className="btnGroup vertical noBorder">
                    <CustomInputButton
                      type="radio"
                      id="top5"
                      name="top5"
                      labelText={i18("Top 5", "dashboard.assetPortfolio.filter.top5")}
                      labelIcon={<Star />}
                      checked={"top5" === display}
                      onChange={() => {}}
                      onClick={() => {
                        const newValue = "top5" === display ? "" : "top5";
                        setDisplay(newValue);
                        onFilterChange({ display: newValue, filter, xMerge, groupChains });
                      }}
                    />
                    <CustomInputButton
                      type="radio"
                      id="xMerge"
                      name="xMerge"
                      labelText={i18("Group xAssets", "dashboard.assetPortfolio.group.xMerge")}
                      labelIcon={<Package />}
                      checked={xMerge}
                      onChange={() => {}}
                      onClick={() => {
                        const newValue = !xMerge;
                        setxMerge(newValue);
                        onFilterChange({ display, filter, xMerge: newValue, groupChains });
                      }}
                    />
                    <CustomInputButton
                      type="radio"
                      id="chain"
                      name="chain"
                      labelText={i18("Group Chains", "dashboard.assetPortfolio.group.chains")}
                      labelIcon={<Link />}
                      checked={groupChains}
                      onChange={() => {}}
                      onClick={() => {
                        const newValue = !groupChains;
                        setGroupChains(newValue);
                        onFilterChange({ display, filter, xMerge, groupChains: newValue });
                      }}
                    />
                  </div>
                </fieldset>

                <fieldset>
                  <legend className="label">{i18("Categories", "dashboard.assetPortfolio.filter.header")}</legend>
                  <div className="btnGroup vertical noBorder">
                    <CustomInputButton
                      type="radio"
                      id="all"
                      name="categories_choose"
                      labelText={i18("Total", "dashboard.assetPortfolio.filter.all")}
                      labelIcon={<Circle />}
                      checked={"Total" === filter?.id}
                      onChange={() => {}}
                      onClick={() => {
                        const newValue = {
                          id: "Total",
                          label: "Total",
                        };
                        setFilter(newValue);
                        onFilterChange({ display, filter: newValue, xMerge, groupChains });
                      }}
                    />
                    <CustomInputButton
                      type="radio"
                      id="available"
                      name="categories_choose"
                      labelText={i18("Available", "dashboard.assetPortfolio.filter.available")}
                      labelIcon={<Circle />}
                      checked={"Available" === filter?.id}
                      onChange={() => {}}
                      onClick={() => {
                        const newValue = {
                          id: "Available",
                          label: "Available",
                        };
                        setFilter(newValue);
                        onFilterChange({ display, filter: newValue, xMerge, groupChains });
                      }}
                    />
                    <CustomInputButton
                      type="radio"
                      id="pools"
                      name="categories_choose"
                      labelText={i18("Pools", "dashboard.assetPortfolio.filter.pools")}
                      labelIcon={<Circle />}
                      checked={"Pools" === filter?.id}
                      onChange={() => {}}
                      onClick={() => {
                        const newValue = {
                          id: "Pools",
                          label: "Pools",
                        };
                        setFilter(newValue);
                        onFilterChange({ display, filter: newValue, xMerge, groupChains });
                      }}
                    />
                    <CustomInputButton
                      type="radio"
                      id="farms"
                      name="categories_choose"
                      labelText={i18("Farms", "dashboard.assetPortfolio.filter.farms")}
                      labelIcon={<Circle />}
                      checked={"Farms" === filter?.id}
                      onChange={() => {}}
                      onClick={() => {
                        const newValue = {
                          id: "Farms",
                          label: "Farms",
                        };
                        setFilter(newValue);
                        onFilterChange({ display, filter: newValue, xMerge, groupChains });
                      }}
                    />
                    <CustomInputButton
                      type="radio"
                      id="soft-lockups"
                      name="categories_choose"
                      labelText={i18("Soft Lockups", "dashboard.assetPortfolio.filter.softLockups")}
                      labelIcon={<Circle />}
                      checked={"Soft-Lockups" === filter?.id}
                      onChange={() => {}}
                      onClick={() => {
                        const newValue = {
                          id: "Soft-Lockups",
                          label: "Soft Lockups",
                        };
                        setFilter(newValue);
                        onFilterChange({ display, filter: newValue, xMerge, groupChains });
                      }}
                    />
                    <CustomInputButton
                      type="radio"
                      id="unmints"
                      name="categories_choose"
                      labelText={i18("Unmints", "dashboard.assetPortfolio.filter.unmints")}
                      labelIcon={<Circle />}
                      checked={"Unmints" === filter?.id}
                      onChange={() => {}}
                      onClick={() => {
                        const newValue = {
                          id: "Unmints",
                          label: "Unmints",
                        };
                        setFilter(newValue);
                        onFilterChange({ display, filter: newValue, xMerge, groupChains });
                      }}
                    />
                    <CustomInputButton
                      type="radio"
                      id="pending-rewards"
                      name="categories_choose"
                      labelText={i18("Pending Rewards", "dashboard.assetPortfolio.filter.pendingRewards")}
                      labelIcon={<Circle />}
                      checked={"Pending Rewards" === filter?.id}
                      onChange={() => {}}
                      onClick={() => {
                        const newValue = {
                          id: "Pending Rewards",
                          label: "Pending Rewards",
                        };
                        setFilter(newValue);
                        onFilterChange({ display, filter: newValue, xMerge, groupChains });
                      }}
                    />
                  </div>
                </fieldset>
              </>
            }
          />
          <DragHandle extraClassName="ghostDragHandle" />
        </div>
      </div>

      {showNotConnectedData ||
      (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) ? (
        <DashboardWelcomeDialog />
      ) : (
        <></>
      )}

      <section className="dashboardSectionPools withGradientBorder">
        {showNotConnectedData ||
        (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) ||
        !hasPermitStored(walletInfo) ? (
          <div style={{ pointerEvents: "none", filter: "blur(0.3rem)" }}>
            <h3 style={{ marginBottom: "0rem" }}>
              {filter ? filter.label : "-"}: {data?.total || "$0"}
            </h3>
          </div>
        ) : isLoading ? (
          <CustomLoader size="xs" />
        ) : (
          <>
            {"$0" !== data?.total ? (
              <div className="flexbox">
                {
                  <div style={{ flexDirection: "column" }}>
                    <div style={{ overflowY: "hidden" }}>
                      <h3
                        className="h6"
                        style={{
                          marginBottom: data?.total === undefined || "$0" === data?.total ? "0rem" : "1rem",
                        }}
                      >
                        {filter ? filter.label : "-"}: {data?.total || "$0"}
                      </h3>
                    </div>
                    <div
                      style={{
                        maxHeight: "20rem",
                        overflowY: "top5" === display ? "hidden" : "scroll",
                        paddingRight: "1rem",
                        width: "max-content",
                      }}
                    >
                      {data.assetsAmounts
                        .filter(assetAmounts => "0" !== assetAmounts.totalAmount)
                        .map(assetAmounts => (
                          <div className="flexbox" key={assetAmounts.asset.symbol}>
                            <span
                              className="tokenIconPortfolio"
                              title={assetAmounts.asset.symbol}
                              aria-label={assetAmounts.asset.symbol}
                            >
                              <img
                                src={imgSanitize(assetAmounts.asset.symbol)}
                                alt={`${assetAmounts.asset.symbol} ${assetAmounts.asset.symbol}`}
                              />
                            </span>

                            <span className="tokenInfo">
                              <div className="tokenAmounts">
                                {abbreviateNumber(assetAmounts.totalAmount)}{" "}
                                <small>({abbreviateUSD(assetAmounts.totalDollarAmount)})</small>
                              </div>
                              <div style={{ display: "flex" }}>
                                {!groupChains && (
                                  <AssetChainLogo
                                    chain={chains[assetAmounts.asset.contextChainId]}
                                    style={{ fontSize: "var(--px14)", color: "var(--warm-grey)" }}
                                  />
                                )}

                                <span
                                  style={{
                                    fontSize: "var(--px14)",
                                    color: "var(--warm-grey)",
                                    marginLeft: !groupChains ? ".5em" : "0em",
                                  }}
                                >
                                  {assetAmounts.asset.symbol}
                                </span>
                              </div>
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                }

                {!isMobileBreakpoint && data.assetsAmounts.length > 0 && (
                  <div style={{ height: "100%", width: "100%" }}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        chart: {
                          type: "pie",
                          backgroundColor: "",
                        },
                        accessibility: {
                          enabled: true,
                          description: "Portfolio asset distribution",
                        },
                        colors: generateColors(
                          data.assetsAmounts.length,
                          data.assetsAmounts.findIndex(assetAmounts => assetAmounts.asset.isSourceDenom)
                        ).backgroundColor,
                        title: {
                          text: "",
                        },
                        legend: {
                          enabled: false,
                        },
                        tooltip: {
                          formatter() {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            return `<b>${this.key}</b>: ${this.point.amount} (${abbreviateUSD(this.y)})`;
                          },
                        },
                        plotOptions: {
                          pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            dataLabels: {
                              enabled: true,
                              format: "{point.name}: {point.per}%",
                            },
                            showInLegend: true,
                          },
                        },
                        series: [
                          {
                            name: "Portfolio Assets",
                            colorByPoint: true,
                            innerSize: "50%",
                            data: data.assetsAmounts
                              .filter(
                                assetAmounts =>
                                  BigNumber(BigNumber(assetAmounts.totalDollarAmount).div(data.totalRaw).times(100))
                                    .decimalPlaces(2)
                                    .toNumber() > 0
                              )
                              .map((assetAmounts, i) => {
                                return {
                                  name: assetAmounts.asset.symbol,
                                  y: parseFloat(assetAmounts.totalDollarAmount),
                                  amount: abbreviateNumber(assetAmounts.totalAmount),
                                  per: BigNumber(
                                    BigNumber(assetAmounts.totalDollarAmount).div(data.totalRaw).times(100)
                                  )
                                    .decimalPlaces(2)
                                    .toString(),
                                  borderColor: generateColors(
                                    data.assetsAmounts.length,
                                    data.assetsAmounts.findIndex(assetAmounts => assetAmounts.asset.isSourceDenom)
                                  ).borderColor[i],
                                };
                              }),
                          },
                        ],
                        credits: {
                          enabled: false,
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <h3 className="h6" style={{ marginBottom: "0rem" }}>
                {filter ? filter.label : "-"}: {data?.total || "$0"}
              </h3>
            )}
          </>
        )}
      </section>
    </section>
  );
}

export default DashboardMyAssetPortfolio;
