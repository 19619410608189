import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from "clsx";
import Button from "@axvdex/components/common/Button";
import useLanguage from "@axvdex/hooks/useLanguage";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/About.module.scss";

interface aboutProps {
  isLanding?: boolean;
  maintenanceMode?: boolean;
}

function About() {
  const { i18 } = useLanguage();
  return (
    <MainLayout
      pageClass={clsx(styles.about, "about")}
      headTitle={i18("About", "about.headTitle")}
      headDescription={i18("About Astrovault", "about.headDescription")}
    >
      <AboutPageContent />
    </MainLayout>
  );
}

export const AboutPageContent = ({ isLanding, maintenanceMode }: aboutProps) => {
  const { i18 } = useLanguage();
  const PartnersList = [
    { name: i18("AiB", "about.partners.aib.name"), id: "aib" },
    { name: i18("Silvermine Capital", "about.partners.silvermine-capital.name"), id: "silvermine-capital" },
    { name: i18("InterProtocol", "about.partners.ist.name"), id: "inter-protocol" },
    { name: i18("Gravity Bridge", "about.partners.gravitybridge.name"), id: "gravity-bridge" },
    { name: i18("Cudos", "about.partners.cudos.name"), id: "cudos" },
    { name: i18("Agoric", "about.partners.agoric.name"), id: "agoric" },
    { name: i18("Jackal", "about.partners.jackal.name"), id: "jackal" },
    { name: i18("LuckyFriday", "about.partners.luckyfriday.name"), id: "luckyfriday" },
    { name: i18("Crypto Chemistry", "about.partners.crypto-chemistry.name"), id: "crypto-chemistry" },
    { name: i18("Sky Consulting", "about.partners.sky-consulting.name"), id: "sky-consulting" },
    { name: i18("Kado", "about.partners.kado.name"), id: "kado" },
    { name: i18("Andromeda", "about.partners.andromeda.name"), id: "andromeda" },
    { name: i18("Archway", "about.partners.archway.name"), id: "archway" },
    { name: i18("Phi Labs", "about.partners.phi-labs.name"), id: "phi-labs" },
    { name: i18("Decentr", "about.partners.decentr.name"), id: "decentr" },
    { name: i18("Vidulum", "about.partners.vidulum.name"), id: "vidulum" },
    { name: i18("Nibiru", "about.partners.nibiru.name"), id: "nibiru" },
    { name: i18("Empowerchain", "about.partners.empowerchain.name"), id: "empowerchain" },
    { name: i18("Bitcanna", "about.partners.bitcanna.name"), id: "bitcanna" },
    { name: i18("Bonus Block", "about.partners.bonus-block.name"), id: "bonus-block" },
    { name: i18("Architech", "about.partners.architech.name"), id: "architech" },
    { name: i18("Alter", "about.partners.alter.name"), id: "alter" },
    { name: i18("Xavier Capital", "about.partners.xavier-capital.name"), id: "xavier-capital" },
    { name: i18("Liquid Finance", "about.partners.liquid-finance.name"), id: "liquid-finance" },
    { name: i18("Landslide", "about.partners.landslide.name"), id: "landslide" },
    { name: i18("Axelar", "about.partners.axelar.name"), id: "axelar" },
    { name: i18("Living Opera", "about.partners.living-opera.name"), id: "living-opera" },
    { name: i18("Synaps", "about.partners.synaps.name"), id: "synaps" },
    { name: i18("Qwoyn Studios", "about.partners.qwoyn-studios.name"), id: "qwoyn-studios" },
    { name: i18("Kelepar", "about.partners.kelepar.name"), id: "kelepar" },
    { name: i18("Kusama Kingdom", "about.partners.kusama-kingdom.name"), id: "kusama-kingdom" },
  ];
  return (
    <>
      <div className="aboutBgWrapper" />

      <section className="aboutSectionHeader">
        <div className="aboutPageWrapper">
          {maintenanceMode && (
            <h1 className="display64px" style={{ paddingBottom: "1.5em" }}>
              <Trans>
                Closed for maintenance, <br />
                back soon.
              </Trans>
            </h1>
          )}

          <div className="aboutSectionHeaderTextWrapper">
            <p className="heading">{i18("About Astrovault", "about.heading")}</p>
            <h1 className="display64px">
              <Trans i18nKey="about.h1">
                DeFi,
                <br />
                Sustainably<span className="textFuchsia">.</span>
              </Trans>
            </h1>
            <p>
              <a href="https://astrovault.io/whitepaper" target="_blank" rel="noreferrer">
                <Button
                  className="btn"
                  data-color="gradient"
                  title={i18("See Astrovault Whitepaper", "footerUtitilitesMenu.link.seewhitepaper.title")}
                  text={i18("Read the Astrovault Whitepaper", "about.readWhitepaperBtn.title")}
                />
              </a>
            </p>
            <p>
              <a href="https://astrovault.io/whitepaper/tf" target="_blank" rel="noreferrer">
                <Button
                  className="btn"
                  data-color="gradient"
                  title={i18("See Timeflux Whitepaper", "footerUtitilitesMenu.link.seetfwhitepaper.title")}
                  text={i18("Read the Timeflux Whitepaper", "about.readTFWhitepaperBtn.title")}
                />
              </a>
            </p>
            <p>
              <a href="https://astrovault.io/whitepaper/econ" target="_blank" rel="noreferrer">
                <Button
                  className="btn"
                  data-color="gradient"
                  title={i18("See Econ Whitepaper", "footerUtitilitesMenu.link.seeeconwhitepaper.title")}
                  text={i18("Read the Econ Whitepaper", "about.readEconWhitepaperBtn.title")}
                />
              </a>
            </p>
            {isLanding && (
              <p>
                <a href="https://astrovault.io" target="_blank" rel="noreferrer">
                  <Button
                    className="btn"
                    data-color="gradient"
                    title={i18("Go to your Dashboard", "about.goToDashboardBtn.title")}
                    text={i18("Go to your Dashboard", "about.goToDashboardBtn.title")}
                  />
                </a>
              </p>
            )}
          </div>
        </div>
      </section>

      <section className="aboutSectionDetails">
        <img
          className="gradient"
          src={require(`../assets/images/about-gradient-pink-turquoise.webp`)}
          alt={i18("Gradient", "about.img.gradient.alt")}
          loading="lazy"
        />

        <div className="aboutPageWrapper">
          <div className="aboutGrid gridTwo primary">
            {/*<div className="aboutGridText">*/}
            {/*  <h2 className="display36px">*/}
            {/*    <Trans t={t}>*/}
            {/*      Launch*/}
            {/*      <br />*/}
            {/*      <span className="textFuchsia">Status</span>*/}
            {/*    </Trans>*/}
            {/*  </h2>*/}

            {/*  <div className="text18">*/}
            {/*    <Trans t={t}>*/}
            {/*      <p>*/}
            {/*        We are currently working on getting enough liquidity circulating, holding off briefly to avoid bad*/}
            {/*        initial UX with slippage. Protecting our users is our highest priority.*/}
            {/*      </p>*/}
            {/*      <p>We will be open to the public SOON. ❤️</p>*/}
            {/*    </Trans>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="aboutGridText">
              <h2 className="display36px">
                <Trans i18nKey="about.details.h2">
                  Revolutionising
                  <br />
                  <span className="textFuchsia">AMMs</span>
                </Trans>
              </h2>

              <div className="text18">
                <Trans i18nKey="about.details.text.0">
                  <p>
                    Astrovault is a re-envisioned AMM that fixes a core problem — AMMs are not economically viable. This
                    Cosmos DEX is the first to monetise liquidity.
                  </p>
                </Trans>

                <Trans i18nKey="about.details.text.1">
                  <p>
                    Our growing Protocol Earned Liquidity treasury is shown in our
                    <Link
                      to="/statistics"
                      rel="noopener noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      statistics page.
                    </Link>
                  </p>
                </Trans>

                <Trans i18nKey="about.details.text.2">
                  <p>
                    A massive governance aggregator, Astrovault can offer sustainable incentives for your DAO. With
                    unique staking and LP opportunities, Astrovault stands apart economically in the Cosmos.
                  </p>
                </Trans>
              </div>
            </div>

            <div className="aboutGridImage">
              <img
                src={require(`../assets/images/about-details.webp`)}
                alt={i18("About Astrovault", "about.img.aboutAstrovault.alt")}
                loading="lazy"
              />
            </div>
          </div>

          <div className="aboutGrid gridTwo secondary">
            <div className="aboutGridImage">
              <img
                src={require(`../assets/images/about-devices-pngx2.webp`)}
                alt={i18("About Astrovault - Devices", "about.img.aboutDevices.alt")}
                loading="lazy"
              />
            </div>

            <div className="aboutGridText">
              <ul>
                <li>{i18("Revenue generating AMM", "about.pros.list.0")}</li>
                <li>{i18("Scalable liquid staking", "about.pros.list.1")}</li>
                <li>{i18("Perpetual self-generated liquidity", "about.pros.list.2")}</li>
                <li>{i18("1:1 Stablecoin swaps", "about.pros.list.3")}</li>
                <li>{i18("Layer-1 ecosystem governance power", "about.pros.list.4")}</li>
                <li>{i18("Trading incentives", "about.pros.list.5")}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutSectionFeatures">
        <div className="aboutPageWrapper">
          <h2 className="display36px">{i18("Features", "about.features.title")}</h2>

          <div className="aboutGrid gridThree">
            <article className="aboutSectionFeatureCard withGradientBorder">
              <h3>{i18("External Revenue", "about.features.grid.0.title")}</h3>
              <p>
                <Trans i18nKey="about.features.grid.0.text">
                  The AXV DAO earns revenue from outside its user base, harvesting inflation from Cosmos layer-1 chains
                </Trans>
              </p>
            </article>

            <article className="aboutSectionFeatureCard withGradientBorder">
              <h3>{i18("Volume Incentive", "about.features.grid.1.title")}</h3>
              <p>
                <Trans i18nKey="about.features.grid.1.text">
                  Astrovault directly capitalises on DEX volume and rewards traders. GRVT8 can be redeemed to reimburse
                  trade fees
                </Trans>
              </p>
            </article>

            <article className="aboutSectionFeatureCard withGradientBorder">
              <h3>{i18("Sustainability", "about.features.grid.2.title")}</h3>
              <p>
                <Trans i18nKey="about.features.grid.2.text">
                  All Nebula Standard pool fees buyback and burn the AXV token. All Plasma Stable pool fees are retained
                  as Protocol Owned Liquidity
                </Trans>
              </p>
            </article>
          </div>
        </div>
      </section>

      <section className="aboutSectionRoadmap">
        <div className="aboutPageWrapper">
          <h2 className="display36px">{i18("Roadmap", "about.roadmap.title")}</h2>

          <ul className="aboutRoadmapList">
            <li className="checked">
              <div>
                <h3>{i18("Launch Astrovault", "about.roadmap.list.0.title")}</h3>
                <p>{i18("To Q3 2023", "about.roadmap.list.0.text")}</p>
              </div>
            </li>

            <li className="checked">
              <div>
                <h3>{i18("Outbid launch,", "about.roadmap.list.1.title")}</h3>
                <h3>{i18("AXV token launch", "about.roadmap.list.1.2.title")}</h3>
                <p>{i18("Q1 2024", "about.roadmap.list.1.text")}</p>
              </div>
            </li>

            <li>
              <div>
                <h3>{i18("NFT integrations", "about.roadmap.list.2.2.title")}</h3>
                <p>{i18("Q1 2024", "about.roadmap.list.2.text")}</p>
              </div>
            </li>

            <li>
              <div>
                <h3>{i18("Deploy 2nd Astrovault,", "about.roadmap.list.3.1.title")}</h3>
                <h3>{i18("Deploy 2nd Outbid", "about.roadmap.list.3.2.title")}</h3>
                <p>{i18("Q2 2024", "about.roadmap.list.3.text")}</p>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="aboutSectionRoadmap">
        <div className="aboutPageWrapper">
          <h2 className="display36px">{i18("More roadmap...", "about.roadmap.title")}</h2>

          <ul className="aboutRoadmapList">
            <li>
              <div>
                <h3>{i18("Governance,", "about.roadmap.list.4.1.title")}</h3>
                <h3>{i18("Deploy 3rd Astrovault", "about.roadmap.list.4.2.title")}</h3>
                <p>{i18("Q2 2024", "about.roadmap.list.4.text")}</p>
              </div>
            </li>

            <li>
              <div>
                <h3>{i18("B2B NFTs", "about.roadmap.list.5.1.title")}</h3>
                <h3>{i18("B2B Payments", "about.roadmap.list.5.2.title")}</h3>
                <p>{i18("Q3 2024", "about.roadmap.list.5.text")}</p>
              </div>
            </li>

            <li>
              <div>
                <h3>{i18("EVM Deployment", "about.roadmap.list.6.title")}</h3>
                <p>{i18("Q4 2024", "about.roadmap.list.6.text")}</p>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="aboutSectionPartners" id="astrovault-partners">
        <div className="aboutPageWrapper">
          <h2 className="display36px">{i18("Our Partners", "about.ourPartners.title")}</h2>

          <div className="aboutGrid gridThree">
            {PartnersList.map((item, idx) => (
              <div className="imageWrapper" key={idx}>
                <img
                  src={require(`../assets/images/about/logo-${item.id}.webp`)}
                  alt={item.name}
                  loading="lazy"
                  width={300}
                  height={200}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
