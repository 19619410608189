import { useEffect, useState } from "react";
import clsx from "clsx";

import { isEqual } from "lodash";
import ReactSlider from "react-slider";
import BigNumber from "bignumber.js";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  selectAssets,
  selectChains,
  selectContracts,
  selectGRVT8Balance,
  selectIsWalletLoading,
  selectUser,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import { executeGRVT8BurnAction, updateGRVT8Balance } from "@axvdex/state/wallet/walletThunks";
import { DummyGravitateInfo } from "@axvdex/mocks/DummyData";
import useLanguage from "@axvdex/hooks/useLanguage";
import { IAsset, IContract } from "@axvdex/utils/interfaces";
import { IWalletConnectedChainInfo, IWalletInfo } from "@axvdex/state/wallet/initialState";
import styles from "../styles/DashboardMarket.module.scss";
import DragHandle from "./common/DragHandle";
import CustomLoader from "./common/CustomLoader";
import CustomNumericInput from "./form-element/CustomNumericInput";
import Button from "./common/Button";
import AstrovaultTokenIcon from "./common/AstrovaultTokenIcon";
import { useMediaQuery } from "usehooks-ts";
import { responsiveBreakpoints } from "@axvdex/constants";
import { hasPermitStored } from "@axvdex/utils/localStorage";
import CustomInputButton from "./form-element/CustomInputButton";
import { Loader } from "react-feather";

function DashboardGravitate() {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const walletInfo = useAppSelector(selectWalletInfo, isEqual);
  const user = useAppSelector(selectUser);
  const [showNotConnectedData, setshowNotConnectedData] = useState(false);
  const isLoadingWallet = useAppSelector(selectIsWalletLoading, isEqual);
  const [tabSelected, setTabSelected] = useState(Object.keys(walletInfo.connectedChains)[0]);
  const assets = useAppSelector(selectAssets);
  const contracts = useAppSelector(selectContracts);
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const grvt8Balance = useAppSelector(selectGRVT8Balance);
  const [input, setInput] = useState("0");
  const [sliderValue, setSliderValue] = useState(100);

  const AXV = Object.values(assets).find(asset => asset.symbol === "AXV" && asset.contextChainId === tabSelected);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExecution, setIsLoadingExecution] = useState(false);

  useEffect(() => {
    if (!isLoadingWallet && !walletInfo.isConnected) {
      setshowNotConnectedData(true);
    } else {
      setshowNotConnectedData(false);
    }
  }, [walletInfo.isConnected, isLoadingWallet]);

  useEffect(() => {
    if (!isLoadingWallet && walletInfo.isConnected && contracts["cashback_" + tabSelected]) {
      getGRVT8Balance(walletInfo.connectedChains[tabSelected]);
    }
    if (!tabSelected && Object.keys(walletInfo.connectedChains).length > 0) {
      setTabSelected(Object.keys(walletInfo.connectedChains)[0]);
    }
  }, [walletInfo.isConnected, isLoadingWallet, contracts, tabSelected]);

  const handleBurnGRVT8 = async (walletInfo: IWalletInfo, amount: string) => {
    const grvt8Contract = contracts["cashback_" + tabSelected];
    setIsLoadingExecution(true);
    AXV &&
      walletInfo &&
      walletInfo.connectedChains[tabSelected] &&
      (await dispatch(
        executeGRVT8BurnAction({
          chainId: tabSelected,
          cashbackAddr: grvt8Contract?.address,
          axvAddr: AXV?.address,
          amount: BigNumber(amount).times(Math.pow(10, 6)).decimalPlaces(0).toString(10),
          i18,
        })
      ));
    await getGRVT8Balance(walletInfo.connectedChains[tabSelected]);
    setIsLoadingExecution(false);
  };

  const getGRVT8Balance = async (walletChainContext: IWalletConnectedChainInfo) => {
    const grvt8Contract = contracts["cashback_" + tabSelected];
    if (grvt8Contract) {
      setIsLoading(true);
      const balance = await dispatch(
        updateGRVT8Balance({
          client: walletChainContext.signingClient,
          grvt8Contract: grvt8Contract,
          walletAddress: walletChainContext.address,
        })
      );

      setInput(balance.payload as string);
      setSliderValue(100);
      setIsLoading(false);
    }
  };

  return (
    <section className={clsx(styles.dashboardGridItemMarket, "dashboardGridItemMarket")}>
      <div className="dashboardGridItemTitle">
        <h2>{i18("My Gravitate", "dashboard.gravitate.title")}</h2>
        <div
          style={
            showNotConnectedData ||
            (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) ||
            !user.isUserDetailsLoad
              ? { pointerEvents: "none", filter: "blur(0.3rem)" }
              : {}
          }
        >
          <DragHandle extraClassName="ghostDragHandle" />
        </div>
      </div>
      <section className="withGradientBorder" style={{ filter: !user.isUserDetailsLoad ? "blur(0.3rem)" : undefined }}>
        <fieldset>
          <legend className="visuallyHidden">{i18("Select tab", "dashboard.history.tabs.legend")}</legend>
          <div className="btnGroup filterBtnGroup" style={{ width: "inherit", marginBottom: "2em" }}>
            {Object.keys(walletInfo.connectedChains).map((chainId, i) => {
              return (
                <CustomInputButton
                  key={"grvt8_" + chainId}
                  type="radio"
                  id={"grvt8_" + chainId}
                  defaultChecked={tabSelected === chainId}
                  name={"collectRewards_filter"}
                  labelText={walletInfo.connectedChains[chainId].chainState.displayName}
                  onClick={() => setTabSelected(chainId)}
                />
              );
            })}
          </div>
        </fieldset>
        <div className="grvt8Container">
          {
            // TODO: ON MOBILE BREAKPOINT PASS THE BUTTON TO ANOTHER ROW
          }
          {contracts["cashback_" + tabSelected] && (
            <div className="row" style={{ display: !isMobileBreakpoint ? "flex" : undefined }}>
              <div className="column">
                <div style={{ justifyContent: "center", padding: "1em", paddingBottom: "1.6em" }}>
                  <span
                    className="tokenIcon"
                    title={i18("GRVT8", "dashboard.gravitate.icon.title")}
                    aria-label={i18("GRVT8", "dashboard.gravitate.icon.title")}
                  >
                    <img
                      src={
                        // eslint-disable-next-line @typescript-eslint/no-var-requires
                        require(`../assets/tokens/logo-grvt8.svg`).default
                      }
                      alt={i18("GRVT8 Balance", "dashboard.gravitate.icon.alt")}
                    />
                  </span>
                  <span className="tokenName" style={{ marginLeft: ".5em" }}>
                    {i18("GRVT8", "symbol.grvt8")}
                    <small style={{ color: "var(--warm-grey)" }}>
                      {" "}
                      (1:
                      {contracts["cashback_" + tabSelected] &&
                      contracts["cashback_" + tabSelected].extraFields?.reward_balance &&
                      contracts["cashback_" + tabSelected].extraFields?.totalSupply &&
                      AXV
                        ? BigNumber(1)
                            .times(Math.pow(10, 6))
                            .times(contracts["cashback_" + tabSelected]?.extraFields?.reward_balance ?? 0)
                            .div(
                              contracts["cashback_" + tabSelected]?.extraFields?.totalSupply !== "0"
                                ? contracts["cashback_" + tabSelected]?.extraFields?.totalSupply
                                : 1
                            )
                            .div(Math.pow(10, AXV?.decimals ?? 0))
                            .decimalPlaces(4)
                            .toFixed(4)
                        : ""}{" "}
                      AXV)
                    </small>
                  </span>
                </div>
                <div style={{ justifyContent: "center", textAlign: "left", padding: "1em", textAlignLast: "left" }}>
                  {isLoading || isLoadingExecution ? (
                    <div style={{ margin: "auto", textAlign: "center" }}>
                      <CustomLoader size="xs" />
                    </div>
                  ) : (
                    <CustomNumericInput
                      extraClassName="tradeToTokenUsdFormGroup gradientText"
                      style={{ fontSize: "var(--px22)" }}
                      name="trade_to_usd"
                      labelText={i18("USD trade amount", "trade.form.toToken.input.label")}
                      hiddenLabel={true}
                      placeholder={"0"}
                      value={input}
                      disabled={true}
                    />
                  )}

                  {/* <CustomNumericInput
                extraClassName="burnGRVT8Input"
                name="deposit_to_usd"
                labelText={i18("Enter Amount", "dashboard.gravitate.input.label")}
                hiddenLabel={true}
                placeholder={"0"}
                value={input}
                disabled
              /> */}
                </div>
              </div>
              <div className="column">
                <div
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                    paddingTop: ".95em",
                    paddingBottom: ".95em",
                    textAlignLast: "center",
                  }}
                >
                  <ReactSlider
                    className="horizontalSlider"
                    thumbClassName="horizontalSliderThumb"
                    trackClassName="horizontalSliderTrack"
                    value={sliderValue}
                    onChange={value => {
                      setSliderValue(value);
                      setInput("" + (Number(grvt8Balance[contracts["cashback_" + tabSelected].address]) * value) / 100);
                    }}
                    renderThumb={(props, state) => (
                      <div {...props}>
                        <span className="thumbNowValue">{state.valueNow}%</span>
                        <span className="thumbHandle" />
                      </div>
                    )}
                  />
                </div>

                <div style={{ justifyContent: "center", textAlign: "center", textAlignLast: "center" }}>
                  {" "}
                  <Button
                    extraClassName="grvt8BurnButton"
                    btnColor="dark-medium"
                    title={i18("Collect Rewards", "dashboard.gravitate.btn.label")}
                    icon={<AstrovaultTokenIcon />}
                    onClick={() => handleBurnGRVT8(walletInfo, input)}
                    isFullWidth={true}
                    disabled={
                      isLoading ||
                      isLoadingExecution ||
                      !contracts["cashback_" + tabSelected] ||
                      contracts["cashback_" + tabSelected].extraFields?.totalSupply === "0" ||
                      BigNumber(input)
                        .times(Math.pow(10, 6))
                        .times(contracts["cashback_" + tabSelected].extraFields?.reward_balance)
                        .div(contracts["cashback_" + tabSelected].extraFields?.totalSupply)
                        .eq(0)
                    }
                  >
                    {isLoading ? (
                      <div style={{ margin: "auto", textAlign: "center" }}>
                        <CustomLoader size="xs" />
                      </div>
                    ) : (
                      <>
                        {i18(
                          `Claim ${
                            contracts["cashback_" + tabSelected] &&
                            contracts["cashback_" + tabSelected].extraFields?.reward_balance &&
                            contracts["cashback_" + tabSelected].extraFields?.totalSupply &&
                            AXV
                              ? BigNumber(input)
                                  .times(Math.pow(10, 6))
                                  .times(contracts["cashback_" + tabSelected]?.extraFields?.reward_balance ?? 0)
                                  .div(
                                    contracts["cashback_" + tabSelected]?.extraFields?.totalSupply !== "0"
                                      ? contracts["cashback_" + tabSelected]?.extraFields?.totalSupply
                                      : 1
                                  )
                                  .div(Math.pow(10, AXV?.decimals ?? 0))
                                  .decimalPlaces(4)
                                  .toFixed(4)
                              : ""
                          }`,
                          "dashboard.gravitate.btn.text",
                          {
                            amount:
                              contracts["cashback_" + tabSelected] &&
                              contracts["cashback_" + tabSelected].extraFields?.reward_balance &&
                              contracts["cashback_" + tabSelected].extraFields?.totalSupply &&
                              AXV
                                ? BigNumber(input)
                                    .times(Math.pow(10, 6))
                                    .times(contracts["cashback_" + tabSelected]?.extraFields?.reward_balance ?? 0)
                                    .div(
                                      contracts["cashback_" + tabSelected]?.extraFields?.totalSupply !== "0"
                                        ? contracts["cashback_" + tabSelected]?.extraFields?.totalSupply
                                        : 1
                                    )
                                    .div(Math.pow(10, AXV?.decimals ?? 0))
                                    .decimalPlaces(4)
                                    .toFixed(4)
                                : "-",
                          }
                        )}
                      </>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </section>
  );
}

const GRVT8ChainRow = ({ chainId }: { chainId: string }) => {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const contracts = useAppSelector(selectContracts);
  const walletInfo = useAppSelector(selectWalletInfo, isEqual);
  const assets = useAppSelector(selectAssets);
  const grvt8Balance = useAppSelector(selectGRVT8Balance);
  const chains = useAppSelector(selectChains);

  const AXV = Object.values(assets).find((asset: IAsset) => asset.symbol === "AXV");
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingWallet = useAppSelector(selectIsWalletLoading, isEqual);

  useEffect(() => {
    if (!isLoadingWallet && walletInfo.isConnected && contracts["cashback_" + chainId]) {
      getGRVT8Balance(walletInfo.connectedChains[chainId]);
    }
  }, [walletInfo.isConnected, isLoadingWallet, contracts]);

  const grvt8Contract = contracts["cashback_" + chainId];
  const chain = chains[chainId];
  const [input, setInput] = useState("0");
  const [sliderValue, setSliderValue] = useState(100);

  const handleBurnGRVT8 = async (walletInfo: IWalletInfo, chainId: string, amount: string) => {
    setIsLoading(true);
    AXV &&
      walletInfo &&
      walletInfo.connectedChains[chainId] &&
      (await dispatch(
        executeGRVT8BurnAction({
          chainId,
          cashbackAddr: grvt8Contract?.address,
          axvAddr: AXV?.address,
          amount: BigNumber(amount).times(Math.pow(10, 6)).decimalPlaces(0).toString(10),
          i18,
        })
      ));
    await getGRVT8Balance(walletInfo.connectedChains[chainId]);
    setIsLoading(false);
  };

  const getGRVT8Balance = async (walletChainContext: IWalletConnectedChainInfo) => {
    if (grvt8Contract) {
      setIsLoading(true);
      const balance = await dispatch(
        updateGRVT8Balance({
          client: walletChainContext.signingClient,
          grvt8Contract: grvt8Contract,
          walletAddress: walletChainContext.address,
        })
      );

      setInput(balance.payload as string);
      setSliderValue(100);
      setIsLoading(false);
    }
  };

  return (
    <li key={"grvt8_" + chainId} className={"withGradientBorderBottom"}>
      {chain.displayName}
      <br />
      <small style={{ color: "var(--warm-grey)" }}>
        {" "}
        (1:
        {grvt8Contract && grvt8Contract.extraFields?.reward_balance && grvt8Contract.extraFields?.totalSupply && AXV
          ? BigNumber(1)
              .times(Math.pow(10, 6))
              .times(grvt8Contract?.extraFields?.reward_balance ?? 0)
              .div(grvt8Contract?.extraFields?.totalSupply !== "0" ? grvt8Contract?.extraFields?.totalSupply : 1)
              .div(Math.pow(10, AXV?.decimals ?? 6))
              .decimalPlaces(4)
              .toFixed(4)
          : ""}{" "}
        AXV)
      </small>
      <br />
      {isLoading ? (
        <div style={{ margin: "auto", textAlign: "center" }}>
          <CustomLoader size="xs" />
        </div>
      ) : (
        <CustomNumericInput
          extraClassName="tradeToTokenUsdFormGroup gradientText"
          style={{ fontSize: "var(--px22)" }}
          name="trade_to_usd"
          labelText={i18("USD trade amount", "trade.form.toToken.input.label")}
          hiddenLabel={true}
          placeholder={"0"}
          value={input}
          disabled={true}
        />
      )}
      <br />
      <ReactSlider
        className="horizontalSlider"
        thumbClassName="horizontalSliderThumb"
        trackClassName="horizontalSliderTrack"
        value={sliderValue}
        onChange={value => {
          setSliderValue(value);
          setInput("" + (Number(grvt8Balance[grvt8Contract.address]) * value) / 100);
        }}
        renderThumb={(props, state) => (
          <div {...props}>
            <span className="thumbNowValue">{state.valueNow}%</span>
            <span className="thumbHandle" />
          </div>
        )}
      />
      <br />
      <Button
        extraClassName="grvt8BurnButton"
        btnColor="dark-medium"
        title={i18("Collect Rewards", "dashboard.gravitate.btn.label")}
        icon={<AstrovaultTokenIcon />}
        onClick={() => handleBurnGRVT8(walletInfo, chainId, input)}
        isFullWidth={true}
        disabled={
          !grvt8Contract ||
          grvt8Contract.extraFields?.totalSupply === "0" ||
          BigNumber(input)
            .times(Math.pow(10, 6))
            .times(grvt8Contract.extraFields?.reward_balance)
            .div(grvt8Contract.extraFields?.totalSupply)
            .eq(0)
        }
      >
        <>
          {i18(
            `Claim ${
              grvt8Contract &&
              grvt8Contract.extraFields?.reward_balance &&
              grvt8Contract.extraFields?.totalSupply &&
              AXV
                ? BigNumber(input)
                    .times(Math.pow(10, 6))
                    .times(grvt8Contract?.extraFields?.reward_balance ?? 0)
                    .div(grvt8Contract?.extraFields?.totalSupply !== "0" ? grvt8Contract?.extraFields?.totalSupply : 1)
                    .div(Math.pow(10, AXV?.decimals ?? 0))
                    .decimalPlaces(4)
                    .toFixed(4)
                : ""
            }`,
            "dashboard.gravitate.btn.text",
            {
              amount:
                grvt8Contract &&
                grvt8Contract.extraFields?.reward_balance &&
                grvt8Contract.extraFields?.totalSupply &&
                AXV
                  ? BigNumber(input)
                      .times(Math.pow(10, 6))
                      .times(grvt8Contract?.extraFields?.reward_balance ?? 0)
                      .div(
                        grvt8Contract?.extraFields?.totalSupply !== "0" ? grvt8Contract?.extraFields?.totalSupply : 1
                      )
                      .div(Math.pow(10, AXV?.decimals ?? 0))
                      .decimalPlaces(4)
                      .toFixed(4)
                  : "-",
            }
          )}
        </>
      </Button>
    </li>
  );
};

export default DashboardGravitate;
