import clsx from "clsx";
import WalletConnectListItems from "@axvdex/components/WalletConnectListItems";
import useLanguage from "@axvdex/hooks/useLanguage";
import { useGlobalModalContext } from "../../providers/GlobalModalProvider";
import Button from "../common/Button";
import CustomModal from "../common/CustomModal";
import styles from "../../styles/WalletModal.module.scss";
import { useEffect, useState } from "react";
import CustomLoader from "../common/CustomLoader";
import { useAppSelector } from "@axvdex/state";
import { selectChains, selectGlobalConfig } from "@axvdex/state/wallet/walletSelectors";

function WalletSelectionModal() {
  const { i18 } = useLanguage();
  const { modal, hideModal } = useGlobalModalContext();
  const chains = useAppSelector(selectChains);
  const globalConfig = useAppSelector(selectGlobalConfig);
  const [selectedChains, setSelectedChains] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (modal.isOpen && globalConfig) {
      setSelectedChains(globalConfig.chainContexts.filter(chain => !chain.disabled).map(chain => chain.chainId));
    }
  }, [modal.isOpen, globalConfig]);

  return (
    <CustomModal
      isOpen={modal.modalType === "walletSelectionModal" && modal.isOpen}
      onClose={hideModal}
      extraClassName={clsx(styles.walletModal, "walletModal")}
    >
      <section className="sectionModalHeader">
        <h1 className="h2">{i18("Connect wallet", "walletModal.header")}</h1>
      </section>

      {
        // TODO: REMOVING CHOISE OF CHOOSING WHAT CHAINs TO CONNECT TO. TO BE REINTRODUCED LATER
      }
      {/* {globalConfig && globalConfig.chainContexts && (
        <div style={{ margin: "1em" }}>
          {globalConfig.chainContexts
            .filter(chain => !chain.disabled)
            .map(chain => {
              const chainData = chains[chain.chainId];
              const chainName = chainData?.chainName || chain.chainId;
              return (
                <Button
                  key={chain.chainId}
                  btnColor={selectedChains.includes(chain.chainId) ? "gradient" : "gradientText"}
                  text={chainName}
                  title={chainName}
                  onClick={() => {
                    if (selectedChains.includes(chain.chainId)) {
                      setSelectedChains(selectedChains.filter(c => c !== chain.chainId));
                    } else {
                      setSelectedChains([...selectedChains, chain.chainId]);
                    }
                  }}
                />
              );
            })}
        </div>
      )} */}

      <section className="sectionWallet">
        <div className="walletConnectListWrapper withGradientBorderBottom">
          <WalletConnectListItems isLoading={isLoading} setIsLoading={setIsLoading} selectedChains={selectedChains} />
        </div>

        <div className="buttonContainer modalButtonContainer">
          <Button
            btnColor="gradientText"
            text={i18("Cancel", "walletModal.btnCancel.text")}
            title={i18("Cancel and close", "walletModal.btnCancel.title")}
            onClick={hideModal}
          />
          {isLoading && (
            <span style={{ marginLeft: "auto" }}>
              <CustomLoader size="xs" />
            </span>
          )}
        </div>
      </section>
    </CustomModal>
  );
}

export default WalletSelectionModal;
