import { useEffect, useState } from "react";
import clsx from "clsx";
import { Circle, Settings, Shield, ShieldOff, XCircle } from "react-feather";
import BigNumber from "bignumber.js";
import useLanguage from "@axvdex/hooks/useLanguage";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  selectAssetBalances,
  selectAssets,
  selectChains,
  selectContracts,
  selectNicknames,
  selectUser,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import {
  updateNativeBalance,
  updateTokenBalance,
  updateUserGlobalSettingsFields,
} from "@axvdex/state/wallet/walletThunks";
import { getNicknameProtection, updateNickname, updateNicknamesFromBlockchain } from "@axvdex/state/outbid/saleThunks";
import { hasPermitStored } from "@axvdex/utils/localStorage";
import Button from "../common/Button";
import CustomModal from "../common/CustomModal";
import styles from "../../styles/MyAssetsManageTokensModal.module.scss";
import CustomInput from "../form-element/CustomInput";
import CustomLoader from "../common/CustomLoader";
import CustomInputButton from "../form-element/CustomInputButton";

function UserSettingsModal({ outbidIcon }: any) {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const chains = useAppSelector(selectChains);
  const nicknames = useAppSelector(selectNicknames);
  const contracts = useAppSelector(selectContracts);
  const walletInfo = useAppSelector(selectWalletInfo);
  const assets = useAppSelector(selectAssets);
  const assetBalance = useAppSelector(selectAssetBalances);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkboxConfirmation, setCheckboxConfirmation] = useState(false);
  const [action, setAction] = useState("updateNickname");

  const [nickname, setNickname] = useState("");
  const [selectedNoNicknames, setSelectedNoNicknames] = useState(false);
  const [loadingNoNicknames, setLoadingNoNicknames] = useState(false);

  const chain =
    Object.values(chains).length > 0 ? Object.values(chains).find(chain => chain.prefix === "archway") : null;

  // NICKNAME CONTRACT IS CURRENTLY ONLY ON ARCHWAY BLOCKCHAIN, SO ALL NICKNAMES COMING FROM THERE
  // OTHER CHAINS OUTBID WITH NICKNAMES WILL NEED THIS CHANGING
  useEffect(() => {
    if (chain && contracts["nicknames_" + chain.chainId] && walletInfo.connectedChains[chain.chainId]) {
      getNickname();
    }
    setSelectedNoNicknames(user?.myGlobalSettings?.noDisplayNicknames ?? "");
  }, [isOpen, user, contracts, chain]);

  useEffect(() => {
    if (checkboxConfirmation) {
      const actionInfo = "updateNickname" === action ? getUpdateNicknameAction() : getGetProtectionAction();
      const paymentAssetID =
        actionInfo.payment_assets[0].info.token?.contract_addr || actionInfo.payment_assets[0].info.native_token?.denom;
      if (!assetBalance[paymentAssetID]) {
        if (!assets[paymentAssetID].isNative) {
          dispatch(
            updateTokenBalance({
              client: walletInfo.connectedChains[chain.chainId].signingClient,
              tokenAddress: assets[paymentAssetID].address,
              userAddress: walletInfo.connectedChains[chain.chainId].address,
            })
          );
        } else {
          dispatch(
            updateNativeBalance({
              client: walletInfo.connectedChains[chain.chainId].signingClient,
              denom: assets[paymentAssetID].denom,
              userAddress: walletInfo.connectedChains[chain.chainId].address,
            })
          );
        }
      }
    }
  }, [checkboxConfirmation]);

  const getNickname = async () => {
    if (!nicknames[walletInfo.connectedChains[chain.chainId].address]) {
      const nickname = await dispatch(
        updateNicknamesFromBlockchain({
          addresses: [walletInfo.connectedChains[chain.chainId].address],
          client: walletInfo.connectedChains[chain.chainId].signingClient,
          chainId: chain.chainId,
        })
      );
      setNickname(nickname.payload[walletInfo.connectedChains[chain.chainId].address]?.nickname || "");
    } else {
      setNickname(nicknames[walletInfo.connectedChains[chain.chainId].address].nickname);
    }
  };

  const getUpdateNicknameAction = () => {
    const actions = contracts["nicknames_" + chain.chainId].extraFields.actions;
    if (
      !nicknames[walletInfo.connectedChains[chain.chainId].address] ||
      "" === nicknames[walletInfo.connectedChains[chain.chainId].address].nickname
    ) {
      return actions.InitSetOwnNickname;
    }
    if (
      nicknames[walletInfo.connectedChains[chain.chainId].address] &&
      (!nicknames[walletInfo.connectedChains[chain.chainId].address].changed_by ||
        nicknames[walletInfo.connectedChains[chain.chainId].address].changed_by ===
          walletInfo.connectedChains[chain.chainId].address)
    ) {
      return actions.OverrideOwnNickname;
    }

    if (
      nicknames[walletInfo.connectedChains[chain.chainId].address] &&
      nicknames[walletInfo.connectedChains[chain.chainId].address].changed_by !==
        walletInfo.connectedChains[chain.chainId].address
    ) {
      return actions.OverrideOwnNicknameByOther;
    }
  };

  const getGetProtectionAction = () => contracts["nicknames_" + chain.chainId].extraFields.actions.GetOwnProtection;

  const getFeeString = (action: any) => {
    const asset =
      assets[action.payment_assets[0].info.token?.contract_addr || action.payment_assets[0].info.native_token?.denom];
    const amount = BigNumber(action.payment_assets[0].amount).div(Math.pow(10, asset.decimals));
    return `${amount} ${asset.symbol}`;
  };

  const getPaymentAssetID = (action: any) =>
    action.payment_assets[0].info.token?.contract_addr || action.payment_assets[0].info.native_token?.denom;

  if (!walletInfo.connectedChains[chain.chainId] || !chain || !contracts?.["nicknames_" + chain.chainId]) {
    return null;
  }
  return (
    <>
      {outbidIcon ? (
        <Button
          title={i18(`User Settings`, "userWallet.settings.title")}
          //btnVariant="icon"
          btnColor={
            nicknames[walletInfo.connectedChains[chain.chainId].address]
              ? nicknames[walletInfo.connectedChains[chain.chainId].address].changed_by !==
                walletInfo.connectedChains[chain.chainId].address
                ? "gradientOrange"
                : "dark-medium"
              : "gradient"
          }
          icon={<Settings />}
          onClick={() => setIsOpen(!isOpen)}
          btnSize="sm"
        >
          Nickname
        </Button>
      ) : (
        <Button
          style={{ height: "2em" }}
          title={i18(`User Settings`, "userWallet.settings.title")}
          btnVariant="icon"
          icon={<Settings />}
          onClick={() => setIsOpen(!isOpen)}
        />
      )}

      <CustomModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        extraClassName={clsx(styles.permitAuthenticationModal, "permitAuthenticationModal")}
      >
        <section className="sectionModalHeader">
          <h2 className="h2">{i18("User Settings", "userWallet.settings.title")}</h2>
        </section>

        <section className="sectionModalContent withGradientBorderBottom">
          <div className="inlineFlexbox" style={{ width: "100%", placeContent: "space-between" }}>
            <CustomInput
              name="nickname"
              labelText={i18(
                "Nickname (A-Z, 0-9, _ and - with 20 characters max.)",
                "userWallet.settings.nicknameDetail"
              )}
              title={i18(`Nickname`, "userWallet.settings.nickname")}
              // description="Used in: Outbid"
              value={nickname}
              onChange={e => {
                const regexPattern = /^[A-Za-z0-9_-]{0,16}$/;
                if (regexPattern.test(e.target.value)) {
                  setNickname(e.target.value);
                  setCheckboxConfirmation(false);
                  setAction("updateNickname");
                }
              }}
            />

            {nicknames[walletInfo.connectedChains[chain.chainId]?.address] &&
              (!nicknames[walletInfo.connectedChains[chain.chainId].address].is_protected ? (
                <Button
                  title="Nickname Protection"
                  btnColor={"gradient"}
                  style={{ marginTop: "auto" }}
                  onClick={() => {
                    setAction("getProtection");
                    setCheckboxConfirmation(false);
                  }}
                >
                  <ShieldOff />
                </Button>
              ) : (
                <Shield color="green" style={{ width: "5em", marginTop: "auto" }} />
              ))}
          </div>
          <br />
          <br />
          {hasPermitStored({ pubKey: walletInfo.pubKey }) && (
            <>
              {loadingNoNicknames ? (
                <CustomLoader size="xs" />
              ) : (
                <CustomInputButton
                  type="radio"
                  id={"noNicknames"}
                  name="noNicknames"
                  labelText={"Hide nicknames"}
                  labelIcon={selectedNoNicknames ? <XCircle /> : <Circle />}
                  onClick={async () => {
                    setLoadingNoNicknames(true);
                    setSelectedNoNicknames(!selectedNoNicknames);
                    await dispatch(
                      updateUserGlobalSettingsFields({
                        noDisplayNicknames: !selectedNoNicknames,
                      })
                    );
                    setLoadingNoNicknames(false);
                  }}
                />
              )}
            </>
          )}
        </section>

        <div className="inlineFlexbox" style={{ width: "100%" }}>
          <div className="inlineFlexbox" style={{ width: "65%" }}>
            <input
              type="checkbox"
              checked={checkboxConfirmation}
              onChange={e => setCheckboxConfirmation(e.target.checked)}
            />

            {"updateNickname" === action &&
              ("InitSetOwnNickname" === getUpdateNicknameAction().action_type ||
              "OverrideOwnNickname" === getUpdateNicknameAction().action_type ? (
                <span>pay to update your nickname</span>
              ) : (
                <span>
                  pay to overwrite your nickname that was changed by{" "}
                  {nicknames[nicknames[walletInfo.connectedChains[chain.chainId].address].changed_by]
                    ? nicknames[nicknames[walletInfo.connectedChains[chain.chainId].address].changed_by].nickname
                    : "other user"}
                </span>
              ))}

            {"getProtection" === action && <span>pay to protect your nickname from being changed by others</span>}
          </div>
          <div style={{ marginLeft: "auto", textAlign: "center" }}>
            {loading ? (
              <CustomLoader size="xs" />
            ) : (
              <Button
                btnColor="gradient"
                title={"Update"}
                style={{ marginBottom: ".5em" }}
                onClick={async () => {
                  setLoading(true);
                  if (
                    "updateNickname" === action &&
                    ("InitSetOwnNickname" === getUpdateNicknameAction().action_type ||
                      "OverrideOwnNickname" === getUpdateNicknameAction().action_type ||
                      "OverrideOwnNicknameByOther" === getUpdateNicknameAction().action_type)
                  ) {
                    await dispatch(
                      updateNickname({
                        walletConnectedChainInfo: walletInfo.connectedChains[chain.chainId],
                        payment: getUpdateNicknameAction().payment_assets[0],
                        nickname: nickname,
                        target_address: walletInfo.connectedChains[chain.chainId].address,
                        i18,
                      })
                    );
                  } else if ("getProtection" === action) {
                    await dispatch(
                      getNicknameProtection({
                        walletConnectedChainInfo: walletInfo.connectedChains[chain.chainId],
                        payment: getGetProtectionAction().payment_assets[0],
                        i18,
                      })
                    );
                  }
                  setLoading(false);
                  setCheckboxConfirmation(false);
                  setIsOpen(false);
                }}
                disabled={
                  !checkboxConfirmation ||
                  BigNumber(
                    assetBalance[
                      getPaymentAssetID(
                        "updateNickname" === action ? getUpdateNicknameAction() : getGetProtectionAction()
                      )
                    ]
                  ).lt(
                    "updateNickname" === action
                      ? getUpdateNicknameAction().payment_assets[0].amount
                      : getGetProtectionAction().payment_assets[0].amount
                  )
                }
              >
                {"updateNickname" === action
                  ? getFeeString(getUpdateNicknameAction())
                  : getFeeString(getGetProtectionAction())}
                <br />
                Update
              </Button>
            )}
            <br />
            {assetBalance[
              getPaymentAssetID("updateNickname" === action ? getUpdateNicknameAction() : getGetProtectionAction())
            ] && (
              <small>
                {"Balance: " +
                  BigNumber(
                    assetBalance[
                      getPaymentAssetID(
                        "updateNickname" === action ? getUpdateNicknameAction() : getGetProtectionAction()
                      )
                    ]
                  )
                    .div(
                      Math.pow(
                        10,
                        assets[
                          getPaymentAssetID(
                            "updateNickname" === action ? getUpdateNicknameAction() : getGetProtectionAction()
                          )
                        ].decimals
                      )
                    )
                    .decimalPlaces(2) +
                  " " +
                  assets[
                    getPaymentAssetID(
                      "updateNickname" === action ? getUpdateNicknameAction() : getGetProtectionAction()
                    )
                  ].symbol}
              </small>
            )}
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default UserSettingsModal;
