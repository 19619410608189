import clsx from "clsx";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import BigNumber from "bignumber.js";
import { Key } from "react-feather";
import useLanguage from "@axvdex/hooks/useLanguage";
import { secretNetworkCreateViewingKey } from "@axvdex/state/wallet/walletThunks";
import styles from "../../styles/UserBalance.module.scss";
import Button from "../common/Button";

interface UserBalanceProps {
  extraClassName?: string;
  balanceValue: string | number;
  balanceUsdValue: string | number;
  balanceProps?: NumericFormatProps;
  usdProps?: NumericFormatProps;
  invalidAmount?: boolean;
  secretViewingKey?: {
    restURL: string;
    chainId: string;
    sendingAddr: string;
    contract_address: string;
    refreshBalance?: any;
  };
}

function UserBalance({
  extraClassName,
  balanceValue,
  balanceUsdValue,
  balanceProps,
  usdProps,
  invalidAmount,
  secretViewingKey,
}: UserBalanceProps) {
  const { i18 } = useLanguage();

  return (
    <span className={clsx(styles.userBalance, "userBalance", extraClassName && extraClassName)}>
      <span className="balance">
        {i18("Balance", "userBalance.label")}:&nbsp;
        {invalidAmount && !secretViewingKey && "-"}
        {invalidAmount && secretViewingKey && (
          <Button
            title={i18("Create viewing Key", "userBalance.viewingKey")}
            // text={i18("Viewing Key", "userBalance.viewingKey")}
            btnVariant="icon"
            btnColor="dark-medium"
            icon={<Key />}
            onClick={async () => {
              await secretNetworkCreateViewingKey(secretViewingKey);
              secretViewingKey.refreshBalance();
            }}
          />
        )}
        {!invalidAmount && (
          <NumericFormat
            className="balanceValue"
            displayType="text"
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={4}
            value={BigNumber(balanceValue || "0")
              .decimalPlaces(4, BigNumber.ROUND_FLOOR)
              .toString()}
            {...balanceProps}
          />
        )}
      </span>

      {!invalidAmount && (
        <span className="balanceUsd">
          <NumericFormat
            className="balanceUsdValue"
            displayType="text"
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            value={BigNumber(balanceUsdValue || "0")
              .decimalPlaces(2, BigNumber.ROUND_FLOOR)
              .toString()}
            prefix="&nbsp;~&nbsp;"
            suffix="&nbsp;USD"
            {...usdProps}
          />
        </span>
      )}
    </span>
  );
}

export default UserBalance;
