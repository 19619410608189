import BigNumber from "bignumber.js";
import { IAsset } from "../interfaces";

export default (offerAsset: IAsset, offerAssetAmountRaw: string, toAddress: string) => {
  return {
    offerAmount: offerAssetAmountRaw,
    offerAmountInUSD: BigNumber(offerAssetAmountRaw).times(offerAsset.price).toNumber(),
    askAmount: offerAssetAmountRaw,
    askAmountInUSD: BigNumber(offerAssetAmountRaw).times(offerAsset.price).toNumber(),
    tx: {
      contract: !offerAsset.isNative
        ? {
            toExecuteContract: offerAsset.address,
            msgBody: {
              transfer: {
                recipient: toAddress,
                amount: BigNumber(offerAssetAmountRaw).times(Math.pow(10, offerAsset.decimals)).toString(10),
              },
            },
          }
        : undefined,
      nativeTransfer: offerAsset.isNative
        ? {
            toAddress,
            amount: [
              {
                amount: BigNumber(offerAssetAmountRaw).times(Math.pow(10, offerAsset.decimals)).toString(10),
                denom: offerAsset.denom,
              },
            ],
          }
        : undefined,
    },
  };
};
