import { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { useReactTable, getCoreRowModel, flexRender, ColumnDef } from "@tanstack/react-table";
import { useMediaQuery } from "usehooks-ts";
import { responsiveBreakpoints } from "@axvdex/constants";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { selectAssets, selectChains, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { calcLockup } from "@axvdex/utils/unstakeSimulation";
import { formatBalance } from "@axvdex/utils/formatNumber";
import { executeContract, updateNativeBalance, updateTokenBalance } from "@axvdex/state/wallet/walletThunks";
import { sendToast } from "@axvdex/state/wallet/walletSlice";
import imgSanitize from "@axvdex/utils/imgSanitize";
import parseWalletErrorMessage from "@axvdex/utils/parseWalletErrorMessage";
import Button from "@axvdex/components/common/Button";
import useLanguage from "@axvdex/hooks/useLanguage";
import styles from "../../styles/PoolCardLockupsTable.module.scss";

interface PoolCardClaimTableProps {
  id: string;
  amount: string;
  daysLeft: number;
  calcs: any;
}

interface ComponentProps {
  poolData: any;
  lockups: any;
  latestBlock: any;
  querySoftLockups: any;
  [key: string]: any;
}

function PoolCardLockupsTable({ poolData, lockups, latestBlock, querySoftLockups }: ComponentProps) {
  const { i18 } = useLanguage();
  const walletInfo = useAppSelector(selectWalletInfo);
  const dispatch = useAppDispatch();
  const assets = useAppSelector(selectAssets);
  const chains = useAppSelector(selectChains);

  const [data, setData] = useState<PoolCardClaimTableProps[][]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const walletChainContext = walletInfo?.connectedChains[poolData.contextChainId];
  const chain = chains[poolData.contextChainId];

  useEffect(() => {
    setData(
      lockups.map(lockup => {
        const elapsed_time =
          (new Date(latestBlock.header.time).getTime() - new Date(lockup.start_timestamp / 1000000).getTime()) / 1000;

        return lockup.assets_amount.map((amount, i) => {
          const calcs = calcLockup(poolData, lockup.assets_amount, BigInt(Math.round(elapsed_time)))[i];
          return {
            id: poolData.assetIDs[i],
            amount,
            daysLeft:
              Math.round((864000 - elapsed_time) / 60 / 60 / 24) >= 0
                ? Math.round((864000 - elapsed_time) / 60 / 60 / 24)
                : 0,
            calcs,
          };
        });
      })
    );
  }, [lockups]);

  const handleClaim = async (index = null) => {
    setIsLoading(true);
    let isSuccessful: boolean;
    let msg: string;
    let txLink = null;
    let res = null;
    try {
      res = await executeContract(walletChainContext, poolData.lockups, {
        withdrawal_from_lockup: {
          lockup_indexes: index !== null ? [index] : Array.from(Array(data.length).keys()),
        },
      });
      txLink = `${chain.rpc}/${res.transactionHash}`;
      isSuccessful = true;
      msg = i18(`Lockup claim successful!`, "poolCardTable.lockupsTable.claimSuccessfult");
    } catch (e) {
      msg = i18(
        `Lockup claim failed! Error: ${parseWalletErrorMessage(
          e.message,
          i18,
          walletChainContext.chainState.feeCurrencies
        )}`,
        "poolCardTable.lockupsTable.claimFailed",
        { error: parseWalletErrorMessage(e.message, i18, walletChainContext.chainState.feeCurrencies) }
      );
      isSuccessful = false;
    }

    await querySoftLockups(poolData, walletInfo);

    dispatch(
      updateNativeBalance({
        client: walletChainContext.signingClient,
        userAddress: walletChainContext.address,
        denom: walletChainContext.chainState.feeCurrencies[0].coinMinimalDenom,
      })
    );

    poolData.assetIDs.forEach(assetID => {
      if (assets[assetID].isNative)
        dispatch(
          updateNativeBalance({
            client: walletChainContext.signingClient,
            userAddress: walletChainContext.address,
            denom: assets[assetID].denom,
          })
        );
      else
        dispatch(
          updateTokenBalance({
            client: walletChainContext.signingClient,
            userAddress: walletChainContext.address,
            tokenAddress: assets[assetID].address,
          })
        );
    });

    dispatch(
      updateNativeBalance({
        client: walletChainContext.signingClient,
        userAddress: walletChainContext.address,
        denom: walletChainContext.chainState.feeCurrencies[0].coinMinimalDenom,
      })
    );
    const toastType = isSuccessful ? "tx-success" : "tx-fail";
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

    setIsLoading(false);
  };

  const poolType =
    (poolData &&
      (poolData.type === "stable"
        ? "stable"
        : poolData.type === "hybrid"
        ? "hybrid"
        : poolData.type === "standard"
        ? "standard"
        : "")) ||
    "";

  return (
    <section className={clsx(styles.lockupsSectionTable, "poolCardModalSectionTable", "lockupsSectionTable")}>
      <div className="lockupsSectionTableHeader">
        {data.length === 0 && i18("No lockups pending...", "poolCardTable.lockupsTable.noLockupsPending")}
      </div>

      {data.map((dataSection, index) => (
        <table key={index} className={clsx("table", "withGradientBorderBottom", "lockUpsTable", poolType)}>
          <TableSection
            poolData={poolData}
            withHeader={true}
            dataSection={dataSection}
            handleClaim={handleClaim}
            sectionNumber={index}
            isLoading={isLoading}
          />
        </table>
      ))}

      <div className="lockupsSectionTableFooter">
        {data.length > 1 && (
          <div className="buttonContainer">
            <Button
              text={i18("Claim All", "poolCardTable.lockupsTable.claimAllBtn.text")}
              title={i18("Claim All Items", "poolCardTable.lockupsTable.claimAllBtn.title")}
              isFullWidth={true}
              btnColor="gradient"
              onClick={() => handleClaim()}
              disabled={isLoading}
            />
          </div>
        )}
      </div>
    </section>
  );
}

function TableSection({ poolData, withHeader, dataSection, handleClaim, sectionNumber, isLoading }: any) {
  const { i18 } = useLanguage();
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const assets = useAppSelector(selectAssets);
  const defaultColumns = useMemo<ColumnDef<PoolCardClaimTableProps>[]>(() => {
    return [
      {
        id: "token",
        accessorKey: "token",
        header: () => <span>{i18("Token", "poolCardTable.lockupsTable.table.column.token")}</span>,
        cell: ({ row }) => (
          <span
            className="tokenIcon"
            title={assets[poolData.assetIDs[row.index]].symbol}
            aria-label={assets[poolData.assetIDs[row.index]].symbol}
          >
            <img
              src={imgSanitize(assets[poolData.assetIDs[row.index]].symbol)}
              alt={assets[poolData.assetIDs[row.index]].symbol}
            />
          </span>
        ),
      },
      {
        id: "amount",
        accessorKey: "amount",
        header: () => <span>{i18("Available", "poolCardTable.lockupsTable.table.column.available")}</span>,
        cell: ({ row }) => (
          <div className="inlineFlexbox">
            {formatBalance(row.original.amount, assets[row.original.id].price, assets[row.original.id].decimals).amount}
            <span className="tableInputValueConversion">
              ($
              {
                formatBalance(row.original.amount, assets[row.original.id].price, assets[row.original.id].decimals)
                  .usdConversion
              }
              )
            </span>
          </div>
        ),
      },
      {
        id: "fee",
        accessorKey: "fee",
        header: () => <span>{i18("Fee", "poolCardTable.lockupsTable.table.column.fee")}</span>,
        cell: ({ row }) => (
          <div className="inlineFlexbox">
            {
              formatBalance(
                row.original.calcs.fee_amount,
                assets[row.original.id].price,
                assets[row.original.id].decimals
              ).amount
            }
            <span className="tableInputValueConversion">
              ($
              {
                formatBalance(
                  row.original.calcs.fee_amount,
                  assets[row.original.id].price,
                  assets[row.original.id].decimals
                ).usdConversion
              }
              )
            </span>
          </div>
        ),
      },
    ];
  }, [i18]);

  const [columns, setColumns] = useState(() => [...defaultColumns]);

  useEffect(() => {
    setColumns([...defaultColumns]);
  }, [defaultColumns]);

  return (
    <>
      {withHeader && (
        <thead>
          {useReactTable({
            data: dataSection,
            columns,
            getCoreRowModel: getCoreRowModel(),
          })
            .getHeaderGroups()
            .map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
                <th>
                  <span>{i18("Days Left", "poolCardTable.lockupsTable.table.column.daysLeft")}</span>
                </th>
                {!isMobileBreakpoint && (
                  <th>
                    <span className="visuallyHidden">
                      {i18("Claim", "poolCardTable.lockupsTable.table.column.claim")}
                    </span>
                  </th>
                )}
              </tr>
            ))}
        </thead>
      )}
      <tbody>
        {useReactTable({
          data: dataSection,
          columns,
          getCoreRowModel: getCoreRowModel(),
        })
          .getRowModel()
          .rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
              {row.index === 0 && (
                <>
                  <td rowSpan={dataSection.length} colSpan={1}>
                    {row.original.daysLeft}
                  </td>
                  {!isMobileBreakpoint && (
                    <td rowSpan={dataSection.length} colSpan={1}>
                      <Button
                        text={i18("Claim", "poolCardTable.lockupsTable.claimBtn.text")}
                        title={i18("Claim item", "poolCardTable.lockupsTable.claimBtn.title")}
                        btnColor="gradientText"
                        onClick={() => handleClaim(sectionNumber)}
                        disabled={isLoading}
                      />
                    </td>
                  )}
                </>
              )}
            </tr>
          ))}
      </tbody>
      {isMobileBreakpoint && (
        <tfoot>
          <tr>
            <td colSpan={4}>
              <div className="tableTFootFlexbox">
                <Button
                  text={i18("Claim", "poolCardTable.lockupsTable.claimBtn.text")}
                  title={i18("Claim item", "poolCardTable.lockupsTable.claimBtn.title")}
                  btnColor="gradientText"
                  isFullWidth={true}
                  onClick={() => handleClaim(sectionNumber)}
                  disabled={isLoading}
                />
              </div>
            </td>
          </tr>
        </tfoot>
      )}
    </>
  );
}

export default PoolCardLockupsTable;
