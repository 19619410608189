import useLanguage from "@axvdex/hooks/useLanguage";
import CustomInputButton from "../form-element/CustomInputButton";
import { ListItemsProps } from "../form-element/CustomSelect";
import React from "react";
import { selectChains } from "@axvdex/state/wallet/walletSelectors";
import { useAppSelector } from "@axvdex/state";
import { IChain } from "@axvdex/utils/interfaces";

export default function ActionsRowGroupButtons({
  chainOptions,
  assetOptions,
  fromSelectedChain,
  setFromSelectedChain,
  fromSelectedToken,
  setFromSelectedToken,
  toSelectedChain,
  setToSelectedChain,
  toSelectedToken,
  setToSelectedToken,
}: {
  chainOptions: ListItemsProps[];
  assetOptions: ListItemsProps[];
  fromSelectedChain: ListItemsProps;
  setFromSelectedChain: React.Dispatch<React.SetStateAction<ListItemsProps>>;
  fromSelectedToken: ListItemsProps;
  setFromSelectedToken: React.Dispatch<React.SetStateAction<ListItemsProps>>;
  toSelectedChain: ListItemsProps;
  setToSelectedChain: React.Dispatch<React.SetStateAction<ListItemsProps>>;
  toSelectedToken: ListItemsProps;
  setToSelectedToken: React.Dispatch<React.SetStateAction<ListItemsProps>>;
}) {
  const { i18 } = useLanguage();
  const chains = useAppSelector(selectChains);

  const showDepositButton = () => {
    // if nothing selected
    if (
      fromSelectedChain.value === "" &&
      fromSelectedToken.value === "" &&
      toSelectedChain.value === "" &&
      toSelectedToken.value === ""
    ) {
      return true;
    }

    // if from chain is NOT home chain and asset is a currency on that chain
    if (
      !fromSelectedChain.extraFields?.isDeployedChain &&
      chains[fromSelectedChain.value]?.currencies.find(
        item => item.coinMinimalDenom === fromSelectedToken?.extraFields?.externalAsset?.id
      )
    )
      return true;

    return false;
  };
  const isDepositButtonSelected = () => {
    // if from chain is NOT home chain and asset is a currency on that chain
    if (
      !fromSelectedChain.extraFields?.isDeployedChain &&
      chains[fromSelectedChain.value]?.currencies.find(
        item => item.coinMinimalDenom === fromSelectedToken?.extraFields?.externalAsset?.id
      ) &&
      toSelectedChain.extraFields?.isDeployedChain &&
      chains[toSelectedChain.value]?.ibcDenoms.find(
        item => item.base_denom === fromSelectedToken?.extraFields?.externalAsset?.id
      )?.ibc_denom === toSelectedToken?.id
    ) {
      return true;
    }
    return false;
  };

  const showWithdrawalButton = () => {
    // if from chain is home chain and an ibc asset on that chain
    if (
      fromSelectedChain.extraFields?.isDeployedChain &&
      chains[fromSelectedChain.value]?.ibcDenoms.find(item => item.ibc_denom === fromSelectedToken.id)
    )
      return true;
    return false;
  };
  const isWithdrawalButtonSelected = () => {
    // if from chain is home chain and an ibc asset on that chain, to chain is other chain and asset is a currency on that chain
    if (
      fromSelectedChain.extraFields?.isDeployedChain &&
      chains[fromSelectedChain.value]?.ibcDenoms.find(item => item.ibc_denom === fromSelectedToken.id) &&
      !toSelectedChain.extraFields?.isDeployedChain &&
      chains[fromSelectedChain.value]?.ibcDenoms.find(item => item.ibc_denom === fromSelectedToken.id).base_denom ===
        toSelectedToken?.extraFields?.externalAsset?.id
    ) {
      return true;
    }
    return false;
  };

  const showTradeButton = () => {
    // if nothing selected
    if (
      fromSelectedChain.value === "" &&
      fromSelectedToken.value === "" &&
      toSelectedChain.value === "" &&
      toSelectedToken.value === ""
    ) {
      return true;
    }

    // trade is only possible from a token that is on a deployed chain
    if (fromSelectedChain.extraFields?.isDeployedChain) {
      return true;
    }
    return false;
  };
  const isTradeButtonSelected = () => {
    // trade is only possible from a token that is on a deployed chain to a token that is on another deployed chain or the same chain
    if (
      fromSelectedChain.extraFields?.isDeployedChain &&
      toSelectedChain.extraFields?.isDeployedChain &&
      fromSelectedToken.id !== toSelectedToken.id
    ) {
      return true;
    }
    return false;
  };

  const showSendButton = () => {
    // any asset should be able to be sent, but only DONT show when there is nothing selected on the from field
    if (fromSelectedChain.value === "" && fromSelectedToken.value === "") {
      return false;
    }
    return true;
  };
  const isSendButtonSelected = () => {
    // its only selected when from and to is the same
    if (fromSelectedChain.value === toSelectedChain.value && fromSelectedToken.value === toSelectedToken.value) {
      return true;
    }
    return false;
  };

  return (
    <fieldset style={{ width: "fit-content" }}>
      {showDepositButton() && (
        <div className="btnGroup filterBtnGroup">
          <CustomInputButton
            key={"trade.deposit"}
            type="radio"
            id={"trade.deposit"}
            name={"trade.deposit"}
            labelText={"Deposit"}
            checked={isDepositButtonSelected()}
            onClick={() => {
              if (isDepositButtonSelected()) return;
              // if the environment is testnet && nothing selected
              if (
                process.env.REACT_APP_MODE === "TESTNET" &&
                fromSelectedChain.value === "" &&
                fromSelectedToken.value === "" &&
                toSelectedChain.value === "" &&
                toSelectedToken.value === ""
              ) {
                // set the selected chain and token
                setFromSelectedChain(chainOptions.find(item => item.value === "theta-testnet-001")!);
                setFromSelectedToken(assetOptions.find(item => item.value === "ATOM" + "_" + "CosmosHub")!);
                setToSelectedChain(chainOptions.find(item => item.value === "pion-1")!);
                setToSelectedToken(assetOptions.find(item => item.value === "ATOM" + "_" + "Neutron")!);
              } else if (
                process.env.REACT_APP_MODE === "MAINNET" &&
                fromSelectedChain.value === "" &&
                fromSelectedToken.value === "" &&
                toSelectedChain.value === "" &&
                toSelectedToken.value === ""
              ) {
                setFromSelectedChain(chainOptions.find(item => item.value === "cosmoshub-4")!);
                setFromSelectedToken(assetOptions.find(item => item.value === "ATOM" + "_" + "CosmosHub")!);
                setToSelectedChain(chainOptions.find(item => item.value === "neutron-1")!);
                setToSelectedToken(assetOptions.find(item => item.value === "ATOM" + "_" + "Neutron")!);
              } else {
                const { toChain, toToken } = depositTargetItems(chains, fromSelectedToken, chainOptions, assetOptions);
                setToSelectedChain(toChain);
                setToSelectedToken(toToken);
              }
            }}
          />
        </div>
      )}
      {showWithdrawalButton() && (
        <div className="btnGroup filterBtnGroup">
          <CustomInputButton
            key={"trade.withdrawal"}
            type="radio"
            id={"trade.withdrawal"}
            name={"trade.withdrawal"}
            labelText={"Withdrawal"}
            checked={isWithdrawalButtonSelected()}
            onClick={() => {
              if (isWithdrawalButtonSelected()) return;
              const ibcAssetInfo = chains[fromSelectedChain.value].ibcDenoms.find(
                item => item.ibc_denom === fromSelectedToken.id
              );
              setToSelectedChain(chainOptions.find(item => item.value === ibcAssetInfo.base_chain_id)!);
              setToSelectedToken(
                assetOptions.find(item => item.extraFields?.externalAsset?.id === ibcAssetInfo.base_denom)!
              );
            }}
          />
        </div>
      )}
      {showTradeButton() && (
        <div className="btnGroup filterBtnGroup">
          <CustomInputButton
            key={"trade.trade"}
            type="radio"
            id={"trade.trade"}
            name={"trade.trade"}
            labelText={"Trade"}
            checked={isTradeButtonSelected()}
            onClick={() => {
              if (isTradeButtonSelected()) return;
              if (
                process.env.REACT_APP_MODE === "TESTNET" &&
                fromSelectedChain.value === "" &&
                fromSelectedToken.value === "" &&
                toSelectedChain.value === "" &&
                toSelectedToken.value === ""
              ) {
                // set the selected chain and token
                setFromSelectedChain(chainOptions.find(item => item.value === "constantine-3")!);
                setFromSelectedToken(assetOptions.find(item => item.value === "CONST" + "_" + "Archway")!);
                setToSelectedChain(chainOptions.find(item => item.value === "constantine-3")!);
                setToSelectedToken(assetOptions.find(item => item.value === "AXV" + "_" + "Archway")!);
              } else {
                // by default, select AXV on the same chain as the from token is, if its axv on the from field select the chain currency
                setToSelectedChain(fromSelectedChain);
                setToSelectedToken(
                  fromSelectedToken.label === "AXV"
                    ? assetOptions.find(
                        item => item.id === chains[fromSelectedChain.value].currencies[0].coinMinimalDenom
                      )
                    : assetOptions.find(
                        item => item.value === "AXV" + "_" + chains[fromSelectedChain.value].displayName
                      )
                );
              }
            }}
          />
        </div>
      )}

      {showSendButton() && (
        <div className="btnGroup filterBtnGroup">
          <CustomInputButton
            key={"trade.send"}
            type="radio"
            id={"trade.send"}
            name={"trade.send"}
            labelText={"Send"}
            checked={isSendButtonSelected()}
            onClick={() => {
              setToSelectedChain(fromSelectedChain);
              setToSelectedToken(fromSelectedToken);
            }}
          />
        </div>
      )}
    </fieldset>
  );
}

export const depositTargetItems = (
  chains: { [key: string]: IChain },
  fromSelectedToken: ListItemsProps,
  chainOptions: any,
  assetOptions: any
) => {
  // go through all our home chains, and find the first one that the from asset as an ibc denom, so it enables deposits to it
  let toChain = undefined;
  let ibcAssetInfo = undefined;
  for (const chainInfo of Object.values(chains)) {
    const ibcAsset = chainInfo.ibcDenoms.find(
      item => item.base_denom === fromSelectedToken.extraFields?.externalAsset?.id
    );
    if (ibcAsset) {
      ibcAssetInfo = ibcAsset;
      toChain = chainOptions.find(item => item.value === chainInfo.chainId);
      break;
    }
  }

  if (ibcAssetInfo && toChain) {
    return {
      toChain,
      toToken: assetOptions.find(item => item.id === ibcAssetInfo.ibc_denom)!,
    };
  }

  return null;
};
