import BigNumber from "bignumber.js";

export const GAS_ESTIMATION_STATIC_MINT_DERIVATIVE = 600000;
export const GAS_ESTIMATION_STATIC_UNMINT_DERIVATIVE = 550000;
//const GAS_ESTIMATION_STATIC_IBC_DEPOSIT = 0; // it will never use native asset to do, so the wallet handles this verification
export const GAS_ESTIMATION_STATIC_IBC_WITHDRAWAL = 250000;

export const GAS_ESTIMATION_STATIC_STABLE_POOL_DEPOSIT = 1000000;
export const GAS_ESTIMATION_STATIC_STABLE_POOL_WITHDRAWAL = 1900000;
// export const GAS_ESTIMATION_STATIC_STABLE_POOL_WITHDRAWAL_LOCKUP = 750000;

export const GAS_ESTIMATION_STATIC_STANDARD_POOL_DEPOSIT = 1350000;
export const GAS_ESTIMATION_STATIC_STANDARD_POOL_WITHDRAWAL = 1200000;

export const GAS_ESTIMATION_STATIC_TRADE_1_HOP_STABLE = 900000;
export const GAS_ESTIMATION_STATIC_TRADE_1_HOP_STANDARD = 1200000;
export const GAS_ESTIMATION_STATIC_TRADE_1_HOP_HYBRID = 1400000;

export const GAS_ESTIMATION_STATIC_TRADE_ROUTER_OVERHEAD = 400000;

export const GAS_ESTIMATION_STATIC_FARM_DEPOSIT = 750000;
export const GAS_ESTIMATION_STATIC_FARM_WITHDRAWAL = 750000;

export default (
  gasEstimation,
  gasEstimationReference = {
    estimatedFee: [
      {
        denom: "",
        amount: "180000000000000000",
      },
    ],
    gasLimit: 200000,
  }
) => {
  const ratio = gasEstimation / gasEstimationReference.gasLimit;
  return BigNumber(gasEstimationReference.estimatedFee[0].amount)
    .times(ratio)
    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
    .toString(10);
};
