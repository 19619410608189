import BigNumber from "bignumber.js";
import { CheckCircle, X } from "react-feather";
import { useEffect } from "react";
import Button from "@axvdex/components/common/Button";
import CustomLoader from "@axvdex/components/common/CustomLoader";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { selectAssets, selectBackgroundTemporaryState } from "@axvdex/state/wallet/walletSelectors";
import { setBackgroundTemporaryState } from "@axvdex/state/wallet/walletSlice";
// import { formatBalance, humanizeAmount } from "@axvdex/utils/formatNumber";
// import useLanguage from "@axvdex/hooks/useLanguage";

export const CrosschainSwapStatusContainer = () => {
  const dispatch = useAppDispatch();
  const assets = useAppSelector(selectAssets);
  const backgroundTemporaryState = useAppSelector(selectBackgroundTemporaryState);

  // on render, scroll to bottom, as this is always at the bottom and is the most important info
  useEffect(() => {
    window.scrollTo({ top: 999999999999999, behavior: "smooth" });
  }, []);

  const parseActionMessage = (section: any) => {
    if ("trade" === section.action) {
      const fromAmount = BigNumber(section.fromAssetAmount)
        .div(Math.pow(10, assets[section.fromAssetID].decimals))
        .decimalPlaces(6)
        .toString(10);
      const toAmount = BigNumber(section.toAssetAmount)
        .div(Math.pow(10, assets[section.toAssetID].decimals))
        .decimalPlaces(6)
        .toString(10);
      const fromAsset = assets[section.fromAssetID].symbol;
      const toAsset = assets[section.toAssetID].symbol;
      if ("success" === section.status) return `Traded ${fromAmount} ${fromAsset} to ${toAmount} ${toAsset}`;
      if ("fail" === section.status) return `Failed to trade ${fromAmount} ${fromAsset} to ${toAsset}`;
      if ("loading" === section.status) return `Trading ${fromAmount} ${fromAsset} to ${toAsset}`;
    }
    if ("send" === section.action) {
      const fromAmount = BigNumber(section.fromAssetAmount)
        .div(Math.pow(10, assets[section.fromAssetID].decimals))
        .decimalPlaces(6)
        .toString(10);
      const fromAsset = assets[section.fromAssetID].symbol;
      if ("success" === section.status)
        return `Sent ${fromAmount} ${fromAsset} from ${section.fromAssetChain} to ${section.toAssetChain}`;
      if ("fail" === section.status)
        return `Failed sending ${fromAmount} ${fromAsset} from ${section.fromAssetChain} to ${section.toAssetChain}`;
      if ("loading" === section.status)
        return `Sending ${fromAmount} ${fromAsset} from ${section.fromAssetChain} to ${section.toAssetChain}`;
    }
    if ("refund" === section.action) {
      const toAmount = BigNumber(section.toAssetAmount)
        .div(Math.pow(10, assets[section.toAssetID].decimals))
        .decimalPlaces(6)
        .toString(10);
      const toAsset = assets[section.toAssetID].symbol;
      if ("success" === section.status) return `Refunded ${toAmount} ${toAsset} on ${section.fromAssetChain}`;
      if ("fail" === section.status) return `Failed refund ${toAmount} ${toAsset} on ${section.fromAssetChain}`;
      if ("loading" === section.status) return `Refunding ${toAmount} ${toAsset} on ${section.fromAssetChain}`;
    }
    if ("update" === section.action) {
      if ("success" === section.status) return `Updated wallet data`;
      if ("fail" === section.status) return `Failed update wallet data`;
      if ("loading" === section.status) return `Updating wallet data`;
    }
  };

  if (!backgroundTemporaryState.crosschainSwap || 0 === backgroundTemporaryState.crosschainSwap.length) return null;
  return (
    <section id="crosschainSwapStatusContainer" className="withGradientBorder">
      <div style={{ display: "flex", placeContent: "space-between" }}>
        <h3>{`Crosschain Swap status:`}</h3>
        {0 === backgroundTemporaryState.crosschainSwap.filter(section => "success" !== section.status).length && (
          <Button
            icon={<X />}
            btnColor="dark-medium"
            title={"Clear"}
            onClick={() => {
              dispatch(
                setBackgroundTemporaryState({
                  type: "crosschainSwap",
                  data: null,
                })
              );
            }}
          />
        )}
      </div>

      {backgroundTemporaryState.crosschainSwap.map((section: any) => {
        return (
          <div key={section.id} style={{ display: "flex", placeItems: "center", marginBottom: ".5em" }}>
            {"success" === section.status && <CheckCircle color="green" style={{ width: "1.5em" }} />}
            {"fail" === section.status && <X color="red" style={{ width: "1.5em" }} />}
            {"loading" === section.status && <CustomLoader size="xss" />}
            <span style={{ marginLeft: "1em" }}> {parseActionMessage(section)}</span>
          </div>
        );
      })}
      {1 ===
        backgroundTemporaryState.crosschainSwap.filter(
          section => "update" === section.action && "success" === section.status
        ).length && <small>Tip: You can check your trade history on the Dashboard</small>}
    </section>
  );
};
