import { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { useReactTable, getCoreRowModel, flexRender, ColumnDef } from "@tanstack/react-table";
import BigNumber from "bignumber.js";
import { isEqual } from "lodash";
import { formatBalance, fromHumanAmountToTokenBalance, humanizeAmount } from "@axvdex/utils/formatNumber";
import {
  selectAssetBalances,
  selectAssets,
  selectGlobalConfig,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import {
  executePoolAction,
  increasePoolAllowance,
  updateLpStakingBalance,
  updateNativeBalance,
  updateTokenBalance,
} from "@axvdex/state/wallet/walletThunks";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import Button from "@axvdex/components/common/Button";
//import { NumericFormat } from "react-number-format";
import stakeSimulation, { stableStakeCheckUnbalancingThreshold } from "@axvdex/utils/swapScripts/stakeSimulation";
//import { gt } from "@axvdex/utils/math";
import imgSanitize from "@axvdex/utils/imgSanitize";
import estimatedFees, { GAS_ESTIMATION_STATIC_STABLE_POOL_DEPOSIT } from "@axvdex/utils/estimatedFees";
import useLanguage from "@axvdex/hooks/useLanguage";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import CustomNumericInput from "../form-element/CustomNumericInput";
import CustomLoader from "../common/CustomLoader";
import CustomSwitch from "../form-element/CustomSwitch";

// const DummyPoolCardDetailsTableList = [
//   { token: "ARCH", total: 10, deposits: 10 },
//   { token: "xARCH", total: 11, deposits: 15 },
// ];

interface PoolCardStableDepositTableProps {
  id: string;
  symbol: string;
  image: string;
  total: number;
  allowanceLoading: boolean;
  allowance?: {
    allowance: string;
    expires: any;
  } | null;
  input: string;
  balance: number | null;
  shouldRender: boolean;
}

interface ComponentProps {
  poolData: any;
  [key: string]: any;
}

function PoolCardStableDepositTable({ poolData }: ComponentProps) {
  const { i18 } = useLanguage();
  const assetBalances = useAppSelector(selectAssetBalances, isEqual);
  const assets = useAppSelector(selectAssets, isEqual);
  const walletInfo = useAppSelector(selectWalletInfo, isEqual);
  const globalConfig = useAppSelector(selectGlobalConfig);

  const walletChainContext = walletInfo?.connectedChains[poolData.contextChainId];

  const [data, setData] = useState<PoolCardStableDepositTableProps[]>([]);
  const [simulationValues, setSimulationValues] = useState<any>({});
  const [isTransactionStarts, setIsTransactionStarts] = useState(false);
  const [depositAcknowledge, setDepositAcknowledge] = useState({
    activated: poolData.settings.xasset_mode_minter,
    visible: !poolData.settings.xasset_mode_minter,
  });
  const [deposited, setDeposited] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const update = async () => {
      // if there is no wallet connect and auto connect is enabled, we should show the loader as we are trying to auto connect
      if (!walletInfo.isConnected && loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) {
        setIsLoading(true);
        return;
      }
      if (data.length === 0) setIsLoading(true);
      // get asset balances of this pool that are not already in state
      const requests = [];
      for (const [i, poolAsset] of poolData.poolAssets.entries()) {
        if (!assetBalances[poolData.assetIDs[i]] && walletInfo.isConnected) {
          if (poolAsset.info.token) {
            requests.push(
              dispatch(
                updateTokenBalance({
                  client: walletChainContext.signingClient,
                  userAddress: walletChainContext.address,
                  tokenAddress: poolAsset.info.token.contract_addr,
                })
              )
            );
          } else {
            requests.push(
              dispatch(
                updateNativeBalance({
                  client: walletChainContext.signingClient,
                  userAddress: walletChainContext.address,
                  denom: poolAsset.info.native_token.denom,
                })
              )
            );
          }
        } else {
          requests.push(Promise.resolve());
        }
      }

      const poolAssetBalances = await Promise.all(requests);

      // get balance of this user on this pool
      let lpStakingBalance = assetBalances[poolData?.lp_staking];
      if (!assetBalances[poolData?.lp_staking] && walletInfo.isConnected) {
        lpStakingBalance = (
          await dispatch(
            updateLpStakingBalance({
              client: walletChainContext.signingClient,
              userAddress: walletChainContext.address,
              lpStakingAddress: poolData?.lp_staking,
            })
          )
        ).payload;
      }

      setDeposited(lpStakingBalance);

      // get allowances of this user for tokens on this pool
      const allowanceResponses = await Promise.all([
        //across all tokens on this pool, check if the allowance was already provided
        ...poolData.poolAssets.reduce((acc, asset) => {
          if (asset.info.token && walletInfo.isConnected) {
            console.log(asset.info.token.contract_addr);
            const allowanceResponse = rpcClientQuerySmartContractWrapper(
              walletChainContext.signingClient,
              asset.info.token.contract_addr,
              {
                allowance: {
                  owner: walletChainContext.address,
                  spender: poolData.address,
                },
              }
            );

            acc = [...acc, allowanceResponse];
            return acc;
          }

          return [...acc, null];
        }, []),
      ]);

      console.log(allowanceResponses);

      const pPoolData = poolData.poolAssets.map((asset, i) => {
        let shouldRender = true;

        if (poolData.disabled_actions && poolData.disabled_actions.deposit_disabled_for_indexes.includes(i)) {
          shouldRender = false;
        }

        // hide the token assets when xasset mode active
        if (asset.info.token && poolData.settings.xasset_mode_minter) {
          shouldRender = false;
        }

        return {
          id: poolData?.assetIDs[i],
          total: humanizeAmount(asset.amount, assets[poolData?.assetIDs[i]]?.decimals),
          symbol: assets[poolData?.assetIDs[i]]?.symbol,
          input: data && data[i] ? data[i].input : "",
          allowance: allowanceResponses[i],
          balance: poolAssetBalances[i]
            ? formatBalance(
                poolAssetBalances[i].payload,
                assets[poolData?.assetIDs[i]].price,
                assets[poolData?.assetIDs[i]].decimals
              ).amount || 0
            : formatBalance(
                assetBalances[poolData?.assetIDs[i]],
                assets[poolData?.assetIDs[i]].price,
                assets[poolData?.assetIDs[i]].decimals
              ).amount || 0,
          shouldRender,
        };
      });
      setData(pPoolData);
      setIsLoading(false);
    };
    update();
  }, [poolData, walletInfo.isConnected]);

  useEffect(() => {
    setData(prevData => {
      const newData = [...prevData];
      newData.map((row, i) => {
        row.balance = formatBalance(
          assetBalances[poolData?.assetIDs[i]],
          assets[poolData?.assetIDs[i]].price,
          assets[poolData?.assetIDs[i]].decimals
        ).amount;
        return row;
      });
      return newData;
    });
    setDeposited(assetBalances[poolData?.lp_staking]);
  }, [assetBalances]);

  const handleStablePoolInputChange = (amount, index) => {
    setData(prevData => {
      const amounts = prevData.map(row => row.input.replaceAll(",", ""));
      if (index !== null) {
        amounts[index] = amount.replaceAll(",", "");
      }
      const newData = [...prevData];
      const transformData = stakeSimulation(poolData, amounts);
      if (!transformData) {
        return newData.map((row, _) => {
          return { ...row, input: "" };
        });
      }
      const returnAmounts = transformData.returnAmounts;
      delete transformData.returnAmounts;
      setSimulationValues({ ...transformData });
      return newData.map((row, index) => {
        return { ...row, input: returnAmounts[index] };
      });
    });
  };

  const handleIncreaseAllowance = async (tokenAddress, index) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[index].allowanceLoading = true;
      return newData;
    });

    const res = await dispatch(
      increasePoolAllowance({
        poolData,
        tokenAddress,
        i18,
      })
    );

    setData(prevData => {
      const newData = [...prevData];
      newData[index].allowanceLoading = false;
      newData[index].allowance = res.payload as { allowance: string; expires: any } | null;
      newData[index].shouldRender = assets[poolData.assetIDs[index]].isNative;
      return newData;
    });
  };

  const handleSubmit = async () => {
    setIsTransactionStarts(true);

    await dispatch(
      executePoolAction({
        poolData,
        simulationValues,
        isDeposit: true,
        i18,
      })
    );

    poolData.poolAssets.forEach((_, i) => {
      handleStablePoolInputChange("0", i);
    });

    setIsTransactionStarts(false);
  };

  const defaultColumns = useMemo<ColumnDef<PoolCardStableDepositTableProps>[]>(() => {
    return [
      {
        id: "token",
        accessorKey: "token",
        header: () => <span>{i18("Token Balance", "poolCardTable.table.columns.token")}</span>,
        cell: ({ row }) => (
          <div className="flexbox">
            <span className="tokenIcon" title={row.original.symbol} aria-label={row.original.symbol}>
              <img
                src={imgSanitize(row.original.symbol)}
                alt={row.original.symbol}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(
                    updateTokenBalance({
                      client: walletChainContext.signingClient,
                      userAddress: walletChainContext.address,
                      tokenAddress: row.original.id,
                    })
                  )
                }
              />
            </span>

            {row.original.balance !== null && <span>{row.original.balance}</span>}
          </div>
        ),
      },
      {
        id: "actions",
        header: () => <span className="visuallyHidden">{i18("Actions", "poolCardTable.table.columns.actions")}</span>,
        cell: ({ row }) => {
          return (
            <>
              {row.original.allowance?.allowance !== "0" || assets[row.original.id].isNative ? (
                <>
                  <>
                    <div className="formGroupInline">
                      {row.original.balance !== null && (
                        <Button
                          text={i18("Max", "poolCardTable.maxBtn.text")}
                          title={i18("Set Max Value", "poolCardTable.maxBtn.title")}
                          btnColor="gradientText"
                          onClick={() => handleStablePoolInputChange(row.original.balance + "", row.index)}
                        />
                      )}
                      <CustomNumericInput
                        extraClassName="derivativeFromUsdFormGroup"
                        name={`amount_${row.index}`}
                        labelText={i18("Enter amount", "poolCardTable.input.label")}
                        hiddenLabel={true}
                        placeholder={"0"}
                        decimalScale={6}
                        value={row.original.input}
                        onChange={e => {
                          handleStablePoolInputChange(e.target.value, row.index);
                        }}
                      />
                    </div>

                    <div className="tableInputValueConversion withMarginTop">
                      ($
                      {
                        formatBalance(
                          fromHumanAmountToTokenBalance(
                            row.original.input?.replaceAll(",", "") !== "."
                              ? row.original.input?.replaceAll(",", "")
                              : "0",
                            assets[row.original.id].decimals
                          ),
                          assets[row.original.id].price,
                          assets[row.original.id].decimals
                        ).usdConversion
                      }
                      )
                    </div>
                  </>
                </>
              ) : (
                <>
                  {!row.original.allowanceLoading ? (
                    <Button
                      text={i18(`Approve ${assets[row.original.id].symbol}`, "poolCardTable.approveBtn.text", {
                        symbol: assets[row.original.id].symbol,
                      })}
                      title={i18(`Approve set ${assets[row.original.id].symbol}`, "poolCardTable.approveBtn.title", {
                        symbol: assets[row.original.id].symbol,
                      })}
                      btnColor="gradientText"
                      onClick={() => handleIncreaseAllowance(assets[row.original.id].address, row.index)}
                    />
                  ) : (
                    <CustomLoader text={i18("Processing...", "poolCardTable.loader.text")} size="xs" />
                  )}
                </>
              )}
            </>
          );
        },
      },
    ];
  }, [i18]);

  const [columns, setColumns] = useState(() => [...defaultColumns]);

  // useEffect(() => {
  //   setData(DummyPoolCardDetailsTableList);
  // }, [defaultColumns]);

  useEffect(() => {
    setColumns([...defaultColumns]);
  }, [defaultColumns]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const shouldDisableButton = amountWithinInput => {
    return amountWithinInput.find((value, index) => {
      let valueParsed = value;
      if (value === "") valueParsed = "0";
      return !BigNumber(valueParsed)
        .times(Math.pow(10, assets[poolData.assetIDs[index]].decimals))
        .lte(assetBalances[poolData.assetIDs[index]]);
    });
  };

  const getDepositedBalanceDollarPrice = () => {
    let price = 0;
    poolData.assetIDs.forEach(assetID => {
      price += assets[assetID].price;
    });

    return price / poolData.assetIDs.length;
  };

  const submitButtonText = () => {
    const action = i18("Deposit", "poolCardTable.submitBtn.text.deposit");
    if (!walletInfo.connectedChains[poolData.contextChainId]) {
      return {
        buttonText: i18("Not Connected", "poolCardTable.submintBtn.text.notConnected"),
        disabled: true,
      };
    }
    if (data.every(value => !value.input || !Number(value.input))) {
      return {
        buttonText: i18("Enter Amount", "poolCardTable.submitBtn.text.enterAmount"),
        disabled: true,
      };
    }

    if (!poolData?.owner_settings?.is_deposit_enabled) {
      return {
        buttonText: i18("Deposit disabled", "poolCardTable.submitBtn.text.disabled", {
          action,
        }),
        disabled: true,
      };
    }

    // check if this deposit would trigger unbalancing threshold error
    if (
      !poolData.settings.xasset_mode_minter &&
      poolData?.settings?.max_deposit_unbalancing_threshold &&
      stableStakeCheckUnbalancingThreshold(
        poolData,
        data.map((value, i) => {
          if (value.input === "") return BigInt(0);
          return BigInt(
            BigNumber(value.input).times(Math.pow(10, poolData.assetDecimals[i])).decimalPlaces(0).toString(10)
          );
        })
      )
    ) {
      return {
        buttonText: i18(
          "Deposit is unbalancing the pool more than " +
            (parseInt(poolData.settings.max_deposit_unbalancing_threshold) * 100) / 1000000 +
            "%",
          "poolCardTable.submitBtn.text.unbalancingThreshold",
          {
            action,
            unbalancingThreshold: (parseInt(poolData.settings.max_deposit_unbalancing_threshold) * 100) / 1000000,
          }
        ),
        disabled: true,
      };
    }

    if (isTransactionStarts) {
      return {
        buttonText: i18("Executing...", "poolCardTable.submitBtn.text.executing"),
        disabled: true,
      };
    }

    if (shouldDisableButton(data.map(row => row.input))) {
      return {
        buttonText: i18("Not enough balance", "poolCardTable.submitBtn.text.notEnoughBalance"),
        disabled: true,
      };
    }

    // DISABLED THIS CHECK BECAUSE OF NEUTRON HAVING MULTIPLE GAS TOKENS
    // // check if user has sufficient gas fees to cover this
    // const calcEstimatedFees = estimatedFees(
    //   GAS_ESTIMATION_STATIC_STABLE_POOL_DEPOSIT,
    //   globalConfig.statusCounters[poolData.contextChainId].estimatedFeesReference
    // );
    // const feeDenom = globalConfig.statusCounters[poolData.contextChainId].estimatedFeesReference.estimatedFee[0].denom;
    // if (assetBalances[feeDenom] && BigNumber(assetBalances[feeDenom]).lt(calcEstimatedFees)) {
    //   return {
    //     buttonText: i18(
    //       `Wallet needs ${assets[feeDenom].symbol} for Deposit`,
    //       "poolCardTable.submitBtn.text.walletNoFunds",
    //       { action, symbol: assets[feeDenom].symbol }
    //     ),
    //     disabled: true,
    //   };
    // }

    // // if the input is the source denom we need to check if it will consume more fees than the user have balance
    // // current balance - input amount < estimated fees
    // if (
    //   data.find(row => row.id === feeDenom) &&
    //   assetBalances[feeDenom] &&
    //   BigNumber(assetBalances[feeDenom])
    //     .minus(
    //       fromHumanAmountToTokenBalance(
    //         data.find(row => row.id === feeDenom).input.replaceAll(",", ""),
    //         assets[feeDenom].decimals
    //       )
    //     )
    //     .lt(calcEstimatedFees)
    // ) {
    //   return {
    //     buttonText: i18(`Wallet needs ${feeDenom} for Deposit`, "poolCardTable.submitBtn.text.walletNoFunds", {
    //       action,
    //       symbol: assets[feeDenom].symbol,
    //     }),
    //     disabled: true,
    //   };
    // }

    return {
      buttonText: action,
      disabled: false,
    };
  };

  return (
    <section className={clsx("poolCardModalSectionTable withGradientBorderBottom")}>
      {isLoading ? (
        <div style={{ textAlign: "center", marginBottom: "1.5em" }}>
          <CustomLoader size="xs" />
        </div>
      ) : (
        <table
          className={clsx(
            "table",
            "withGradientBorderRows",
            "poolCardModalDetailsTable",
            "isResponsive",
            "col-2",
            "stableDeposit"
          )}
          style={{ marginBottom: !deposited ? "2em" : undefined }}
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table
              .getRowModel()
              .rows.filter(r => {
                const shouldRender = r.original.shouldRender;
                return shouldRender;
              })
              .map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
          </tbody>

          {deposited && (
            <tfoot>
              <tr>
                <td colSpan={2}>
                  <div className="tableTFootFlexbox">
                    <small>{i18("Deposited", "poolCardTable.depositTable.deposited")}:</small>
                    <span>
                      {formatBalance(deposited, getDepositedBalanceDollarPrice(), 6).amount}
                      <small>
                        &nbsp;($
                        {formatBalance(deposited, getDepositedBalanceDollarPrice(), 6).usdConversion})
                      </small>
                    </span>
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      )}

      {depositAcknowledge.visible ? (
        <section className="highFee withGradientBorder">
          <CustomSwitch
            name="feeConfirm"
            labelText={
              <>
                <div className={clsx("highFeeText", "inlineFlexbox")}>
                  <small style={{ color: "white" }}>Deposit Acknowledge</small>
                </div>
                <div>
                  <small className="textGrey">
                    {i18(
                      "Funds are subject to a soft lockup; takes 10 days to withdraw without fee, but are accessible at any time for a 1% or less fee",
                      "poolCardTable.withdrawalFee.text"
                    )}
                  </small>
                </div>
              </>
            }
            isToggled={depositAcknowledge.activated}
            onToggle={e => setDepositAcknowledge({ activated: e.target.checked, visible: false })}
          />
        </section>
      ) : !isTransactionStarts ? (
        <Button
          text={submitButtonText().buttonText}
          title={i18("Submit", "poolCardTable.submitBtn.title")}
          btnColor="gradient"
          isFullWidth={true}
          onClick={handleSubmit}
          disabled={submitButtonText().disabled}
        />
      ) : (
        <CustomLoader text={i18("Processing...", "poolCardTable.loader.text")} size="xs" />
      )}
    </section>
  );
}

export default PoolCardStableDepositTable;
